import Input from '@/pages/TrackingPage/components/EDD/components/Input';
import { TrackingFormStyled } from '@/pages/TrackingPage/components/EDD/components/ModalTrackingPage/components/PreviewContent/TrackingForm/styled';
import { FormEvent, useState } from 'react';

const TrackingForm = () => {
  const [activeTab, setActiveTab] = useState(0);

  const handleChangeTab = (activeTab: number) => {
    setActiveTab(activeTab);
  };

  const handleSubmitForm = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log('qq');
  };

  return (
    <TrackingFormStyled>
      <div className="tab-container">
        <div className={`tab-item ${activeTab === 0 ? 'active' : ''}`} onClick={() => handleChangeTab(0)}>
          Order ID
        </div>
        <div className={`tab-item ${activeTab === 1 ? 'active' : ''}`} onClick={() => handleChangeTab(1)}>
          Tracking Number
        </div>
      </div>

      <div className="mt-4">Enter your Order ID & Email address in the box below to check the status of your order</div>

      <form className="d-flex flex-column gap-16 mt-16" onSubmit={handleSubmitForm}>
        <Input label="Order ID" name="order-id" placeholder="Enter your order id" />
        <Input label="Email" name="email-1" placeholder="Enter your email" />
        <button className="submit-btn">Track</button>
      </form>
    </TrackingFormStyled>
  );
};

export default TrackingForm;
