import styled from 'styled-components';

interface IProps {
  tone?: 'primary' | 'tertiary';
  isHeader?: boolean;
}

export const MenuItemStyled = styled.div<IProps>`
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: ${(props) => (props.tone === 'tertiary' ? 'var(--p-color-bg)' : '#fff')};
  border-bottom: 1px solid var(--p-color-border);
  .item-container {
    /* display: flex;
    align-items: center; */
  }
`;
