import { ErrorBanner, UpgradeBanner } from '@/components';
import ControlBar from '@/components/ControlBar';
import Layout from '@/components/Layout';
import { PATH } from '@/constants';
import { AccountPlan } from '@/constants/enum';
import { checkDirtyField, isLockFeature } from '@/helpers';
import { getErrorFromAPI, handleToastMutation } from '@/helpers/toast';
import useConfirmationNavigate from '@/hooks/useConfirmationNavigate';
import { useUpdateTransitTimeMutation } from '@/redux/api/api.caller';
import commonSlice from '@/redux/features/common.slice';
import { accountSelector, christmasBannerSelector } from '@/redux/features/plan.slice';
import trackingPageSlice, { eddSelector } from '@/redux/features/trackingPage.slice';
import { IUpdatedTransitTime } from '@/types/edd';
import { BlockStack, Button } from '@shopify/polaris';
import { ArrowLeftIcon } from '@shopify/polaris-icons';
import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import ShippingMethod from '../ShippingMethod';
import ShippingZone from '../ShippingZone';

const TransitTimeEditorDetail = () => {
  const navigate = useConfirmationNavigate();
  const dispatch = useDispatch();
  const [apiError, setApiError] = useState<string>('');
  const { value: edd, oldValue } = useSelector(eddSelector);
  const plan = useSelector(accountSelector);
  const { formattedEndDate } = useSelector(christmasBannerSelector);
  const isActiveChristmasFeature = new Date() < new Date(formattedEndDate);
  const isLockedFeature =
    isLockFeature([AccountPlan.Starter, AccountPlan.Basic]) && !plan.isOldPaidUser && !isActiveChristmasFeature;
  const { id } = useParams();
  const [updateTransitTime, { isLoading }] = useUpdateTransitTimeMutation();

  const handleUpdateTransitTime = () => {
    const payload: IUpdatedTransitTime = {
      id: Number(id),
      shippingDestinationCountry: edd.shippingZone.countriesCode,
      shippingMethod: edd.shippingMethod.methodInfo,
    };
    updateTransitTime(payload).then((data) => {
      if ('data' in data && data.data && data.data?.state === 1) {
        if (apiError) {
          setApiError('');
        }
        dispatch(commonSlice.actions.handleToast(handleToastMutation(data)));
        navigate(PATH.POST_PURCHASE_EDD);
      }
      if ('error' in data) {
        setApiError(getErrorFromAPI(data));
      }
    });
  };

  const isDisabledDiscard = useMemo(() => {
    const { isDirty } = checkDirtyField(
      { methodInfo: oldValue.shippingMethod.methodInfo, countriesCode: oldValue.shippingZone.countriesCode },
      { methodInfo: edd.shippingMethod.methodInfo, countriesCode: edd.shippingZone.countriesCode },
    );
    return !isDirty;
  }, [
    edd.shippingMethod.methodInfo,
    edd.shippingZone.countriesCode,
    oldValue.shippingMethod.methodInfo,
    oldValue.shippingZone.countriesCode,
  ]);
  const isDisabledSave = useMemo(() => {
    return (
      edd.shippingZone.countriesCode.length === 0 ||
      edd.shippingMethod.methodInfo.length === 0 ||
      isLockedFeature ||
      isDisabledDiscard
    );
  }, [edd.shippingMethod.methodInfo.length, edd.shippingZone.countriesCode.length, isDisabledDiscard, isLockedFeature]);

  return (
    <Layout
      title={
        (
          <div className="d-flex align-center justify-between">
            <div className="d-flex align-center">
              <Button
                variant="plain"
                onClick={() => {
                  navigate(PATH.POST_PURCHASE_EDD);
                }}
                icon={ArrowLeftIcon}
              />
              <span className="ml-4 d-inline-block">Edit rule</span>
            </div>
            <ControlBar
              hidden={isDisabledDiscard && isDisabledSave}
              actionGroup={[
                {
                  content: 'Discard',
                  onAction: () => {
                    dispatch(trackingPageSlice.actions.handleChangeEDDValue(oldValue));
                  },
                  disabled: isDisabledDiscard,
                },
                {
                  content: 'Save',
                  onAction: handleUpdateTransitTime,
                  primary: true,
                  loading: isLoading,
                  disabled: isDisabledSave,
                },
              ]}
            />
          </div>
        ) as any
      }
    >
      <UpgradeBanner
        isOpen={[AccountPlan.Starter, AccountPlan.Basic].includes(plan.plan) && !plan.isOldPaidUser && !isActiveChristmasFeature}
        usedFeature={AccountPlan.Professional}
      />
      <BlockStack gap="400">
        <ErrorBanner isVisible={!!apiError}>{apiError}</ErrorBanner>
        <ShippingZone id={id} />
        <ShippingMethod />
      </BlockStack>
    </Layout>
  );
};

export default TransitTimeEditorDetail;
