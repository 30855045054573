import { Icon } from '@shopify/polaris';
import { CircleDownIcon, CircleRightIcon, CircleUpIcon } from '@shopify/polaris-icons';

export const renderIconDirection = (changingValue: number) => {
  if (changingValue === 0) {
    return <Icon source={CircleRightIcon} tone="info" />;
  }
  if (changingValue > 0) {
    return <Icon source={CircleUpIcon} tone="success" />;
  }
  return <Icon source={CircleDownIcon} tone="critical" />;
};
