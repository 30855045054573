export const cleanObject = (obj: any) => {
  const clone = { ...obj };
  for (const [key, value] of Object.entries(clone)) {
    if (typeof value !== 'boolean' && typeof value !== 'number' && !value) {
      delete clone[key];
    }
    if (value === 'all') {
      delete clone[key];
    }
  }
  return clone;
};

export const transformOptions = <T extends object>(obj: T) =>
  Object.entries(obj).map(([key, value]) => ({
    value,
    label: key,
  }));

export const isEmptyObject = (obj: any) => {
  // eslint-disable-next-line no-restricted-syntax
  for (const prop in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, prop)) {
      return false;
    }
  }
  return true;
};

export const splitObjects = <T extends object>(obj: T, start: number, end: number) => {
  const props = Object.entries(obj);
  return props.slice(start, end).reduce((splitObj, [key, value]) => {
    (splitObj as any)[key] = value;
    return splitObj;
  }, {});
};

export const deepObjectEqual = (obj1: any, obj2: any): boolean => {
  if (typeof obj1 !== 'object' || obj1 === null || typeof obj2 !== 'object' || obj2 === null) {
    // if (obj1 !== obj2) console.log(obj1, obj2);
    return obj1 === obj2;
  }

  if (Array.isArray(obj1) !== Array.isArray(obj2)) {
    // console.log('array', obj1, obj2);
    return false;
  }

  if (Array.isArray(obj1) && Array.isArray(obj2)) {
    if (obj1.length !== obj2.length) {
      // console.log('array length', obj1, obj2);
      return false;
    }
    // if (!obj1.every((item, index) => deepObjectEqual(item, obj2[index]))) console.log('array every', obj1, obj2);
    return obj1.every((item, index) => deepObjectEqual(item, obj2[index]));
  }

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    // console.log('keys length', obj1, obj2);
    return false;
  }
  return keys1.every((key) => deepObjectEqual(obj1[key], obj2[key]));
};
