import { createSelector, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { NavigateOptions, To } from 'react-router-dom';

interface IAppbarState {
  isClickedDiscard: boolean;
  isShowContextualBar: boolean;
  confirmNavigate: {
    isShowConfirmNavigate: boolean;
    to?: To;
    options?: NavigateOptions;
  };
}

const initialState: IAppbarState = {
  isClickedDiscard: false,
  isShowContextualBar: false,
  confirmNavigate: {
    isShowConfirmNavigate: false,
  },
};

export const contextualBarSlice = createSlice({
  name: 'contextualBar',
  initialState,
  reducers: {
    handleUpdateDiscardState: (state, action: PayloadAction<{ isClickedDiscard: boolean }>) => {
      state.isClickedDiscard = action.payload.isClickedDiscard;
    },
    handleUpdateContextualBarState: (state, action: PayloadAction<{ isShowContextualBar: boolean }>) => {
      state.isShowContextualBar = action.payload.isShowContextualBar;
    },
    handleConfirmNavigateState: (state, action: PayloadAction<IAppbarState['confirmNavigate']>) => {
      state.confirmNavigate = action.payload;
    },
  },
});

export const clickedDiscardSelector = createSelector(
  (state: RootState) => state.contextualBar.isClickedDiscard,
  (state) => state,
);

export const isShowContextualBarSelector = createSelector(
  (state: RootState) => state.contextualBar.isShowContextualBar,
  (state) => state,
);

export const confirmNavigateSelector = createSelector(
  (state: RootState) => state.contextualBar.confirmNavigate,
  (state) => state,
);

export default contextualBarSlice;
