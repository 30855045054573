import RegularText from '@/components/RegularText';
import { Checkbox, TextField } from '@shopify/polaris';

interface IProps {
  checkboxLabel?: string;
  inputLabel?: string;
  inputValue?: string;
  inputPlaceholder?: string;
  checked?: boolean;
  onInputChange?: (value: string) => void;
  onCheckboxChange?: (value: boolean) => void;
}
const CheckboxInput = ({
  checked,
  checkboxLabel,
  inputLabel,
  inputPlaceholder,
  inputValue,
  onCheckboxChange,
  onInputChange,
}: IProps) => {
  return (
    <div className="d-flex flex-column gap-4">
      <Checkbox label={<RegularText>{checkboxLabel}</RegularText>} checked={checked} onChange={onCheckboxChange} />
      {checked && (
        <TextField
          value={inputValue}
          onChange={onInputChange}
          autoComplete="off"
          label={inputLabel}
          labelHidden={!inputLabel}
          placeholder={inputPlaceholder}
        />
      )}
    </div>
  );
};

export default CheckboxInput;
