import { ErrorBanner } from '@/components';
import BoldText from '@/components/BoldText';
import { LINK } from '@/constants';
import { Device } from '@/constants/enum';
import { getErrorFromAPI, handleToastMutation } from '@/helpers/toast';
import Appearance from '@/pages/TrackingPage/components/EDD/components/ModalTrackingPage/components/Appearance';
import Lookup from '@/pages/TrackingPage/components/EDD/components/ModalTrackingPage/components/Lookup';
import PageStatus from '@/pages/TrackingPage/components/EDD/components/ModalTrackingPage/components/PageStatus';
import PreviewContent from '@/pages/TrackingPage/components/EDD/components/ModalTrackingPage/components/PreviewContent';
import { ContentContainerStyled } from '@/pages/TrackingPage/components/EDD/components/ModalTrackingPage/styled';
import {
  useGetTrackingPageQuery,
  useUpdateTrackingPageMutation,
  useUpdateTranslationMutation,
  useUpdateUploadMediaMutation,
  useUploadMediaMutation,
} from '@/redux/api/api.caller';
import { tokenSelector } from '@/redux/features/auth.slice';
import commonSlice from '@/redux/features/common.slice';
import planSlice, { accountSelector } from '@/redux/features/plan.slice';
import trackingPageSlice, { languageSelector, trackingPageSelector } from '@/redux/features/trackingPage.slice';
import trackingPageMediaSlice, { IAssetMedia, trackingPageMediaSelector } from '@/redux/features/trackingPageMedia.slice';
import { Button, ButtonGroup, Icon, Layout, Modal } from '@shopify/polaris';
import { DesktopIcon, MobileIcon, StatusIcon, ThemeEditIcon, ViewIcon, WandIcon } from '@shopify/polaris-icons';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export enum TrackingPagePath {
  Lookup = 'lookup',
  Status = 'status',
  Appearance = 'appearance',
}

const navList = [
  {
    id: TrackingPagePath.Lookup,
    icon: ThemeEditIcon,
    path: TrackingPagePath.Lookup,
  },
  {
    id: TrackingPagePath.Status,
    icon: StatusIcon,
    path: TrackingPagePath.Status,
  },
  {
    id: TrackingPagePath.Appearance,
    icon: WandIcon,
    path: TrackingPagePath.Appearance,
  },
];

const ModalTrackingPage = () => {
  const trackingPage = useSelector(trackingPageSelector);
  const {
    value: {
      lookAndFeel: {
        content: { shippingInfo, progressBar },
      },
    },
    isOpenModalTrackingPage,
  } = trackingPage;
  const language = useSelector(languageSelector);
  const isMobile = window.innerWidth <= 768;
  const dispatch = useDispatch();
  const { shop, token } = useSelector(tokenSelector);
  const trackingPageMediaData = useSelector(trackingPageMediaSelector);
  const accountState = useSelector(accountSelector);
  const [currentPath, setCurrentPath] = useState(TrackingPagePath.Lookup);
  const [apiError, setApiError] = useState<string>('');

  const [uploadImage, uploadData] = useUploadMediaMutation();
  const [updateUploadImage] = useUpdateUploadMediaMutation();
  const [updateTrackingPage, { isLoading: isLoadingUpdateTrackingPage }] = useUpdateTrackingPageMutation();
  const [updateTranslation, { isLoading: isLoadingUpdateTranslation }] = useUpdateTranslationMutation();

  const { data } = useGetTrackingPageQuery();

  useEffect(() => {
    dispatch(trackingPageMediaSlice.actions.handleUpdateMedia({ tempData: data?.data?.lookAndFeel?.media as IAssetMedia }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(data?.data?.lookAndFeel)]);

  const [currentViewMode, setCurrentViewMode] = useState<Device>(Device.Desktop);

  const handleChangeViewMode = useCallback(
    (index: Device) => {
      if (currentViewMode === index) return;
      setCurrentViewMode(index);
    },
    [currentViewMode],
  );

  const handleTabChange = useCallback((path: TrackingPagePath) => setCurrentPath(path), []);

  const renderContentLeft = useCallback(() => {
    switch (currentPath) {
      case TrackingPagePath.Lookup:
        return <Lookup />;
      case TrackingPagePath.Status:
        return <PageStatus />;
      case TrackingPagePath.Appearance:
        return <Appearance />;
      default:
        return null;
    }
  }, [currentPath]);

  const handleUploadImage = async () => {
    console.log('log_trackingPageMedia: ', trackingPageMediaData);
    const oldMediaData = data?.data?.lookAndFeel?.media;
    if (!oldMediaData) return;
    // const dataShouldUpdate: IAssetMedia = {};
    await Promise.all(
      Object.entries(trackingPageMediaData.data).map(([key, media]) => {
        const isFileChange = media.file;
        const isUrlChange = media.value && media.value !== oldMediaData[key as keyof typeof oldMediaData].url;
        if (isFileChange || isUrlChange) {
          const formData = new FormData();
          formData.append('name', `asset-${media.index}`);
          if (media.file) {
            formData.append('file', media.file);
          }
          if (media.value) {
            formData.append('url', media.value);
          }
          formData.append('shop', shop);
          formData.append('urlParams', token?.urlParams || '');

          if (!media.file && isUrlChange) {
            return updateUploadImage({ name: `asset-${media.index}`, url: media.value });
          }
          uploadImage(formData);
          // dataShouldUpdate[key as keyof IAssetMedia] = media;
        }
        return Promise.resolve();
      }),
    );
  };

  const handleDiscard = () => {
    dispatch(
      trackingPageSlice.actions.handleTrackingPageValue({
        ...trackingPage.oldValue,
      }),
    );
    dispatch(trackingPageSlice.actions.handleTrackingPageFieldsChange({}));
    dispatch(trackingPageSlice.actions.handleChangeValidation([]));

    dispatch(
      trackingPageSlice.actions.handleLanguageValue({
        ...language.oldValue.value,
      }),
    );
    dispatch(trackingPageSlice.actions.handleLanguageFieldsChange({}));
    dispatch(trackingPageSlice.actions.handleLanguageConfig({ ...language.oldValue.languageConfig }));
    dispatch(trackingPageSlice.actions.handleChangeValidationLanguage([]));
  };

  const handleSave = async () => {
    await handleUploadImage();
    updateTrackingPage(trackingPage.fieldsChange)
      .then((res) => {
        if ('data' in res && res.data && res.data?.state === 1) {
          dispatch(trackingPageSlice.actions.handleTrackingPageFieldsChange({}));
          dispatch(commonSlice.actions.handleToast(handleToastMutation(res)));

          // check show review table tab in sidebar
          if (trackingPage.fieldsChange?.reviewQuestions?.isActive !== accountState.isEnabledReview) {
            dispatch(
              planSlice.actions.handleChangePlan({
                ...accountState,
                isEnabledReview: trackingPage.fieldsChange?.reviewQuestions?.isActive,
              }),
            );
          }
          if (apiError) {
            setApiError('');
          }
          return;
        }
        setApiError(getErrorFromAPI(res));
      })
      .catch((err) => {});
    updateTranslation(language.fieldsChange)
      .then((res) => {
        dispatch(commonSlice.actions.handleToast(handleToastMutation(res)));
        dispatch(trackingPageSlice.actions.handleLanguageFieldsChange({}));
      })
      .catch((err) => {});
  };

  const hasChange = useMemo(() => {
    return Object.entries(trackingPage.fieldsChange).length > 0 || Object.entries(language.fieldsChange).length > 0;
  }, [language.fieldsChange, trackingPage.fieldsChange]);
  const isDisableSave = useMemo(() => {
    return (
      !trackingPageMediaData.enabledSave &&
      (!!trackingPage.validation.length ||
        !hasChange ||
        !shippingInfo.length ||
        !progressBar.length ||
        !!language.validation.length)
    );
  }, [
    hasChange,
    language.validation.length,
    progressBar.length,
    shippingInfo.length,
    trackingPage.validation.length,
    trackingPageMediaData.enabledSave,
  ]);

  const isLoadingAction = useMemo(() => {
    return uploadData.isLoading || isLoadingUpdateTrackingPage || isLoadingUpdateTranslation;
  }, [isLoadingUpdateTrackingPage, isLoadingUpdateTranslation, uploadData.isLoading]);

  return (
    <Modal
      open={!!isOpenModalTrackingPage}
      onClose={() => {
        dispatch(trackingPageSlice.actions.handleIsOpenModalTrackingPage(false));
      }}
      size="large"
      title={
        <div
          className={`d-flex w-full ${isMobile ? 'flex-column' : 'flex-row align-center justify-between'}`}
          id="order-tracking-modal-title"
        >
          <BoldText>Synctrack Order Tracking</BoldText>{' '}
          <div className={`d-flex gap-4 ${isMobile ? 'mt-8' : ''}`}>
            <Button onClick={handleDiscard} disabled={!hasChange} loading={isLoadingAction}>
              Discard
            </Button>
            <Button variant="primary" onClick={handleSave} disabled={isDisableSave} loading={isLoadingAction}>
              Save
            </Button>
          </div>
        </div>
      }
    >
      <ContentContainerStyled>
        <ErrorBanner isVisible={!!apiError}>{apiError}</ErrorBanner>

        <Layout>
          <Layout.Section variant="oneThird">
            <div className="d-flex h-full">
              <div className="actions-bar">
                {navList.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className={`nav-icon ${currentPath.startsWith(item.path) ? 'nav-icon__active' : ''}`}
                      onClick={() => {
                        // scrollModalEditContactFormToTop();
                        // dispatch(editContactFormSlice.actions.handleChangePath(item.path));
                        handleTabChange(item.path);
                      }}
                    >
                      <Icon source={item.icon} />
                    </div>
                  );
                })}
              </div>
              <div className="content-left">{renderContentLeft()}</div>
            </div>
          </Layout.Section>
          <Layout.Section>
            <div className="h-full content-right">
              <div className="content-right-actions">
                <ButtonGroup variant="segmented">
                  <Button
                    pressed={currentViewMode === Device.Desktop}
                    onClick={() => handleChangeViewMode(Device.Desktop)}
                    icon={DesktopIcon}
                  />
                  <Button
                    pressed={currentViewMode === Device.Mobile}
                    onClick={() => handleChangeViewMode(Device.Mobile)}
                    icon={MobileIcon}
                  />
                </ButtonGroup>
                <Button url={LINK.CUSTOMER_SHOP(shop)} icon={ViewIcon} variant="monochromePlain" removeUnderline>
                  Preview
                </Button>
              </div>
              <PreviewContent />
            </div>
          </Layout.Section>
        </Layout>
      </ContentContainerStyled>
    </Modal>
  );
};

export default ModalTrackingPage;
