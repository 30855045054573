import { ErrorBanner } from '@/components';
import { AccountPlan } from '@/constants/enum';
import { isLockFeature } from '@/helpers/feature';
import { getErrorFromAPI, handleToastMutation } from '@/helpers/toast';
import CustomAutocomplete from '@/pages/Settings/components/CourierMapping/components/AutoComplete';
import { shopifyCourierOptions } from '@/pages/Settings/config';
import {
  useDeleteCourierMappingMutation,
  useGetCourierMappingQuery,
  useGetListCourierQuery,
  useUpdateCourierMappingMutation,
} from '@/redux/api/api.caller';
import commonSlice from '@/redux/features/common.slice';
import { accountSelector } from '@/redux/features/plan.slice';
import { ICourierMapping } from '@/types/settings';
import { Button, Divider, Icon, Modal, SkeletonBodyText, Text, useBreakpoints } from '@shopify/polaris';
import { ArrowDownIcon, ArrowRightIcon, DeleteIcon, CheckIcon } from '@shopify/polaris-icons';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const CourierMapping = () => {
  const { smDown } = useBreakpoints();
  const plan = useSelector(accountSelector);
  const isLock = isLockFeature([AccountPlan.Starter, AccountPlan.Basic]) && !plan.isOldPaidUser;
  const dispatch = useDispatch();
  const [itemClicked, setItemClicked] = useState(-1);
  const [apiError, setApiError] = useState<{ update: string; delete: string }>({ update: '', delete: '' });
  const [courierMappingMutation, courierMappingMutationStatus] = useUpdateCourierMappingMutation();
  const [deleteCourierMapping, deleteCourierMappingStatus] = useDeleteCourierMappingMutation();
  const { data, isLoading } = useGetListCourierQuery({ page: 1, perPage: 500 }, { skip: isLock });
  const [listCourier, setListCourier] = useState<Array<{ label: string; value: string }>>([]);
  const [listCourierMapping, setListCourierMapping] = useState<Array<ICourierMapping>>([]);
  const [listCourierChange, setListCourierChange] = useState<Array<number>>([]);
  const [modal, setModal] = useState<{ isOpen: boolean; id?: number }>({ isOpen: false });
  const fetchCourierMapping = useGetCourierMappingQuery(undefined, { skip: isLock }).data;
  const [state, setState] = useState({
    actualCourier: '',
    shopifyCourier: '',
  });

  const handleOpenModal = (id: number) => setModal((prev) => ({ ...prev, isOpen: true, id }));
  const handleCloseModal = () => {
    setApiError({
      ...apiError,
      delete: '',
    });
    setModal((prev) => ({ ...prev, isOpen: false }));
  };

  useEffect(() => {
    if (fetchCourierMapping?.data?.couriersMapping) {
      const data = [...fetchCourierMapping.data.couriersMapping].reverse();
      setListCourierMapping(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchCourierMapping?.data?.couriersMapping]);

  useEffect(() => {
    if (data) {
      const baseOptions = data.data.couriers.map((item) => {
        return {
          label: item.display_name,
          value: item.name,
        };
      });
      setListCourier(baseOptions);
    }
  }, [data]);

  const handleChangeCreateCourierMapping = (key: string) => (value: Array<string>) => {
    setApiError({
      ...apiError,
      update: '',
    });
    setState({
      ...state,
      [key]: value[0],
    });
  };

  const handleChangeCourierMapping = (key: keyof ICourierMapping, id: number) => (value: Array<string>) => {
    setApiError({
      ...apiError,
      update: '',
    });
    if (fetchCourierMapping) {
      const couriers = [...listCourierMapping];
      const listChange = [...listCourierChange];
      const index = listChange.findIndex((item) => item === id);
      if (index === -1) {
        setListCourierChange([...listChange, id]);
      }
      let indexSelected = couriers.findIndex((item) => item.id === id);
      const itemSelected = {
        ...couriers[indexSelected],
        [key]: value[0],
      };
      couriers[indexSelected] = {
        ...itemSelected,
      };
      setListCourierMapping(couriers);
    }
  };

  const handleUpdateCourierMapping = (id: number) => () => {
    setItemClicked(id);
    const lisChange = [...listCourierChange];
    const indexChange = lisChange.findIndex((item) => item === id);
    const itemSelected = listCourierMapping.find((item) => item.id === id);
    if (itemSelected) {
      courierMappingMutation({ from: itemSelected.from, to: itemSelected.to, id }).then((res) => {
        if ('data' in res && res.data && res.data?.state === 1) {
          dispatch(commonSlice.actions.handleToast(handleToastMutation(res)));
          lisChange.splice(indexChange, 1);
          setListCourierChange(lisChange);
          setApiError({
            ...apiError,
            update: '',
          });
          return;
        }
        setApiError({
          ...apiError,
          update: getErrorFromAPI(res),
        });
      });
    }
  };

  const handleCreateCourierMapping = () => {
    setItemClicked(-1);
    courierMappingMutation({ from: state.actualCourier, to: state.shopifyCourier }).then((res) => {
      if ('data' in res && res.data && res.data?.state === 1) {
        return dispatch(commonSlice.actions.handleToast(handleToastMutation(res)));
      }
    });
  };

  const handleDeleteCourierMapping = (id: number) => () => {
    setItemClicked(id);
    deleteCourierMapping(id).then((res) => {
      if ('data' in res && res.data && res.data?.state === 1) {
        dispatch(commonSlice.actions.handleToast(handleToastMutation(res)));
        handleCloseModal();
        return;
      }
      setApiError({
        ...apiError,
        delete: getErrorFromAPI(res),
      });
    });
  };

  return (
    <>
      <ErrorBanner isVisible={!!apiError.update}>{apiError.update}</ErrorBanner>
      <Text as="p" variant="bodySm">
        Display the Shopify Courier's name on package fulfillment and the tracking page instead of your Actual Carriers
      </Text>
      <div className="courier-mapping">
        <div className="group-textfield">
          <CustomAutocomplete
            placeholder="Choose courier"
            handleSelected={handleChangeCreateCourierMapping('actualCourier')}
            label="Actual Courier"
            baseOptions={listCourier}
            selected={[state.actualCourier]}
            loading={isLoading}
            disabled={isLock}
          />
          <div className="arrow-icon">
            <Icon source={smDown ? ArrowDownIcon : ArrowRightIcon} />
          </div>
          <CustomAutocomplete
            placeholder="Choose courier mapping"
            handleSelected={handleChangeCreateCourierMapping('shopifyCourier')}
            label="Shopify Courier"
            baseOptions={shopifyCourierOptions.map((courier: string) => {
              return {
                value: courier,
                label: courier,
              };
            })}
            selected={[state.shopifyCourier]}
            disabled={isLock}
          />
        </div>
        <div className="group-btn">
          <Button
            onClick={handleCreateCourierMapping}
            disabled={!state.actualCourier || !state.shopifyCourier}
            icon={CheckIcon}
            variant="primary"
            loading={courierMappingMutationStatus.isLoading && itemClicked === -1}
          />
        </div>
      </div>

      {isLoading ? (
        <div className="mt-8">
          <SkeletonBodyText />
        </div>
      ) : (
        listCourierMapping.map((item, index) => {
          return (
            <div className="mt-8" key={index}>
              {smDown && listCourierMapping.length !== index - 1 && (
                <div style={{ marginBlock: '0.5rem' }}>
                  <Divider />
                </div>
              )}
              <div className="courier-mapping">
                <div className="group-textfield">
                  <CustomAutocomplete
                    placeholder="Choose courier"
                    handleSelected={handleChangeCourierMapping('from', item.id)}
                    baseOptions={listCourier}
                    loading={isLoading}
                    selected={[item.from]}
                  />
                  <div className="arrow-icon">
                    <Icon source={smDown ? ArrowDownIcon : ArrowRightIcon} />
                  </div>
                  <CustomAutocomplete
                    placeholder="Choose courier mapping"
                    handleSelected={handleChangeCourierMapping('to', item.id)}
                    baseOptions={shopifyCourierOptions.map((courier: string) => {
                      return {
                        value: courier,
                        label: courier,
                      };
                    })}
                    selected={[item.to]}
                  />
                </div>
                <div className="group-btn">
                  <Button onClick={() => handleOpenModal(item.id)} icon={DeleteIcon} />
                  <Button
                    onClick={handleUpdateCourierMapping(item.id)}
                    icon={CheckIcon}
                    variant="primary"
                    disabled={!listCourierChange.join(',').includes(item.id.toString())}
                    loading={courierMappingMutationStatus.isLoading && item.id === itemClicked}
                  />
                </div>
              </div>
            </div>
          );
        })
      )}

      <Modal
        open={modal.isOpen}
        onClose={handleCloseModal}
        title="Are you sure you want to delete this mapping event?"
        primaryAction={{
          content: 'Delete',
          onAction: modal.id ? handleDeleteCourierMapping(modal.id) : () => {},
          loading: deleteCourierMappingStatus.isLoading,
          destructive: true,
        }}
        secondaryActions={[
          {
            content: 'No',
            onAction: handleCloseModal,
          },
        ]}
      >
        <Modal.Section>
          <ErrorBanner isVisible={!!apiError.delete}>{apiError.delete}</ErrorBanner>
          This action will apply to shipments from the time the action is taken and does not apply to previous shipments
        </Modal.Section>
      </Modal>
    </>
  );
};

export default CourierMapping;
