import { LINK, PATH } from '@/constants';
import React from 'react';
import store, { RootState } from '@/redux/store';
import { IRouter } from '@/types/route';
import {
  ChartVerticalFilledIcon,
  PersonIcon,
  DomainLandingPageIcon,
  HomeIcon,
  NotificationIcon,
  SettingsIcon,
  DeliveryIcon,
  ViewIcon,
} from '@shopify/polaris-icons';

import Analytics from '@/pages/Analytics';
import Dashboard from '@/pages/Dashboard';
import Notifications from '@/pages/Notifications';
import EmailTemplatesDetail from '@/pages/Notifications/Email/components/EmailTemplatesDetail';
import SubscriptionPlans from '@/pages/Plan';
import Settings from '@/pages/Settings';
import Shipments from '@/pages/Shipments';
import ShipmentDetail from '@/pages/Shipments/components/ShipmentDetail';
import TrackingLogs from '@/pages/Shipments/components/TrackingLogs';
import TrackingPage from '@/pages/TrackingPage';
import TransitTimeEditor from '@/pages/TrackingPage/components/EDD/components/TransitTimeEditor';
import TransitTimeEditorDetail from '@/pages/TrackingPage/components/EDD/components/TransitTimeEditorDetail';
import EstimatedDeliveryDate from '@/pages/TrackingPage/components/EDD';
import Blacklisting from '@/pages/TrackingPage/components/Blacklisting';

const getRouteList = (): Array<IRouter> => {
  const {
    auth: {
      data: { shop },
    },
  } = store.getState() as RootState;

  return [
    {
      name: 'Dashboard',
      path: PATH.DASHBOARD,
      element: <Dashboard />,
      icon: HomeIcon,
    },
    {
      name: 'Analytics',
      icon: ChartVerticalFilledIcon,
      path: PATH.ANALYTICS,
      element: <Analytics />,
    },
    {
      name: 'Shipments',
      path: PATH.SHIPMENT,
      element: <Shipments />,
      icon: DeliveryIcon,
    },
    {
      name: 'Tracking Page',
      path: PATH.TRACKING_PAGE,
      element: <TrackingPage />,
      icon: DomainLandingPageIcon,
      actionIcon: {
        accessibilityLabel: 'View',
        url: LINK.CUSTOMER_SHOP(shop),
        icon: ViewIcon,
      },
    },
    {
      path: PATH.TRANSIT_TIME,
      element: <TransitTimeEditor />,
      hiddenInSidebar: true,
    },
    {
      path: PATH.TRANSIT_TIME_DETAIL,
      element: <TransitTimeEditorDetail />,
      hiddenInSidebar: true,
    },
    {
      name: 'Settings',
      path: PATH.SETTINGS,
      element: <Settings />,
      icon: SettingsIcon,
    },
    {
      name: 'Notifications',
      icon: NotificationIcon,
      path: PATH.NOTIFICATIONS,
      element: <Notifications />,
    },
    {
      path: PATH.EMAIL_TEMPLATE,
      element: <EmailTemplatesDetail />,
      hiddenInSidebar: true,
    },
    {
      name: 'Subscription Plans',
      path: PATH.PLAN,
      element: <SubscriptionPlans />,
      icon: PersonIcon,
    },
    {
      path: PATH.SHIPMENT_DETAIL,
      element: <ShipmentDetail />,
      hiddenInSidebar: true,
    },
    {
      path: PATH.TRACKING_LOGS,
      element: <TrackingLogs />,
      hiddenInSidebar: true,
    },
    {
      path: PATH.POST_PURCHASE_EDD,
      element: <EstimatedDeliveryDate />,
      hiddenInSidebar: true,
    },
    {
      path: PATH.PRE_PURCHASE_EDD,
      element: <EstimatedDeliveryDate />,
      hiddenInSidebar: true,
    },
    {
      path: PATH.BLACKLIST,
      element: <Blacklisting />,
      hiddenInSidebar: true,
    },
  ];
};

export default getRouteList;
