import BoldText from '@/components/BoldText';
import RegularText from '@/components/RegularText';
import { checkDirtyField } from '@/helpers';
import MenuItem from '@/pages/TrackingPage/components/EDD/components/ModalTrackingPage/components/MenuItem';
import ChooseTheme from '@/pages/TrackingPage/components/LookAndFeel/components/ChooseTheme';
import Style from '@/pages/TrackingPage/components/LookAndFeel/components/Style';
import { optionsDateFormat, optionsTimeFormat } from '@/pages/TrackingPage/components/TrackingOptions/components/Content/config';
import trackingPageSlice, { trackingPageSelector } from '@/redux/features/trackingPage.slice';
import { Editor } from '@monaco-editor/react';
import { Button, Select, Text, TextField } from '@shopify/polaris';
import { ChevronLeftIcon, EditIcon } from '@shopify/polaris-icons';
import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

enum LookupEditType {
  ThemeLayout = 'themeLayout',
  Style = 'style',
  DateAndTime = 'dateAndTime',
  HeaderAndFooter = 'headerAndFooter',
  HtmlAndCss = 'htmlAndCss',
}

const appearanceOptions = [
  { id: LookupEditType.ThemeLayout, label: 'Theme layout' },
  { id: LookupEditType.Style, label: 'Style' },
  { id: LookupEditType.DateAndTime, label: 'Date and time format' },
  { id: LookupEditType.HeaderAndFooter, label: 'Header & footer text' },
  { id: LookupEditType.HtmlAndCss, label: 'HTML & CSS' },
];

const Appearance = () => {
  const dispatch = useDispatch();
  const [currentEditItem, setCurrentEditItem] = useState<LookupEditType>();
  const data = useSelector(trackingPageSelector);
  const dataTrackingPageValue = data.value;
  const { content } = dataTrackingPageValue.lookAndFeel;

  const {
    fieldsChange,
    oldValue: {
      lookAndFeel: { content: oldContent },
      configuration: { additionalContent: oldAdditionalContent },
    },
  } = data;

  const {
    configuration: { additionalContent },
  } = dataTrackingPageValue;

  const handleChangeFormat = useCallback(
    (key: string) => (value: string) => {
      dispatch(
        trackingPageSlice.actions.handleTrackingPageValue({
          ...dataTrackingPageValue,
          lookAndFeel: {
            ...dataTrackingPageValue.lookAndFeel,
            content: {
              ...content,
              format: {
                ...content.format,
                [key]: value,
              },
            },
          },
        }),
      );

      const { dirtyField: formatField } = checkDirtyField(oldContent.format, {
        ...content.format,
        [key]: value,
      });

      dispatch(
        trackingPageSlice.actions.handleTrackingPageFieldsChange({
          ...fieldsChange,
          general:
            !formatField && Object.entries(fieldsChange.general).length < 3
              ? undefined
              : {
                  ...fieldsChange.general,
                  ...formatField,
                },
        }),
      );
    },
    [content, dataTrackingPageValue, dispatch, fieldsChange, oldContent.format],
  );

  const handleAdditionalField = useCallback(
    (currentValue: {}) => {
      const { dirtyField: additionalContentField } = checkDirtyField(oldAdditionalContent, currentValue);
      return additionalContentField;
    },
    [oldAdditionalContent],
  );

  const handleChangeAdditional = useCallback(
    (key: string) => (value?: string) => {
      dispatch(
        trackingPageSlice.actions.handleTrackingPageValue({
          ...dataTrackingPageValue,
          configuration: {
            ...dataTrackingPageValue.configuration,
            additionalContent: {
              ...additionalContent,
              [key]: value,
            },
          },
        }),
      );

      dispatch(
        trackingPageSlice.actions.handleTrackingPageFieldsChange({
          ...fieldsChange,
          additional: handleAdditionalField({ ...additionalContent, [key]: value }),
        }),
      );
    },
    [additionalContent, dataTrackingPageValue, dispatch, fieldsChange, handleAdditionalField],
  );

  const renderEditContent = useCallback(() => {
    switch (currentEditItem) {
      case LookupEditType.ThemeLayout:
        return (
          <div className="px-16">
            <ChooseTheme />
          </div>
        );
      case LookupEditType.Style:
        return (
          <div className="px-16">
            <Style />
          </div>
        );
      case LookupEditType.DateAndTime:
        return (
          <div className="px-16 d-flex flex-column gap-8">
            <Select
              label="Date format"
              options={optionsDateFormat}
              onChange={handleChangeFormat('dateFormat')}
              value={content.format.dateFormat}
            />
            <Select
              label="Time format"
              options={optionsTimeFormat}
              onChange={handleChangeFormat('timeFormat')}
              value={content.format.timeFormat}
            />
          </div>
        );
      case LookupEditType.HeaderAndFooter:
        return (
          <div className="px-16 d-flex flex-column gap-16">
            <TextField
              label="Header text"
              value={additionalContent.textAbove || ''}
              onChange={handleChangeAdditional('textAbove')}
              autoComplete="off"
              multiline={3}
              maxLength={255}
              showCharacterCount
            />

            <TextField
              label="Footer text"
              value={additionalContent.textBelow || ''}
              onChange={handleChangeAdditional('textBelow')}
              autoComplete="off"
              multiline={3}
              maxLength={255}
              showCharacterCount
            />
          </div>
        );
      case LookupEditType.HtmlAndCss:
        return (
          <div className="px-16 d-flex flex-column gap-16">
            <div className="mt-16">
              <Text as="h6" variant="bodySm">
                HTML top of page
              </Text>
              <Editor
                className="mt-4"
                onChange={handleChangeAdditional('htmlTop')}
                height="12rem"
                theme="vs-dark"
                defaultLanguage="html"
                value={additionalContent.htmlTop || ''}
              />
            </div>
            <div className="mt-16">
              <Text as="h6" variant="bodySm">
                HTML bottom of page
              </Text>
              <Editor
                className="mt-4"
                onChange={handleChangeAdditional('htmlBottom')}
                height="12rem"
                theme="vs-dark"
                defaultLanguage="html"
                value={additionalContent.htmlBottom || ''}
              />
            </div>
            <div className="mt-16">
              <Text as="h6" variant="bodySm">
                Custom CSS
              </Text>
              <Editor
                className="mt-4"
                onChange={handleChangeAdditional('customCss')}
                height="12rem"
                theme="vs-dark"
                defaultLanguage="css"
                value={additionalContent.customCss || ''}
              />
            </div>
          </div>
        );
      default:
        return null;
    }
  }, [
    additionalContent.customCss,
    additionalContent.htmlBottom,
    additionalContent.htmlTop,
    additionalContent.textAbove,
    additionalContent.textBelow,
    content.format.dateFormat,
    content.format.timeFormat,
    currentEditItem,
    handleChangeAdditional,
    handleChangeFormat,
  ]);

  return currentEditItem ? (
    <>
      <MenuItem>
        <div className="d-flex align-center gap-4">
          <Button icon={ChevronLeftIcon} onClick={() => setCurrentEditItem(undefined)} variant="monochromePlain" />
          <BoldText>{appearanceOptions.find((item) => item.id === currentEditItem)?.label}</BoldText>
        </div>
      </MenuItem>
      <div className="py-16 border-bottom d-flex flex-column gap-8 edit-content-left">{renderEditContent()}</div>
    </>
  ) : (
    <>
      <MenuItem>
        <BoldText>Theme settings</BoldText>
      </MenuItem>
      {appearanceOptions.map((item) => (
        <MenuItem actionIcon={EditIcon} onAction={() => setCurrentEditItem(item.id)} key={item.id}>
          <RegularText>{item.label}</RegularText>
        </MenuItem>
      ))}
    </>
  );
};

export default Appearance;
