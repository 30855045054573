import type { PayloadAction } from '@reduxjs/toolkit';
import { createSelector, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';

export interface IAssetMediaItem {
  file?: File | null;
  index: number;
  url?: string;
  value?: string;
  baseUrl?: string;
  shop?: string;
  path?: string;
  id?: number;
}

export interface IAssetMedia {
  asset1?: IAssetMediaItem;
  asset2?: IAssetMediaItem;
  asset3?: IAssetMediaItem;
  asset4?: IAssetMediaItem;
  asset5?: IAssetMediaItem;
}

// Define the initial state using that type
export const initialState: IAssetMedia = {
  asset1: {
    index: 1,
    value: '',
    url: '',
    file: null,
  },
  asset2: {
    index: 2,
    value: '',
    url: '',
    file: null,
  },
  asset3: {
    index: 3,
    value: '',
    url: '',
    file: null,
  },
  asset4: {
    index: 4,
    value: '',
    url: '',
    file: null,
  },
  asset5: {
    index: 5,
    value: '',
    url: '',
    file: null,
  },
};

interface IInit {
  data?: IAssetMedia;
  enabledSave?: boolean;
  tempData?: IAssetMedia;
}

export const trackingPageMediaSlice = createSlice({
  name: 'trackingPageMedia',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState: {
    data: initialState,
    enabledSave: false,
    tempData: initialState,
  },
  reducers: {
    handleUpdateMedia: (state, action: PayloadAction<IInit>) => {
      const { data, enabledSave, tempData } = action.payload;
      if (data) {
        state.data = data;
      }
      if (tempData) {
        state.tempData = tempData;
      }
      state.enabledSave = !!enabledSave;
    },
  },
});

// Other code such as selectors can use the imported `RootState` type
export const trackingPageMediaSelector = createSelector(
  (state: RootState) => state.trackingPageMedia,
  (state) => state,
);

export default trackingPageMediaSlice;
