import { BgColorPalette } from '@/constants';
import styled from 'styled-components';

export const StyledSearchAndReplaceWords = styled.div`
  .replace-text-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;

    p {
      font-weight: 700;

      span {
        font-style: italic;
        margin-inline: 0.25rem;
        font-weight: 400;
      }
    }

    &:not(:first-child) {
      padding-top: 0.25rem;
    }

    &:not(:last-child) {
      padding-bottom: 0.25rem;
      border-bottom: 1px solid ${BgColorPalette.SECONDARY_HOVER};
    }
  }

  .no-replace-text {
    color: #6d7175;
  }
`;

export const StyledEditModal = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;

  .Polaris-Choice {
    .Polaris-Choice__Label {
      font-size: 0.8125rem;
    }
  }

  .textfield {
    width: 100%;
    .Polaris-Label label {
      font-size: 0.8125rem;
    }
  }
`;
