import { Environment, LINK } from '@/constants';
import { CONSTANT, convertQueryStringToObject } from '@/helpers';
import { tokenSelector } from '@/redux/features/auth.slice';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

const useScript = () => {
  const location = useLocation();
  const { search } = location;
  const searchToObj = convertQueryStringToObject(search);
  const { token, isAuth } = useSelector(tokenSelector);

  useEffect(() => {
    const isHiddenChatFromAdmin = searchToObj ? !!searchToObj?.scope : !!token?.scope;
    if (process.env.REACT_APP_ENV === Environment.Local || !isAuth || isHiddenChatFromAdmin) return;
    const stamp = new Date().getTime();
    var connectFacebook = document.createElement('script');
    connectFacebook.setAttribute('src', 'https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v2.9');
    connectFacebook.appendChild(document.createTextNode(``));

    var googleApis = document.createElement('script');
    googleApis.setAttribute('src', 'https://ajax.googleapis.com/ajax/libs/jquery/2.1.3/jquery.min.js');
    googleApis.appendChild(document.createTextNode(``));

    // Converted code for script tag 1
    var facebookPageView = document.createElement('script');
    facebookPageView.appendChild(
      document.createTextNode(`
      !(function (f, b, e, v, n, t, s) {
        if (f.fbq) return;
        n = f.fbq = function () {
          n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
        };
        if (!f._fbq) f._fbq = n;
        n.push = n;
        n.loaded = !0;
        n.version = '2.0';
        n.queue = [];
        t = b.createElement(e);
        t.async = !0;
        t.src = v;
        s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s);
      })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', '302112827575627');
      fbq('track', 'PageView');
    `),
    );

    // Converted code for script tag 1
    var bing = document.createElement('script');
    bing.appendChild(
      document.createTextNode(`
      (function (w, d, t, r, u) {
        var f, n, i;
        (w[u] = w[u] || []),
          (f = function () {
            var o = {
              ti: '17422155',
            };
            (o.q = w[u]), (w[u] = new UET(o)), w[u].push('pageLoad');
          }),
          (n = d.createElement(t)),
          (n.src = r),
          (n.async = 1),
          (n.onload = n.onreadystatechange =
            function () {
              var s = this.readyState;
              (s && s !== 'loaded' && s !== 'complete') || (f(), (n.onload = n.onreadystatechange = null));
            }),
          (i = d.getElementsByTagName(t)[0]),
          i.parentNode.insertBefore(n, i);
      })(window, document, 'script', '//bat.bing.com/bat.js', 'uetq');
    `),
    );
    var crisp = document.createElement('script');
    crisp.setAttribute('src', LINK.CHAT_PLUGIN(stamp));
    crisp.appendChild(document.createTextNode(``));

    const listScript = [connectFacebook, googleApis, facebookPageView, bing];
    if (CONSTANT.isEmbedded) {
      listScript.push(crisp);
    }
    const timer = setTimeout(
      () =>
        listScript.forEach((item) => {
          document.body.appendChild(item);
        }),
      2500,
    );
    return () => {
      clearTimeout(timer);
    };
    // <!-- Global site tag (gtag.js) - Google Analytics -->
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuth, token]);
  return null;
};
export default useScript;
