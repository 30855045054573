import { TrackingPageTabs } from '@/constants';
import { IConfigTab } from '@/types/tab';
import {
  CalendarTimeIcon,
  CartDownIcon,
  CodeIcon,
  DeliveryIcon,
  LanguageTranslateIcon,
  PageAddIcon,
  QuestionCircleIcon,
  StatusIcon,
  TeamIcon,
  TextBlockIcon,
  ThemeEditIcon,
} from '@shopify/polaris-icons';
import AdditionalContent from './components/AdditionalContent';
import Blacklisting from './components/Blacklisting';
import CustomStatus from './components/CustomStatus';
import EstimatedDeliveryDate from './components/EDD';
import EmbedTrackingPage from './components/EmbedTrackingPage';
import FooterLink from './components/FooterLink';
import LanguageTrackingPage from './components/Language';
import LookAndFeel from './components/LookAndFeel';
import NoInfoOrder from './components/NoInfoOrder';
import ProductRecommendation from './components/ProductRecommendation';
import TrackingOptions from './components/TrackingOptions';
export const tabsConfigPage: Array<IConfigTab> = [
  {
    value: TrackingPageTabs.SetUp,
    label: 'Tracking page set up',
    component: <EmbedTrackingPage />,
    icon: PageAddIcon,
    hideControlBar: true,
  },
  {
    value: TrackingPageTabs.LookAndFeel,
    label: 'Look & Feel',
    component: <LookAndFeel />,
    icon: ThemeEditIcon,
  },
  {
    value: TrackingPageTabs.TrackingOptions,
    label: 'Tracking options',
    component: <TrackingOptions />,
    icon: DeliveryIcon,
  },
  {
    value: TrackingPageTabs.Language,
    label: 'Languages',
    component: <LanguageTrackingPage />,
    icon: LanguageTranslateIcon,
    hideControlBar: true,
  },
  {
    value: TrackingPageTabs.Edd,
    label: 'Estimated Delivery Date',
    component: <EstimatedDeliveryDate />,
    icon: CalendarTimeIcon,
    hideControlBar: true,
  },
  {
    value: TrackingPageTabs.Blacklist,
    label: 'Blacklisting',
    component: <Blacklisting />,
    icon: TextBlockIcon,
    hideControlBar: true,
  },
  {
    value: TrackingPageTabs.CustomStatus,
    label: 'Custom status',
    component: <CustomStatus />,
    icon: StatusIcon,
  },
  {
    value: TrackingPageTabs.ProductRecommendation,
    label: 'Product Recommendation',
    component: <ProductRecommendation />,
    icon: CartDownIcon,
  },
  // {
  //   value: TrackingPageTabs.Review,
  //   label: 'Review',
  //   component: <Review />,
  //   icon: SmileyJoyMajor,
  // },
  {
    value: TrackingPageTabs.Social,
    label: 'Social & Policy',
    component: <FooterLink />,
    icon: TeamIcon,
  },
  {
    value: TrackingPageTabs.NoInfo,
    label: 'No info/Not found order',
    component: <NoInfoOrder />,
    icon: QuestionCircleIcon,
  },
  {
    value: TrackingPageTabs.HtmlCss,
    label: 'HTML & CSS',
    component: <AdditionalContent />,
    icon: CodeIcon,
  },
];

export const isNotChangeData = (oldData: string[], currentData: string[]) => {
  return (
    [...oldData].sort((n1: string, n2: string) => n1.localeCompare(n2)).join('') ===
    [...currentData].sort((n1: string, n2: string) => n1.localeCompare(n2)).join('')
  );
};
