import { UpgradeBanner } from '@/components';
import TrackingPageLayout from '@/components/Layout/TrackingPageLayout';
import { PATH } from '@/constants';
import { AccountPlan } from '@/constants/enum';
import { isLockFeature } from '@/helpers/feature';
import { accountSelector, christmasBannerSelector } from '@/redux/features/plan.slice';
import { Button } from '@shopify/polaris';
import { ArrowLeftIcon } from '@shopify/polaris-icons';
import { useSelector } from 'react-redux';
import OrderDescription from './components/OrderDescription';
import TransitTimes from './components/TransitTimes';
import { StyledEstimatedDeliveryDate } from './styled';
import useConfirmationNavigate from '@/hooks/useConfirmationNavigate';

const EstimatedDeliveryDate = () => {
  const navigate = useConfirmationNavigate();
  const plan = useSelector(accountSelector);
  const { formattedEndDate } = useSelector(christmasBannerSelector);
  const isActiveChristmasFeature = new Date() < new Date(formattedEndDate);
  const isLockedFeature =
    isLockFeature([AccountPlan.Starter, AccountPlan.Basic]) && !plan.isOldPaidUser && !isActiveChristmasFeature;

  return (
    <TrackingPageLayout
      title={
        (
          <div className="d-flex align-center justify-between">
            <div className="d-flex align-center">
              <Button
                variant="plain"
                onClick={() => {
                  navigate(PATH.TRACKING_PAGE);
                }}
                icon={ArrowLeftIcon}
              />
              <span className="ml-4 d-inline-block">Post-purchase estimated delivery date</span>
            </div>
          </div>
        ) as any
      }
    >
      <StyledEstimatedDeliveryDate>
        <UpgradeBanner isOpen={!!isLockedFeature} usedFeature={AccountPlan.Professional} />
        <OrderDescription />
        <TransitTimes />
      </StyledEstimatedDeliveryDate>
    </TrackingPageLayout>
  );
};

export default EstimatedDeliveryDate;
