import BoldRegularText from '@/components/BoldRegularText';
import RegularText from '@/components/RegularText';
import { checkDirtyField, splitObjects } from '@/helpers';
import { configStatus } from '@/pages/TrackingPage/components/CustomStatus/config';
import trackingPageSlice, { IConfiguration, trackingPageSelector } from '@/redux/features/trackingPage.slice';
import { Button, ButtonGroup, TextField } from '@shopify/polaris';
import { DeleteIcon, EditIcon, PlusCircleIcon } from '@shopify/polaris-icons';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

interface IProps {
  isEnabled?: boolean;
  nameValue?: string;
  dayValue?: string;
  statusIcon?: number;
}
const CustomStatus = ({ dayValue, isEnabled, nameValue, statusIcon }: IProps) => {
  const dispatch = useDispatch();
  const { fieldsChange, value: data, oldValue } = useSelector(trackingPageSelector);
  const { customStatus } = data.configuration;
  const { isCustomStatus } = customStatus;
  const oldCustomStatus = oldValue.configuration.customStatus;
  const [isEditing, setIsEditing] = useState(false);

  const showCreateEdit = () => {
    setIsEditing(true);
    handleChange('isCustomStatus')(true);
  };
  const handleCancel = () => {
    setIsEditing(false);
    dispatch(
      trackingPageSlice.actions.handleTrackingPageValue({
        ...data,
        configuration: {
          ...data.configuration,
          customStatus: {
            isCustomStatus: oldCustomStatus.isCustomStatus,
            activeAfter: oldCustomStatus.activeAfter,
            statusName: oldCustomStatus.statusName,
            icon: Math.max(Math.min(30, +oldCustomStatus.icon), 0).toString(),
          },
        },
      }),
    );

    dispatch(
      trackingPageSlice.actions.handleTrackingPageFieldsChange({
        ...fieldsChange,
        customStatus: handleCustomStatusField({
          isCustomStatus: oldCustomStatus.isCustomStatus,
          activeAfter: oldCustomStatus.activeAfter,
          statusName: oldCustomStatus.statusName,
          status: Math.max(Math.min(30, +oldCustomStatus.icon), 0).toString(),
        }),
      }),
    );
  };

  const handleSave = () => {
    setIsEditing(false);
  };

  const handleChange = (key: keyof IConfiguration['customStatus'], isNumber?: boolean) => (value: string | boolean) => {
    dispatch(
      trackingPageSlice.actions.handleTrackingPageValue({
        ...data,
        configuration: {
          ...data.configuration,
          customStatus: {
            ...customStatus,
            [key]: isNumber ? Math.max(Math.min(30, +value), 0).toString() : value,
          },
        },
      }),
    );

    dispatch(
      trackingPageSlice.actions.handleTrackingPageFieldsChange({
        ...fieldsChange,
        customStatus: handleCustomStatusField({
          ...customStatus,
          [key]: isNumber ? Math.max(Math.min(30, +value), 0).toString() : value,
        }),
      }),
    );
  };

  const handleCustomStatusField = (currentValue: {}) => {
    const { dirtyField: customStatusField } = checkDirtyField(splitObjects(oldCustomStatus, 0, 1), {
      ...currentValue,
    });

    const { dirtyField: activeAfterField } = checkDirtyField(splitObjects(oldCustomStatus, 1, 2), {
      ...currentValue,
    });

    const { dirtyField: statusNameField } = checkDirtyField(splitObjects(oldCustomStatus, 3, 4), {
      ...currentValue,
    });

    const { dirtyField: statusField } = checkDirtyField(splitObjects(oldCustomStatus, 2, 3), {
      ...currentValue,
    });

    if (!customStatusField && !activeAfterField && !statusNameField && !statusField) {
      return undefined;
    }
    return {
      ...fieldsChange.customStatus,
      enabled: customStatusField?.isCustomStatus,
      label: statusNameField?.statusName,
      timeActive: activeAfterField?.activeAfter ? +activeAfterField.activeAfter : undefined,
      icon: statusField?.icon ? +statusField.icon : undefined,
    };
  };

  return (
    <div className="d-flex flex-column gap-8 px-16 pb-4">
      {isCustomStatus ? (
        isEditing ? (
          <>
            <BoldRegularText>Custom status</BoldRegularText>
            <TextField
              maxLength={100}
              showCharacterCount
              label={<RegularText>Status name</RegularText>}
              placeholder="In production"
              autoComplete="off"
              value={customStatus.statusName}
              onChange={handleChange('statusName')}
            />
            <TextField
              label={<RegularText>Day(s) since last status</RegularText>}
              placeholder="Day(s) since last status"
              autoComplete="off"
              value={customStatus.activeAfter}
              onChange={handleChange('activeAfter', true)}
              type="number"
              min={0}
              max={30}
            />
            <div>
              <RegularText>Status icon</RegularText>
              <div className="d-flex gap-8">
                {configStatus.map((icon) => (
                  <div
                    key={icon.value}
                    className={`status-icon-btn d-flex justify-center align-center ${
                      icon.value === customStatus.icon ? 'selected' : ''
                    }`}
                    onClick={() => handleChange('icon')(icon.value)}
                  >
                    <img src={icon.src} alt="icon" />
                  </div>
                ))}
              </div>
            </div>
            <ButtonGroup>
              <Button onClick={handleCancel}>Cancel</Button>
              <Button variant="primary" onClick={handleSave}>
                Save
              </Button>
            </ButtonGroup>
          </>
        ) : (
          <div className="d-flex align-center justify-between">
            <RegularText>In production</RegularText>
            <ButtonGroup>
              <Button icon={EditIcon} variant="plain" onClick={showCreateEdit} />
              <Button icon={DeleteIcon} variant="plain" />
            </ButtonGroup>
          </div>
        )
      ) : (
        <>
          <div>
            <Button variant="plain" onClick={showCreateEdit} icon={PlusCircleIcon}>
              Add custom status
            </Button>
          </div>
          <RegularText>
            Add a custom status (like in production, packaging) to inform customers of specific steps before order is ready.
          </RegularText>
        </>
      )}
    </div>
  );
};

export default CustomStatus;
