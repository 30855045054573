import { checkDirtyField, splitObjects } from '@/helpers';
import trackingPageSlice, { trackingPageSelector } from '@/redux/features/trackingPage.slice';
import { RadioButton } from '@shopify/polaris';
import { useDispatch, useSelector } from 'react-redux';
import { ChooseThemeStyled } from './styled';

const ChooseTheme = () => {
  const dispatch = useDispatch();
  const { fieldsChange, value: data, oldValue } = useSelector(trackingPageSelector);
  const { lookAndFeel } = data;
  const { lookAndFeel: oldLookAndFeel } = oldValue;

  const handleChange = (_: boolean, chooseTheme: string) => {
    dispatch(
      trackingPageSlice.actions.handleTrackingPageValue({
        ...data,
        lookAndFeel: {
          ...lookAndFeel,
          chooseTheme,
        },
      }),
    );

    const { dirtyField: themeField } = checkDirtyField(splitObjects(oldLookAndFeel, 0, 1), {
      ...splitObjects(lookAndFeel, 0, 1),
      chooseTheme,
    });

    dispatch(
      trackingPageSlice.actions.handleTrackingPageFieldsChange({
        ...fieldsChange,
        general:
          !themeField && Object.entries(fieldsChange.general).length < 2
            ? undefined
            : {
                ...fieldsChange.general,
                theme: themeField?.chooseTheme ? +themeField.chooseTheme : undefined,
              },
      }),
    );
  };

  return (
    <ChooseThemeStyled>
      <RadioButton label="Classic" checked={lookAndFeel.chooseTheme === '1'} id="1" onChange={handleChange} />
      <div className="center wrap-image">
        <img
          onClick={() => handleChange(lookAndFeel.chooseTheme === '2', '1')}
          srcSet={`${process.env.REACT_APP_S3_ASSETS_BASE_URL}/trackingpage/theme_1.jpg`}
          alt="_blank"
          loading="lazy"
        />
      </div>
      <RadioButton label="Modern" id="2" checked={lookAndFeel.chooseTheme === '2'} onChange={handleChange} />
      <div className="center wrap-image">
        <img
          onClick={() => handleChange(lookAndFeel.chooseTheme === '1', '2')}
          srcSet={`${process.env.REACT_APP_S3_ASSETS_BASE_URL}/trackingpage/theme_2.jpg`}
          alt="_blank"
          loading="lazy"
        />
      </div>
    </ChooseThemeStyled>
  );
};

export default ChooseTheme;
