import styled from 'styled-components';

export const ChooseThemeStyled = styled.div`
  .Polaris-Choice__Label {
    font-size: 0.8125rem;
  }
  .wrap-image {
    margin-bottom: 0.6rem;
    margin-top: 0.6rem;
    height: 100%;
    .Polaris-Text--root {
      display: flex;
      justify-content: center;
      height: 100%;
    }
    img {
      width: 95%;
      cursor: pointer;
      &:hover {
        transform: scale(1.05);
        transition: all 0.25s ease-in-out;
      }
    }
  }
  .input-container {
    display: flex;
    margin-top: 0.5rem;
    gap: 0.5rem;
    flex-direction: column;

    .Polaris-TextField {
      min-width: 10rem;
    }
  }
`;

export const StyledAssetCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  .input-container {
    margin-top: 0.5rem;
  }
`;
