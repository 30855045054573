import { CustomBadge, CustomTable, ExternalLink, InternalLink } from '@/components';
import { LINK, OrderStatusPalette, PATH } from '@/constants';
import { ShipmentStatus } from '@/constants/shipment';
import { capitalizeFirstLetter, cleanObject, spliceContent } from '@/helpers';
import { useDebounce } from '@/hooks';
import useConfirmationNavigate from '@/hooks/useConfirmationNavigate';
import { useGetShipmentSearchQuery } from '@/redux/api/api.caller';
import { tokenSelector } from '@/redux/features/auth.slice';
import shipmentSlice, {
  courierModalSelector,
  datePickerSelector,
  shipmentFilterSelector,
  statusModalSelector,
} from '@/redux/features/shipment.slice';
import { ITrackingDetail } from '@/types/shipment';
import { Badge, Button, IndexTable, Text, Tooltip, useIndexResourceState } from '@shopify/polaris';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CourierModal from './components/CourierModal';
import ShipmentFilter from './components/ShipmentFilter';
import StatusModal from './components/StatusModal';

const ShipmentTable = () => {
  const navigate = useConfirmationNavigate();
  const dispatch = useDispatch();
  const { shop } = useSelector(tokenSelector);
  const shipmentFilter = useSelector(shipmentFilterSelector);
  const courierModal = useSelector(courierModalSelector);
  const statusModal = useSelector(statusModalSelector);
  const { start, end } = useSelector(datePickerSelector);
  const debouncedSearch = useDebounce<string>(shipmentFilter.search);
  const unRequiredParams = cleanObject({
    page: shipmentFilter.page,
    perPage: shipmentFilter.pageSize,
    fromDate: start.toISOString(),
    toDate: end.toISOString(),
    search: debouncedSearch,
    courier: shipmentFilter.courier,
    status: shipmentFilter.status,
    sortBy: shipmentFilter.sortBy,
    sortDirection: shipmentFilter.sortBy ? shipmentFilter.sortOrder : '',
  });
  const { data, isFetching } = useGetShipmentSearchQuery({
    ...unRequiredParams,
  });
  const shipmentList = (data?.data?.shipments || []).map((shipment) => ({ ...shipment, id: shipment.id.toString() }));
  const { selectedResources, allResourcesSelected, handleSelectionChange, clearSelection } = useIndexResourceState(shipmentList);

  const handleSelectCourierByCheckbox = () => {
    const transformedItems = selectedResources.map((item) => Number(item));
    dispatch(shipmentSlice.actions.handleChangeCourierModal({ ...courierModal, open: true, id: transformedItems }));
  };

  const handleClickPaginationBtn = (type: 'next' | 'prev') =>
    dispatch(
      shipmentSlice.actions.handleChangeTableFilter({
        ...shipmentFilter,
        page: type === 'prev' ? shipmentFilter.page - 1 : shipmentFilter.page + 1,
      }),
    );

  const rowMarkup = useMemo(() => {
    return shipmentList.map((shipment, index) => {
      const renderSyncStatus = () => {
        if (shipment.trackingSummary === ShipmentStatus.CourierNotSupported) {
          return <Badge>none</Badge>;
        }
        if (shipment.pushStatus) {
          return <Badge tone="success">synced</Badge>;
        }
        return <Badge tone="attention">syncing</Badge>;
      };

      const handleClickEditCourierBtn = (id: number, courier: string) =>
        dispatch(shipmentSlice.actions.handleChangeCourierModal({ ...courierModal, open: true, value: courier, id: [id] }));

      const handleClickEditStatusBtn = (orderId: string, trackingNumber: string, trackingDetail: ITrackingDetail[]) =>
        dispatch(
          shipmentSlice.actions.handleChangeStatusModal({
            ...statusModal,
            open: true,
            orderId,
            trackingNumber,
            history: trackingDetail,
          }),
        );

      return (
        <IndexTable.Row
          id={shipment.id.toString()}
          key={shipment.id}
          position={index}
          selected={selectedResources.includes(shipment.id.toString())}
          onClick={() => navigate(`${PATH.SHIPMENT}/${shipment.id.toString()}`)}
        >
          <IndexTable.Cell>
            <Text variant="headingSm" as="h6">
              {shipment.name}
            </Text>
            <ExternalLink to={LINK.ORDER_DETAIL(shop, String(shipment.orderId))}>{shipment.orderId}</ExternalLink>
          </IndexTable.Cell>
          <IndexTable.Cell>
            {shipment.trackingNumber ? (
              <ExternalLink to={LINK.TRACKING_ORDER_NOW(shop, shipment.trackingNumber)}>{shipment.trackingNumber}</ExternalLink>
            ) : (
              'Not added yet'
            )}
          </IndexTable.Cell>
          <IndexTable.Cell>
            <div
              onClick={(e) => {
                handleClickEditCourierBtn(Number(shipment.id), shipment.trackingCompany);
                e.stopPropagation();
              }}
            >
              {shipment.trackingCompany}
              <br />
              {shipment.trackingNumber && <Button variant="plain">Edit</Button>}
            </div>
          </IndexTable.Cell>
          <IndexTable.Cell>{dayjs(shipment.createdAt).format('MMM DD, YYYY hh:mm A')}</IndexTable.Cell>
          <IndexTable.Cell>
            <div
              onClick={(e) => {
                if (shipment.trackingSummary === ShipmentStatus.CourierNotSupported) {
                  handleClickEditStatusBtn(shipment.orderId, shipment.trackingNumber, shipment.trackingDetail);
                  e.stopPropagation();
                }
              }}
            >
              {shipment.trackingSummary ? (
                <CustomBadge
                  summary={
                    Number(shipment.pushStatus) === 2
                      ? 'Over quota'
                      : capitalizeFirstLetter(shipment.trackingSummary.split('_').join(' '))
                  }
                  status={
                    Number(shipment.pushStatus) === 2
                      ? OrderStatusPalette.OVER_QUOTA
                      : OrderStatusPalette[shipment.trackingSummary.toUpperCase() as keyof typeof OrderStatusPalette]
                  }
                />
              ) : (
                '---'
              )}
              {shipment.trackingSummary === ShipmentStatus.CourierNotSupported && <Button variant="plain">Edit</Button>}
            </div>
          </IndexTable.Cell>
          <IndexTable.Cell>{renderSyncStatus()}</IndexTable.Cell>
          <IndexTable.Cell>
            {spliceContent(shipment.trackingDetail?.[0]?.message || '---', 30)}
            {shipment.trackingDetail?.[0]?.message && (
              <span>{dayjs(shipment.trackingDetail?.[0]?.date_time).format('MMM DD, YYYY hh:mm A')}</span>
            )}
          </IndexTable.Cell>
          <IndexTable.Cell>
            {shipment.trackingNumber ? (
              <div onClick={(e) => e.stopPropagation()} className="center">
                <Tooltip content="View detail">
                  <InternalLink
                    path={`${PATH.TRACKING_LOGS}?tracking-number=${shipment.trackingNumber}&order-id=${shipment.orderId}`}
                  >
                    {shipment.trackCount}
                  </InternalLink>
                </Tooltip>
              </div>
            ) : (
              <div className="center">{shipment.trackCount}</div>
            )}
          </IndexTable.Cell>
        </IndexTable.Row>
      );
    });
  }, [courierModal, dispatch, navigate, selectedResources, shipmentList, shop, statusModal]);

  return (
    <>
      <CustomTable
        filter={<ShipmentFilter params={unRequiredParams} />}
        tableProps={{
          resourceName: {
            singular: 'shipment',
            plural: 'shipments',
          },
          itemCount: shipmentList.length,
          headings: [
            { title: 'Order ID' },
            { title: 'Tracking no' },
            { title: 'Courier' },
            { title: 'Date' },
            { title: 'Shipment status' },
            { title: 'Sync status' },
            { title: 'Last event' },
            { title: 'Tracking times', alignment: 'center' },
          ],
          promotedBulkActions: [
            {
              content: 'Edit Courier',
              onAction: handleSelectCourierByCheckbox,
            },
          ],
          selectedItemsCount: allResourcesSelected ? 'All' : selectedResources.length,
          onSelectionChange: handleSelectionChange,
          selectable: true,
        }}
        skeletonLines={13}
        isLoading={isFetching}
        page={shipmentFilter.page}
        pageSize={shipmentFilter.pageSize}
        totalResult={data?.data.totalResult}
        paginationProps={{ onPrevious: () => handleClickPaginationBtn('prev'), onNext: () => handleClickPaginationBtn('next') }}
      >
        {rowMarkup}
      </CustomTable>

      <CourierModal clearSelection={clearSelection} />
      <StatusModal />
    </>
  );
};

export default ShipmentTable;
