import ColorPicker from '@/components/ColorPicker';
import { InputColorPickerStyled } from '@/components/InputColorPicker/styled';
import { isHexColor } from '@/helpers';
import { TextField } from '@shopify/polaris';
import { useEffect, useState } from 'react';

interface IProps {
  label?: string;
  labelHidden?: boolean;
  onChange: (value: string) => void;
  value: string;
}

const InputColorPicker = ({ label, labelHidden, onChange, value }: IProps) => {
  const [inputValue, setInputValue] = useState(value);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const handleChangeColorPickerValue = (e: React.FocusEvent<HTMLInputElement, Element>) => {
    onChange(e.target.value);
    setInputValue(e.target.value);
  };

  const handleBlur = () => {
    if (isHexColor(inputValue)) {
      onChange(inputValue);
    } else {
      setInputValue(value);
    }
  };

  return (
    <InputColorPickerStyled>
      <TextField
        label={label}
        labelHidden={labelHidden}
        autoComplete="off"
        value={inputValue}
        onChange={(value) => setInputValue(value)}
        onBlur={handleBlur}
        suffix={<ColorPicker bgColor={value} value={value} onChange={handleChangeColorPickerValue} />}
      />
    </InputColorPickerStyled>
  );
};

export default InputColorPicker;
