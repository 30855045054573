import { ErrorBanner, UpgradeBanner } from '@/components';
import ControlBar from '@/components/ControlBar';
import Layout from '@/components/Layout';
import { PATH } from '@/constants';
import { AccountPlan } from '@/constants/enum';
import { getErrorFromAPI, handleToastMutation } from '@/helpers/toast';
import useConfirmationNavigate from '@/hooks/useConfirmationNavigate';
import { useCreateTransitTimeMutation } from '@/redux/api/api.caller';
import commonSlice from '@/redux/features/common.slice';
import { accountSelector, christmasBannerSelector } from '@/redux/features/plan.slice';
import trackingPageSlice, { eddSelector } from '@/redux/features/trackingPage.slice';
import { ICreatedTransitTime } from '@/types/edd';
import { BlockStack, Button } from '@shopify/polaris';
import { ArrowLeftIcon } from '@shopify/polaris-icons';
import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ShippingMethod from '../ShippingMethod';
import ShippingZone from '../ShippingZone';
import { checkDirtyField } from '@/helpers';

const TransitTimeEditor = () => {
  const navigate = useConfirmationNavigate();
  const forceNavigate = useConfirmationNavigate({ forceNavigate: true });
  const dispatch = useDispatch();
  const [apiError, setApiError] = useState<string>('');
  const { value: edd, oldValue } = useSelector(eddSelector);
  const plan = useSelector(accountSelector);
  const { formattedEndDate } = useSelector(christmasBannerSelector);
  const isActiveChristmasFeature = new Date() < new Date(formattedEndDate);
  const [createTransitTime, { isLoading }] = useCreateTransitTimeMutation();

  const handleCreateTransitTime = () => {
    const payload: ICreatedTransitTime = {
      shippingDestinationCountry: edd.shippingZone.countriesCode,
      shippingMethod: edd.shippingMethod.methodInfo,
    };
    createTransitTime(payload).then((data) => {
      if ('data' in data && data.data && data.data?.state === 1) {
        if (apiError) {
          setApiError('');
        }
        dispatch(commonSlice.actions.handleToast(handleToastMutation(data)));
        forceNavigate(PATH.POST_PURCHASE_EDD);
      }
      if ('error' in data) {
        setApiError(getErrorFromAPI(data));
      }
    });
  };

  const isDisabledDiscard = useMemo(() => {
    const { isDirty } = checkDirtyField(
      { methodInfo: oldValue.shippingMethod.methodInfo, countriesCode: oldValue.shippingZone.countriesCode },
      { methodInfo: edd.shippingMethod.methodInfo, countriesCode: edd.shippingZone.countriesCode },
    );
    return !isDirty;
  }, [
    edd.shippingMethod.methodInfo,
    edd.shippingZone.countriesCode,
    oldValue.shippingMethod.methodInfo,
    oldValue.shippingZone.countriesCode,
  ]);
  const isDisabledSave = useMemo(() => {
    return edd.shippingZone.countriesCode.length === 0 || edd.shippingMethod.methodInfo.length === 0;
  }, [edd.shippingMethod.methodInfo.length, edd.shippingZone.countriesCode.length]);

  return (
    <Layout
      title={
        (
          <div className="d-flex align-center justify-between">
            <div className="d-flex align-center">
              <Button
                variant="plain"
                onClick={() => {
                  navigate(PATH.POST_PURCHASE_EDD);
                }}
                icon={ArrowLeftIcon}
              />
              <span className="ml-4 d-inline-block">New rule</span>
            </div>
            <ControlBar
              hidden={isDisabledDiscard && isDisabledSave}
              actionGroup={[
                {
                  content: 'Discard',
                  onAction: () => {
                    dispatch(trackingPageSlice.actions.handleChangeEDDValue(oldValue));
                  },
                  disabled: isDisabledDiscard,
                },
                {
                  content: 'Save',
                  onAction: handleCreateTransitTime,
                  primary: true,
                  loading: isLoading,
                  disabled: isDisabledSave,
                },
              ]}
            />
          </div>
        ) as any
      }
    >
      <UpgradeBanner
        isOpen={[AccountPlan.Starter, AccountPlan.Basic].includes(plan.plan) && !plan.isOldPaidUser && !isActiveChristmasFeature}
        usedFeature={AccountPlan.Professional}
      />
      <BlockStack gap="400">
        <ErrorBanner isVisible={!!apiError}>{apiError}</ErrorBanner>
        <ShippingZone />
        <ShippingMethod />
      </BlockStack>
    </Layout>
  );
};

export default TransitTimeEditor;
