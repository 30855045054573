import UploadFile from '@/components/UploadFile';
import { heightPercent } from '@/helpers';
import { Button, Divider, Icon, Text, TextField } from '@shopify/polaris';
import { ImageWithTextOverlayIcon } from '@shopify/polaris-icons';
import { memo } from 'react';
import { StyledAssetCard } from './styled';

interface AssetCardProps {
  assetNo: string;
  fileUrl: string;
  fileSize: string;
  onUploadFile: (file: File) => void;
  destinationValue: string;
  onChangeDestination: (value: string) => void;
  onBlurDestination?: () => void;
  disabledBtn: boolean;
  loadingBtn: boolean;
  onClickBtn: () => void;
  error: string;
  isHiddenPublishBtn?: boolean;
  isShowDivider?: boolean;
}

const AssetCard = ({
  assetNo,
  fileUrl,
  fileSize,
  onUploadFile,
  destinationValue,
  onChangeDestination,
  onBlurDestination,
  disabledBtn,
  loadingBtn,
  onClickBtn,
  error,
  isHiddenPublishBtn,
  isShowDivider,
}: AssetCardProps) => {
  const percentByHeight = heightPercent(fileSize);

  return (
    <StyledAssetCard>
      <Text as="h6" variant="headingSm">
        {assetNo}
      </Text>
      <UploadFile
        url={fileUrl}
        percentByHeight={percentByHeight}
        label=""
        placeholderText={
          <div className="d-flex flex-column justify-center align-center">
            <Icon source={ImageWithTextOverlayIcon} tone="base" />
            <p className="text-center px-16">{`${fileSize}`}</p>
            <p className="text-center px-16">Accepts .svg, .gif, .jpg, and .png; Maximum size: 1MB</p>
          </div>
        }
        onSave={onUploadFile}
      >
        <div className="input-container">
          <TextField
            label="Destination URL"
            placeholder="https://example.com"
            value={destinationValue || ''}
            onChange={onChangeDestination}
            autoComplete="off"
            error={error}
            onBlur={onBlurDestination}
          />
          {isHiddenPublishBtn ? null : (
            <div>
              <Button fullWidth disabled={disabledBtn} onClick={onClickBtn} variant="primary" loading={loadingBtn}>
                Publish
              </Button>
            </div>
          )}
        </div>
      </UploadFile>
      {isShowDivider ? (
        <div className="mt-8" style={{ marginLeft: '-1rem', marginRight: '-1rem' }}>
          <Divider />
        </div>
      ) : null}
    </StyledAssetCard>
  );
};

export default memo(AssetCard);
