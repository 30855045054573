import { Button, FooterHelp } from '@shopify/polaris';
import { LINK } from '@/constants';
import { StyledFooter } from './styled';
import { GlobeIcon, QuestionCircleIcon } from '@shopify/polaris-icons';

const Footer = () => {
  return (
    <StyledFooter>
      <FooterHelp>
        <Button url={LINK.HELP_CENTER} variant="plain" icon={QuestionCircleIcon}>
          Need help? View our docs
        </Button>
        <Button url={LINK.OUR_SITE} variant="plain" icon={GlobeIcon}>
          Visit our site
        </Button>
      </FooterHelp>
    </StyledFooter>
  );
};

export default Footer;
