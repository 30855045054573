import { CustomCard, ErrorBanner } from '@/components';
import CustomLayoutOneThird from '@/components/CustomLayoutOneThird';
import { PATH, REST_OF_WORLD, allCountryData, continentCode } from '@/constants';
import { AccountPlan } from '@/constants/enum';
import { countDuplicateValueInArr, isLockFeature } from '@/helpers';
import { getErrorFromAPI, handleToastMutation } from '@/helpers/toast';
import useConfirmationNavigate from '@/hooks/useConfirmationNavigate';
import { useDeleteTransitTimeMutation } from '@/redux/api/api.caller';
import commonSlice from '@/redux/features/common.slice';
import { accountSelector, christmasBannerSelector } from '@/redux/features/plan.slice';
import trackingPageSlice, { eddSelector, isLoadingEddSelector } from '@/redux/features/trackingPage.slice';
import { Button, Divider, Modal, SkeletonBodyText, Text } from '@shopify/polaris';
import { DeleteIcon, EditIcon } from '@shopify/polaris-icons';
import { Fragment, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StyledTransitTimes } from './styled';

const TransitTimes = () => {
  const navigate = useConfirmationNavigate();
  const dispatch = useDispatch();
  const [apiError, setApiError] = useState<string>('');
  const { value: edd } = useSelector(eddSelector);
  const isLoading = useSelector(isLoadingEddSelector);
  const plan = useSelector(accountSelector);
  const { formattedEndDate } = useSelector(christmasBannerSelector);
  const isActiveChristmasFeature = new Date() < new Date(formattedEndDate);
  const isLockedFeature =
    isLockFeature([AccountPlan.Starter, AccountPlan.Basic]) && !plan.isOldPaidUser && !isActiveChristmasFeature;
  const [modal, setModal] = useState<{ isOpen: boolean; id?: number }>({ isOpen: false });

  const [deleteTransitTime, deletedTransitTimeData] = useDeleteTransitTimeMutation();

  const transformCourierName = useCallback((countriesName: Array<string>) => {
    if (countriesName.length === 1 && countriesName.toString() === REST_OF_WORLD) {
      return REST_OF_WORLD;
    }
    const courierInfo = countriesName.map((country) => allCountryData.find(({ code }) => code === country));
    const listContinentNo = Object.keys(
      countDuplicateValueInArr(
        countriesName.map((country) => allCountryData.find(({ code }) => code === country)?.continent || ''),
      ),
    );
    const listCountryByContinent = listContinentNo.map((continent) => {
      return {
        continent: continentCode[continent as keyof typeof continentCode],
        country: courierInfo.filter((courier) => courier?.continent === continent).map((item) => item?.name || ''),
      };
    });
    return listCountryByContinent.map(({ continent, country }, index) => (
      <Fragment key={continent}>
        <>
          {continent}
          <span className="detail-name">{`(${country.slice(0, 3).join(', ')}${country.length > 3 ? ',..' : ''})`}</span>
        </>
        {listCountryByContinent.length - 1 !== index && <span>{`, `}</span>}
      </Fragment>
    ));
  }, []);

  const handleCloseModal = () => {
    setApiError('');
    setModal((prev) => ({ ...prev, isOpen: false }));
  };
  const handleClickDeleteIcon = (id: number) => setModal((prev) => ({ ...prev, isOpen: true, id }));

  const handleDeleteTransitTime = () => {
    deleteTransitTime({ id: modal.id || 0 }).then((data) => {
      if ('data' in data && data.data && data.data?.state === 1) {
        dispatch(commonSlice.actions.handleToast(handleToastMutation(data)));
        handleCloseModal();
        if (apiError) {
          setApiError('');
        }
        return;
      }
      setApiError(getErrorFromAPI(data));
    });
  };

  return (
    <CustomLayoutOneThird
      isShowDivider
      notUseCard
      title="Transit time"
      description={<p>Set up transit time for different shipping zones and carriers</p>}
    >
      <StyledTransitTimes>
        <CustomCard
          noHeaderBorder
          // noHeaderPaddingBottom
          noContentPaddingTop
          title="Rule list"
          actionGroup={[
            {
              content: 'Add new rule',
              variant: 'primary',
              onAction: () => {
                navigate(PATH.TRANSIT_TIME);
                dispatch(trackingPageSlice.actions.handleSetEddTransitTimeRule({ methodInfo: [], countriesCode: [] }));
              },
              disabled: isLockedFeature,
            },
          ]}
        >
          {isLoading ? (
            <SkeletonBodyText lines={10} />
          ) : (
            edd?.transitTimes?.length > 0 && (
              <div>
                <Divider />
                {edd.transitTimes.map((item) => (
                  <div className="transit-times-item" key={item.id}>
                    <div className="line-tile">
                      <Text variant="headingSm" as="h6">
                        {transformCourierName(item.shippingDestinationCountry)}
                      </Text>
                      <div className="group-btn">
                        <Button
                          variant="plain"
                          icon={DeleteIcon}
                          disabled={item.shippingDestinationCountry.includes('Rest of world') || isLockedFeature}
                          onClick={() => handleClickDeleteIcon(item.id)}
                        />
                        <Button
                          variant="plain"
                          icon={EditIcon}
                          onClick={() => {
                            navigate(`${PATH.TRANSIT_TIME}/${item.id}`);
                            dispatch(
                              trackingPageSlice.actions.handleSetEddTransitTimeRule({
                                methodInfo: item.shippingMethod,
                                countriesCode: item.shippingDestinationCountry,
                              }),
                            );
                          }}
                          disabled={isLockedFeature}
                        />
                      </div>
                    </div>
                    {item.shippingMethod.map((ele) => (
                      <div className="line-description" key={ele.courier.toString()}>
                        <p className="left">{`${ele.courier.slice(0, 5).join(', ')}${ele.courier.length > 5 ? ',...' : ''}`}</p>
                        <p className="right"> {`${ele.first} - ${ele.second} business days`}</p>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            )
          )}
        </CustomCard>

        <Modal
          open={modal.isOpen}
          onClose={handleCloseModal}
          title="Delete Transit time"
          primaryAction={{
            content: 'Delete',
            onAction: handleDeleteTransitTime,
            loading: deletedTransitTimeData.isLoading,
            destructive: true,
          }}
          secondaryActions={[
            {
              content: 'Discard',
              onAction: handleCloseModal,
            },
          ]}
        >
          <Modal.Section>
            <ErrorBanner isVisible={!!apiError}>{apiError}</ErrorBanner>
            Are you sure you want to delete?
          </Modal.Section>
        </Modal>
      </StyledTransitTimes>
    </CustomLayoutOneThird>
  );
};

export default TransitTimes;
