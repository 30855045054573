import { CustomCard, ErrorBanner } from '@/components';
import DatePicker from '@/components/DatePicker';
import Layout from '@/components/Layout';
import { PATH } from '@/constants';
import { AccountPlan } from '@/constants/enum';
import { FileExtension, FileTypes } from '@/constants/file';
import {
  addHourQuantity,
  addMinuteQuantity,
  cleanObject,
  exportExtensionFile,
  exportListShipmentsFile,
  getExportFileName,
} from '@/helpers';
import { isLockFeature } from '@/helpers/feature';
import { getErrorFromAPI, handleToastMutation } from '@/helpers/toast';
import useConfirmationNavigate from '@/hooks/useConfirmationNavigate';
import { useProcessOldOrderMutation } from '@/redux/api/api.caller';
import commonSlice from '@/redux/features/common.slice';
import shipmentSlice, {
  datePickerSelector,
  disabledBtnSelector,
  shipmentFilterSelector,
  titleDatePickerSelector,
} from '@/redux/features/shipment.slice';
import { ActionList, ActionListItemDescriptor, Button, ButtonGroup, Modal, Popover, Tooltip } from '@shopify/polaris';
import { ExportIcon, LockIcon, RefreshIcon } from '@shopify/polaris-icons';
import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ShipmentTable from './components/ShipmentTable';
import { resyncOrderInfo } from './config';
import { StyledGroupBtn } from './styled';

interface IExtensionBtn {
  loading: boolean;
  type: FileExtension;
}

const Shipments = () => {
  const [isResyncActive, setResyncActive] = useState<boolean>(false);
  const [apiError, setApiError] = useState<string>('');
  const [extensionBtn, setExtensionBtn] = useState<IExtensionBtn>({ loading: false, type: FileExtension.XLSX });
  const dispatch = useDispatch();
  const { start, end } = useSelector(datePickerSelector);
  const titleDatePicker = useSelector(titleDatePickerSelector);
  const disabledBtn = useSelector(disabledBtnSelector);
  const { search, courier, status: shipmentStatus, sortBy, sortOrder } = useSelector(shipmentFilterSelector);
  const [resyncOrder, { isLoading }] = useProcessOldOrderMutation();
  const isDisabledBtn = new Date() < new Date(disabledBtn.time);
  const isLockStarter = isLockFeature([AccountPlan.Starter]);
  const [isOpenModalUpgrade, setIsOpenModalUpgrade] = useState<boolean>(false);
  const navigate = useConfirmationNavigate();

  const closeResyncOrderBtn = () => setResyncActive(false);
  const toggleResyncOrderBtn = () => setResyncActive((prevValue) => !prevValue);
  const handleApplyDatePicker = (start: Date, end: Date) => dispatch(shipmentSlice.actions.handleDatePicker({ start, end }));
  const handleSaveDatePickerTitle = (titleBtn: string) => dispatch(shipmentSlice.actions.handleTitleDatePicker(titleBtn));

  const actionItems: ActionListItemDescriptor[] = useMemo(() => {
    const handleDisableResyncBtn = (state: number) => {
      if (state === 1) {
        return dispatch(
          shipmentSlice.actions.handleSetDisabledBtnTime({
            ...disabledBtn,
            time: addHourQuantity(1),
            tooltip: 'Syncing data. Button disabled for 1 hour after click to prevent duplicates.',
          }),
        );
      }
      dispatch(
        shipmentSlice.actions.handleSetDisabledBtnTime({
          ...disabledBtn,
          time: addMinuteQuantity(5),
          tooltip: 'Syncing failed. Button disabled 5 mins. Retry later.',
        }),
      );
    };

    const handleClickResyncOrder = (quantity: number) => {
      closeResyncOrderBtn();
      resyncOrder({ rangeDay: quantity }).then((data) => {
        if ('data' in data && data.data && data.data?.state === 1) {
          dispatch(commonSlice.actions.handleToast(handleToastMutation(data)));
          handleDisableResyncBtn(data.data?.state);
          if (apiError) {
            setApiError('');
          }
          return;
        }
        setApiError(getErrorFromAPI(data));
      });
    };

    return resyncOrderInfo.map(({ content, dayQuantity }) => ({
      content,
      onAction: () => handleClickResyncOrder(dayQuantity),
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabledBtn, resyncOrder]);

  const handleExport = (fileExtension: FileExtension) => {
    if (isLockStarter) {
      return handleOpenModal();
    }
    handleExportShipmentFile(fileExtension);
  };
  const exportGroupButton = (
    <ButtonGroup variant="segmented">
      <Tooltip content="Export to Excel file" zIndexOverride={!isLockStarter ? 400 : -1000}>
        <Button
          icon={isLockStarter ? LockIcon : ExportIcon}
          onClick={() => handleExport(FileExtension.XLSX)}
          loading={extensionBtn.type === FileExtension.XLSX && extensionBtn.loading}
        >
          Excel
        </Button>
      </Tooltip>
      <Tooltip content="Export to CSV file" zIndexOverride={!isLockStarter ? 400 : -1000}>
        <Button
          icon={isLockStarter ? LockIcon : ExportIcon}
          onClick={() => handleExport(FileExtension.CSV)}
          loading={extensionBtn.type === FileExtension.CSV && extensionBtn.loading}
        >
          CSV
        </Button>
      </Tooltip>
    </ButtonGroup>
  );

  const handleExportShipmentFile = async (extension: FileExtension) => {
    if (isLockStarter) {
      return;
    }
    try {
      setExtensionBtn((prev) => ({ ...prev, type: extension, loading: true }));
      const { data, status } = await exportListShipmentsFile(
        cleanObject({
          extension,
          fromDate: start.toISOString(),
          toDate: end.toISOString(),
          search,
          courier,
          status: shipmentStatus,
          sortBy,
          sortOrder,
        }),
      );
      if (status === 200 && data) {
        const fileName = getExportFileName('Order', start, end);
        const fileType = FileTypes[extension.toUpperCase() as keyof typeof FileTypes];
        exportExtensionFile(data, fileName, fileType);
      }
    } catch (error) {
    } finally {
      setExtensionBtn((prev) => ({ ...prev, loading: false }));
    }
  };

  const handleUpgradeNow = () => {
    navigate(PATH.PLAN);
  };

  const handleCloseModal = () => {
    setIsOpenModalUpgrade(false);
  };

  const handleOpenModal = () => {
    setIsOpenModalUpgrade(true);
  };

  return (
    <Layout
      fullWidth
      maxWidth="80rem"
      title="List shipments"
      primaryAction={
        <Popover
          active={isResyncActive}
          autofocusTarget="first-node"
          onClose={closeResyncOrderBtn}
          activator={
            <Tooltip zIndexOverride={isDisabledBtn ? 400 : -1000} content={isDisabledBtn && disabledBtn.tooltip}>
              <Button
                variant="primary"
                icon={RefreshIcon}
                onClick={toggleResyncOrderBtn}
                loading={isLoading}
                disabled={isDisabledBtn}
              >
                Re-sync Order
              </Button>
            </Tooltip>
          }
        >
          <ActionList actionRole="menuitem" items={actionItems} />
        </Popover>
      }
    >
      <ErrorBanner isVisible={!!apiError}>{apiError}</ErrorBanner>
      <CustomCard
        title="Shipments"
        actionGroup={[
          {
            content: (
              <StyledGroupBtn>
                <Tooltip zIndexOverride={isLockStarter ? 400 : -1000} content="Upgrade plan to unlock this feature">
                  {exportGroupButton}
                </Tooltip>
                <DatePicker
                  titleBtn={titleDatePicker}
                  start={start}
                  end={end}
                  onSave={handleApplyDatePicker}
                  setTitleBtn={handleSaveDatePickerTitle}
                />
              </StyledGroupBtn>
            ),
          },
        ]}
      >
        <ShipmentTable />
      </CustomCard>
      {isLockStarter && (
        <Modal
          open={isOpenModalUpgrade}
          onClose={handleCloseModal}
          title="Upgrade to use this feature"
          primaryAction={{
            content: 'Upgrade Now',
            onAction: handleUpgradeNow,
          }}
          secondaryActions={[
            {
              content: 'Cancel',
              onAction: handleCloseModal,
            },
          ]}
        >
          <Modal.Section>
            This feature is only available on the paid plan. You need to upgrade to the Basic or higher plan to use this feature.
          </Modal.Section>
        </Modal>
      )}
    </Layout>
  );
};

export default Shipments;
