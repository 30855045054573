import { TransactionIcon, CursorIcon, SearchIcon, MinimizeIcon, IqIcon } from '@shopify/polaris-icons';
import CourierMapping from './components/CourierMapping';
import TrackingLink from './components/TrackingLink';
import UpdateTheme from './components/UpdateTheme';
import OrderLookup from './components/OrderLookup';
import Integration from './components/Integration';
import { IConfigTab } from '@/types/tab';

export const tabsConfig: Array<IConfigTab> = [
  {
    value: 'trackingLink',
    label: 'Tracking Link Set-up',
    component: <TrackingLink />,
    icon: CursorIcon,
  },
  {
    value: 'courierMapping',
    label: 'Courier Mapping',
    component: <CourierMapping />,
    icon: TransactionIcon,
  },
  {
    value: 'orderLookUp',
    label: 'Order Lookup Widget',
    component: <OrderLookup />,
    icon: SearchIcon,
  },
  {
    value: 'integration',
    label: 'Integration',
    component: <Integration />,
    icon: MinimizeIcon,
  },
  {
    value: 'updateTheme',
    label: 'Update theme 2.0',
    component: <UpdateTheme />,
    icon: IqIcon,
  },
];

export const shopifyCourierOptions = [
  '4PX',
  'USPS',
  'StarTrack',
  'Amazon Logistics UK',
  'Colissimo',
  'Royal Mail',
  'UPS',
  'Canada Post',
  'China Post',
  'FedEx',
  'PostNord',
  'DHL Express',
  'DHL eCommerce',
  'DHL eCommerce Asia',
  'Eagle',
  'Purolator',
  'Australia Post',
  'New Zealand Post',
  'Correios',
  'La Poste',
  'TNT',
  'Whistl',
  'APC',
  'FSC',
  'GLS',
  'GLS (US)',
  'Globegistics',
  'Amazon Logistics US',
  'Bluedart',
  'Delhivery',
  'Japan Post (EN)',
  'Japan Post (JA)',
  'Sagawa (EN)',
  'Sagawa (JA)',
  'Singapore Post',
  'Yamato (EN)',
  'Yamato (JA)',
  'DPD',
  'DPD UK',
  'DPD Local',
  'Newgistics',
  'SF Express',
  'PostNL',
  'YunExpress',
  'Chukou1',
  'Anjun Logistics',
  'SFC Fulfillment',
  'Canpar',
  'Sendle',
  'Couriers Please',
  'Toll IPEC',
  'Hermes UK',
  'Other',
];
