import { getOptionsSelectedAdd, getOptionsSelectedRemove } from '@/helpers';
import { Autocomplete, Badge, Icon, IconSource, LegacyStack, Tag } from '@shopify/polaris';
import { memo, useEffect, useState } from 'react';
import RegularText from '../RegularText';
import { IOption } from '@/types/option';
import { IAutoComplete } from '@/types/autocomplete';

interface IProps extends IAutoComplete.IProps {
  isAlwaysShowTag?: boolean;
  hasClearButton?: boolean;
  prefixIcon?: IconSource;
  isHideTagMarkup?: boolean;
  defaultInputValue?: string;
  disabledSetInputValueEqualSelected?: boolean;
}

function AutoCompleteWithTag({ ...props }: IProps) {
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState<Array<IOption>>([]);
  const [error, setError] = useState('');

  useEffect(() => {
    setOptions(props.options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(props.options)]);

  useEffect(() => {
    if (!props.allowMultiple && !props.disabledSetInputValueEqualSelected) {
      setInputValue(props.tag[0]?.label || '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.allowMultiple, props.disabledSetInputValueEqualSelected, JSON.stringify(props.tag)]);

  useEffect(() => {
    if (!props.requiredIndicator || props.selectedOptions.length > 0) {
      setError('');
    }
  }, [props.requiredIndicator, props.selectedOptions.length]);

  const updateText = (value: string) => {
    if (error) {
      setError('');
    }
    if (props.handleSearch) {
      setInputValue(value);
      props.handleSearch(value);
      if (props.disabledSetInputValueEqualSelected) {
        const resultOptions = props.options?.filter((option) => option.label.toLocaleLowerCase().includes(value.toLowerCase()));
        setOptions(resultOptions);
      }
    } else {
      setInputValue(value);
      const resultOptions = props.options?.filter((option) => option.label.toLocaleLowerCase().includes(value.toLowerCase()));
      setOptions(resultOptions);
    }
  };

  const handleReturnValue = (selected: string[]) => {
    const isAdd = selected.length > props.selectedOptions.length;
    const result = !props.allowMultiple
      ? [props.options.find((item) => item.value === selected[0]) || { value: '', label: '' }]
      : isAdd
      ? getOptionsSelectedAdd(selected, props.tag, props.options)
      : getOptionsSelectedRemove(selected, props.tag);
    return result;
  };

  const removeTag = (tag: string) => () => {
    const newTags = props.selectedOptions?.filter((item) => item !== tag);
    props.setSelectedOptions(handleReturnValue(newTags));
    if (newTags.length === 0) {
      props?.customHandleSetError?.('Please select at least 1 state/province');
    }
  };

  const handleOnSelect = (value: Array<string>) => {
    props.setSelectedOptions(handleReturnValue(value));

    if (error) {
      setError('');
    }
  };

  useEffect(() => {
    if (typeof props.defaultInputValue === 'string') setInputValue(props.defaultInputValue);
  }, [props.defaultInputValue]);

  const handleBlur = () => {
    // Reset input value if default value exists
    if (props.defaultInputValue) {
      setInputValue(props.defaultInputValue);
      setOptions(props.options);
    }

    // Skip validation if no validation is required
    if (!props.customHandleSetError && !props.requiredIndicator) {
      return;
    }

    // Validate empty selection
    if (props.selectedOptions.length === 0) {
      if (props.customHandleSetError) {
        props.customHandleSetError('Please select at least 1 state/province');
      } else if (props.requiredIndicator) {
        setError('This field is required');
      }
    }
  };

  const textField = (
    <Autocomplete.TextField
      error={props.customErrorMessage ? props.customErrorMessage : props.requiredIndicator ? error : undefined}
      onChange={updateText}
      label={props.label}
      value={inputValue}
      autoComplete="off"
      requiredIndicator={props.requiredIndicator}
      placeholder={props.placeholder}
      disabled={props.disable}
      helpText={props.helpText}
      onBlur={handleBlur}
      prefix={props.prefixIcon ? <Icon source={props.prefixIcon} /> : undefined}
      onClearButtonClick={() => {
        updateText('');
      }}
      clearButton={props.hasClearButton}
    />
  );

  const tagsMarkup =
    props.tag.length > 0
      ? props.tag.map((tag) => {
          return (
            <Tag
              key={`option${tag.value}`}
              onRemove={props.allowMultiple && !props.isAlwaysShowTag ? removeTag(tag.value) : undefined}
              disabled={props.disable}
            >
              {tag.label || ''}
            </Tag>
          );
        })
      : null;

  const selectedTagMarkup =
    props.selectedOptions.length > 0 && (props.allowMultiple || props.isAlwaysShowTag) ? (
      <div className="mt-8">
        <LegacyStack spacing="extraTight">{tagsMarkup}</LegacyStack>
      </div>
    ) : null;

  return (
    <div>
      <Autocomplete
        allowMultiple={props.allowMultiple}
        options={options.map((item) => {
          return {
            ...item,
            label:
              props.badge && item.badge ? (
                <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                  <RegularText>{item.label}</RegularText>
                  {item.badgeContent ? <Badge tone="attention">{item.badgeContent}</Badge> : props.badge}
                </div>
              ) : (
                item.label
              ),
          };
        })}
        selected={props.selectedOptions}
        textField={textField}
        onSelect={handleOnSelect}
      />
      {props.isHideTagMarkup ? null : selectedTagMarkup}
    </div>
  );
}

export default memo(AutoCompleteWithTag);
