import { Column, CustomCard, Row } from '@/components';
import CustomLayoutOneThird from '@/components/CustomLayoutOneThird';
import { ANY_SHIPPING_METHOD, REST_OF_WORLD } from '@/constants';
import { AccountPlan, CRUD } from '@/constants/enum';
import { isLockFeature } from '@/helpers';
import { useGetEDDSettingsQuery, useGetListCourierQuery } from '@/redux/api/api.caller';
import { accountSelector, christmasBannerSelector } from '@/redux/features/plan.slice';
import trackingPageSlice, { eddSelector } from '@/redux/features/trackingPage.slice';
import { IShippingMethod } from '@/types/edd';
import { Button, Checkbox, Collapsible, Modal, SkeletonBodyText, Tag, Text, TextField, useBreakpoints } from '@shopify/polaris';
import { DeleteIcon, EditIcon } from '@shopify/polaris-icons';
import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { shippingMethodError } from '../../config';
import { StyledShippingMethod, StyledShippingMethodModal } from './styled';

const ShippingMethod = () => {
  const dispatch = useDispatch();
  const { value: edd } = useSelector(eddSelector);
  const plan = useSelector(accountSelector);
  const { formattedEndDate } = useSelector(christmasBannerSelector);
  const isActiveChristmasFeature = new Date() < new Date(formattedEndDate);
  const isLockedFeature =
    isLockFeature([AccountPlan.Starter, AccountPlan.Basic]) && !plan.isOldPaidUser && !isActiveChristmasFeature;
  const { isLoading: isEddSettingLoading } = useGetEDDSettingsQuery();
  const { data, isLoading } = useGetListCourierQuery({ page: 1, perPage: 500 }, { skip: !edd.shippingMethod.modal.isCollapse });
  const isRestOfWorld = edd.shippingZone.countriesCode.some((code) => code === REST_OF_WORLD);
  const isAnyShippingMethod = edd.shippingMethod.courier.some((courier) => courier === ANY_SHIPPING_METHOD);
  const { smDown } = useBreakpoints();

  useEffect(() => {
    if (data) {
      dispatch(
        trackingPageSlice.actions.handleChangeEDDValue({
          ...edd,
          shippingMethod: {
            ...edd.shippingMethod,
            courierList: data.data.couriers,
          },
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const isCheckedShippingMethod = useCallback(
    (courier: string) => edd.shippingMethod.courier.some((item) => item.toLowerCase() === courier.toLowerCase()),
    [edd.shippingMethod.courier],
  );

  const isNotCheckedShippingMethodExcept = useCallback(
    (courier: string) => {
      const shippingMethodExcept = edd.shippingMethod.methodInfo
        .filter((_, index) => index !== edd.shippingMethod.modal.id)
        .map((method) => method.courier)
        .flat(1);
      return shippingMethodExcept.some((item) => item.toLowerCase() === courier.toLowerCase());
    },
    [edd.shippingMethod.methodInfo, edd.shippingMethod.modal.id],
  );

  const isDisableShippingMethod = useCallback(
    (courier: string) => {
      const mergedArr = edd.shippingMethod.methodInfo.map(({ courier }) => courier).flat(1);
      let removedCourier = [];
      if (edd.shippingMethod.modal.type === CRUD.Update) {
        removedCourier = mergedArr.filter((item) => isNotCheckedShippingMethodExcept(item));
      } else {
        removedCourier = mergedArr.filter((item) => !isCheckedShippingMethod(item));
      }
      return removedCourier.some((item) => item.toLowerCase() === courier.toLowerCase());
    },
    [edd.shippingMethod.methodInfo, edd.shippingMethod.modal.type, isCheckedShippingMethod, isNotCheckedShippingMethodExcept],
  );

  const listCourier = useMemo(() => data?.data?.couriers || [], [data?.data?.couriers]);

  const handleClickAddNewShippingMethodBtn = () =>
    dispatch(
      trackingPageSlice.actions.handleChangeEDDValue({
        ...edd,
        shippingMethod: {
          ...edd.shippingMethod,
          courier: [],
          from: '4',
          to: '7',
          search: '',
          courierList: listCourier,
          modal: { ...edd.shippingMethod.modal, type: CRUD.Create, isActive: true },
        },
      }),
    );

  const handleCollapseShippingMethod = () =>
    dispatch(
      trackingPageSlice.actions.handleChangeEDDValue({
        ...edd,
        shippingMethod: {
          ...edd.shippingMethod,
          modal: { ...edd.shippingMethod.modal, isCollapse: true },
        },
      }),
    );

  const handleRemoveTag = (courier: string) => {
    dispatch(
      trackingPageSlice.actions.handleChangeEDDValue({
        ...edd,
        shippingMethod: { ...edd.shippingMethod, courier: edd.shippingMethod.courier.filter((day) => day !== courier) },
      }),
    );
    edd.shippingMethod.courier.filter((day) => day !== courier);
  };

  const handleClickDeleteBtn = (index: number) =>
    dispatch(
      trackingPageSlice.actions.handleChangeEDDValue({
        ...edd,
        shippingMethod: {
          ...edd.shippingMethod,
          methodInfo: edd.shippingMethod.methodInfo.filter((_, idx) => idx !== index),
        },
      }),
    );

  const handleClickEditBtn = (method: IShippingMethod, index: number) =>
    dispatch(
      trackingPageSlice.actions.handleChangeEDDValue({
        ...edd,
        shippingMethod: {
          ...edd.shippingMethod,
          courier: method.courier,
          from: method.first,
          to: method.second,
          courierList: listCourier,
          modal: { ...edd.shippingMethod.modal, type: CRUD.Update, isActive: true, id: index },
        },
      }),
    );

  const handleCloseModal = () =>
    dispatch(
      trackingPageSlice.actions.handleChangeEDDValue({
        ...edd,
        shippingMethod: {
          ...edd.shippingMethod,
          courier: [],
          from: '4',
          to: '7',
          search: '',
          courierList: listCourier,
          modal: { ...edd.shippingMethod.modal, type: CRUD.Read, isActive: false },
        },
      }),
    );

  const handleChangeOptions = useCallback(
    (value: string | boolean, key: keyof typeof edd.shippingMethod) => {
      if (typeof value === 'string') {
        const filteredCourier = listCourier.filter((item) => item.display_name.toLowerCase().includes(value.toLowerCase()));
        dispatch(
          trackingPageSlice.actions.handleChangeEDDValue({
            ...edd,
            shippingMethod: {
              ...edd.shippingMethod,
              courierList: key === 'search' ? filteredCourier : edd.shippingMethod.courierList,
              [key]: key === 'from' || key === 'to' ? String(Math.abs(Number(value))) : value,
            },
          }),
        );
      }
      if (typeof value === 'boolean') {
        const updatedCourier = value
          ? [...edd.shippingMethod.courier, key]
          : edd.shippingMethod.courier.filter((day) => day !== key);
        dispatch(
          trackingPageSlice.actions.handleChangeEDDValue({
            ...edd,
            shippingMethod: { ...edd.shippingMethod, courier: updatedCourier },
          }),
        );
      }
    },
    [dispatch, edd, listCourier],
  );

  const handleSubmitModalForm = () => {
    if (edd.shippingMethod.modal.type === CRUD.Update) {
      const updatedShippingMethod: IShippingMethod[] = edd.shippingMethod.methodInfo.map((method, index) => {
        if (edd.shippingMethod.modal.id === index) {
          return {
            courier: edd.shippingMethod.courier,
            first: edd.shippingMethod.from,
            second: edd.shippingMethod.to,
          };
        }
        return method;
      });
      return dispatch(
        trackingPageSlice.actions.handleChangeEDDValue({
          ...edd,
          shippingMethod: {
            ...edd.shippingMethod,
            methodInfo: updatedShippingMethod,
            modal: { ...edd.shippingMethod.modal, type: CRUD.Read, isActive: false },
          },
        }),
      );
    }
    dispatch(
      trackingPageSlice.actions.handleChangeEDDValue({
        ...edd,
        shippingMethod: {
          ...edd.shippingMethod,
          methodInfo: [
            ...edd.shippingMethod.methodInfo,
            {
              courier: edd.shippingMethod.courier,
              first: edd.shippingMethod.from,
              second: edd.shippingMethod.to,
            },
          ],
          modal: { ...edd.shippingMethod.modal, type: CRUD.Read, isActive: false },
        },
      }),
    );
  };

  const renderCollapsibleContent = useCallback(() => {
    if (isLoading) {
      return (
        <div style={{ padding: '1rem 0' }}>
          <SkeletonBodyText lines={9} />
        </div>
      );
    }
    if (edd.shippingMethod.courierList.length === 0) {
      return <p className="no-data">No result to display</p>;
    }
    return (
      <>
        {edd.shippingMethod.courierList.map((item) => (
          <Checkbox
            key={item.id}
            id={item.display_name}
            label={item.display_name}
            checked={isCheckedShippingMethod(item.display_name)}
            onChange={handleChangeOptions}
            disabled={isDisableShippingMethod(item.display_name) || isLockedFeature}
          />
        ))}
      </>
    );
  }, [
    edd.shippingMethod.courierList,
    handleChangeOptions,
    isCheckedShippingMethod,
    isDisableShippingMethod,
    isLoading,
    isLockedFeature,
  ]);

  const renderShippingMethod = () => {
    if (isEddSettingLoading) {
      return <SkeletonBodyText lines={5} />;
    }
    if (edd.shippingMethod.methodInfo.length === 0) {
      return <p className="no-data-text">You haven't created any shipping method & transit time yet</p>;
    }
    return (
      <>
        {edd.shippingMethod.methodInfo.map((method, index) => (
          <div className="info-detail" key={`${method.courier}${method.first}${method.second}${index}`}>
            <Row column={smDown ? 1 : 3} style={{ width: '100%' }} gap={smDown ? '0.5rem' : '1rem'}>
              <Column span={smDown ? 1 : 2}>{method.courier.join(', ')}</Column>
              <Column style={{ marginBlock: 'auto' }}>{`${method.first} - ${method.second} business days`}</Column>
            </Row>
            <div className="group-btn">
              <Button icon={EditIcon} onClick={() => handleClickEditBtn(method, index)} disabled={isLockedFeature} />
              <Button icon={DeleteIcon} onClick={() => handleClickDeleteBtn(index)} disabled={isRestOfWorld || isLockedFeature} />
            </div>
          </div>
        ))}
      </>
    );
  };

  return (
    <>
      <StyledShippingMethod>
        <CustomLayoutOneThird
          notUseCard
          title="Shipping method & Transit time"
          description={<p>Excluding non-business days from estimated delivery date</p>}
        >
          <CustomCard
            title="Shipping method list"
            headerStyles={{ paddingBottom: '12px', paddingTop: '20px' }}
            actionGroup={[
              {
                content: 'Add new shipping method',
                variant: 'primary',
                onAction: handleClickAddNewShippingMethodBtn,
                disabled: isRestOfWorld || isLockedFeature,
              },
            ]}
          >
            <div className="info-title">
              <Row column={smDown ? 1 : 3} style={{ width: '100%' }}>
                <Column span={smDown ? 1 : 2}>
                  <Text variant="headingSm" as="h6">
                    Shipping method
                  </Text>
                </Column>
                {!smDown && (
                  <Column>
                    <Text variant="headingSm" as="h6">
                      Transit time
                    </Text>
                  </Column>
                )}
              </Row>
              <Text variant="headingSm" as="h6">
                Actions
              </Text>
            </div>
            {renderShippingMethod()}
          </CustomCard>
        </CustomLayoutOneThird>
      </StyledShippingMethod>

      <Modal
        open={edd.shippingMethod.modal.isActive}
        onClose={handleCloseModal}
        title={edd.shippingMethod.modal.type === CRUD.Update ? 'Update Shipping Method' : 'New Shipping Method'}
        primaryAction={{
          content: edd.shippingMethod.modal.type === CRUD.Update ? 'Update' : 'Save',
          disabled:
            Number(edd.shippingMethod.from) >= 1000 ||
            Number(edd.shippingMethod.to) >= 1000 ||
            edd.shippingMethod.courier.length === 0,
          onAction: handleSubmitModalForm,
        }}
        secondaryActions={[
          {
            content: 'Discard',
            onAction: handleCloseModal,
          },
        ]}
      >
        <Modal.Section>
          <StyledShippingMethodModal>
            <Text variant="headingSm" as="h6">
              Choose shipping method
            </Text>
            {edd.shippingMethod.courier.length === 0 ? (
              <p className="text-description">{shippingMethodError.SHIPPING_METHOD}</p>
            ) : (
              edd.shippingMethod.courier.map((courier) => (
                <Tag key={courier.toString()} onRemove={() => handleRemoveTag(courier)} disabled={isRestOfWorld}>
                  {courier}
                </Tag>
              ))
            )}
            <TextField
              label=""
              id="search"
              placeholder="Search: USPS, UPS..."
              value={edd.shippingMethod.search}
              onChange={handleChangeOptions}
              onFocus={handleCollapseShippingMethod}
              autoComplete="off"
              disabled={isAnyShippingMethod || isLockedFeature}
            />
            <Collapsible
              open={edd.shippingMethod.modal.isCollapse && !isAnyShippingMethod}
              id="shipping-collapsible"
              transition={{
                duration: '500ms',
                timingFunction: 'ease-in-out',
              }}
            >
              <div className="collapsible-content">{renderCollapsibleContent()}</div>
            </Collapsible>

            <div className="mt-16" />

            <Text variant="headingSm" as="h6">
              Transit times
            </Text>
            <p className="text-description">
              If you want to set up a single date, please select the time range of the transit time from 1 to 1
            </p>
            <div className="transit-time">
              <TextField
                label=""
                id="from"
                type="number"
                maxLength={3}
                max={999}
                autoComplete="off"
                value={JSON.stringify(Math.floor(Math.abs(Number(edd.shippingMethod.from))))}
                onChange={handleChangeOptions}
                disabled={isLockedFeature}
              />
              <span>to</span>
              <TextField
                label=""
                id="to"
                type="number"
                maxLength={3}
                max={999}
                autoComplete="off"
                value={JSON.stringify(Math.floor(Math.abs(Number(edd.shippingMethod.to))))}
                onChange={handleChangeOptions}
                disabled={isLockedFeature}
              />
              <span>business days.</span>
            </div>
            {(Number(edd.shippingMethod.from) >= 1000 || Number(edd.shippingMethod.to) >= 1000) && (
              <div className="transit-time-err">{shippingMethodError.TRANSIT_TIME}</div>
            )}
          </StyledShippingMethodModal>
        </Modal.Section>
      </Modal>
    </>
  );
};

export default ShippingMethod;
