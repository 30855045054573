import { Collapsible, Divider, Icon, Text, TextProps } from '@shopify/polaris';
import { ChevronRightIcon } from '@shopify/polaris-icons';
import { ReactNode, memo, useState } from 'react';
import { CollapsibleStyled } from './styled';

interface Props {
  title: ReactNode;
  children: ReactNode;
  variant?: TextProps['variant'];
  open: boolean;
  paddingTitle?: string;
  paddingChildren?: string;
  isNotShowDivider?: boolean;
}

const CustomCollapsible = ({
  title,
  children,
  variant,
  open,
  paddingTitle = '0.5rem',
  paddingChildren = '1rem',
  isNotShowDivider,
}: Props) => {
  const [isOpen, setIsOpen] = useState(open);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <CollapsibleStyled isOpen={isOpen} paddingTitle={paddingTitle} paddingChildren={paddingChildren}>
      <div className="header" onClick={handleClick} aria-expanded={isOpen} aria-controls="basic-collapsible">
        <Text as="h6" variant={variant ? variant : 'headingSm'}>
          {title}
        </Text>
        <div style={{ transform: isOpen ? 'rotate(90deg)' : 'rotate(0deg)', transition: 'all 0.5s' }}>
          <Icon source={ChevronRightIcon} tone="base" />
        </div>
      </div>
      {isNotShowDivider ? null : <Divider />}
      <Collapsible
        expandOnPrint
        open={isOpen}
        id="basic-collapsible"
        transition={{ duration: '500ms', timingFunction: 'ease-in-out' }}
      >
        <div className="children">{children}</div>
      </Collapsible>
    </CollapsibleStyled>
  );
};

export default memo(CustomCollapsible);
