import { useCallback, useEffect, useMemo, useState } from 'react';
import Layout, { ILayoutProps } from '.';
import ControlBar from '../ControlBar';
import { isLockFeature, isUndefinedArray } from '@/helpers';
import { useDispatch, useSelector } from 'react-redux';
import trackingPageSlice, {
  blacklistingSelector,
  eddSelector,
  IConfiguration,
  ILookAndFeel,
  languageSelector,
  trackingPageSelector,
} from '@/redux/features/trackingPage.slice';
import { apiCaller, useGetBlacklistingQuery } from '@/redux/api/api.caller';
import commonSlice from '@/redux/features/common.slice';
import { getErrorFromAPI, handleToastMutation } from '@/helpers/toast';
import planSlice, { accountSelector, christmasBannerSelector } from '@/redux/features/plan.slice';
import ErrorBanner from '../ErrorBanner';
import { AccountPlan, Language, ProductRecommendType, ProgressBar, ShippingInfo } from '@/constants/enum';
import { ITranslation } from '@/types/trackingpage';
import { hexToHSB } from '@/helpers/color';
import { defaultTransitTimes } from '@/pages/TrackingPage/components/EDD/config';
import { BlacklistReplaceWith } from '@/constants';

interface IProps extends ILayoutProps {
  isFixed?: boolean;
}

const TrackingPageLayout = ({ children, isFixed, ...props }: IProps) => {
  const dispatch = useDispatch();
  const plan = useSelector(accountSelector);
  const { formattedEndDate } = useSelector(christmasBannerSelector);
  const isActiveChristmasFeature = new Date() < new Date(formattedEndDate);
  const isLock = isLockFeature([AccountPlan.Starter]);
  const isLockedFeature =
    isLockFeature([AccountPlan.Starter, AccountPlan.Basic]) && !plan.isOldPaidUser && !isActiveChristmasFeature;
  const trackingPage = useSelector(trackingPageSelector);
  const {
    value: {
      lookAndFeel: {
        content: { shippingInfo, progressBar },
      },
    },
    isOpenModalTrackingPage,
  } = trackingPage;
  const language = useSelector(languageSelector);
  const blacklisting = useSelector(blacklistingSelector);
  const edd = useSelector(eddSelector);
  const accountState = useSelector(accountSelector);

  const [apiError, setApiError] = useState<string>('');

  const [updateTrackingPage, { isLoading }] = apiCaller.useUpdateTrackingPageMutation();
  const [updateTranslation, { isLoading: isLoadingUpdateTranslation }] = apiCaller.useUpdateTranslationMutation();
  const [updateBlacklisting, { isLoading: isLoadingUpdateBlacklisting }] = apiCaller.useUpdateBlacklistingMutation();
  const [updateEdd, { isLoading: isLoadingUpdateEdd }] = apiCaller.useUpdateEddSettingsMutation();

  const getTrackingPage = apiCaller.useGetTrackingPageQuery(undefined, {
    skip: isLock,
  });
  const { data: translationData } = apiCaller.useGetTranslationQuery({ language: language.oldValue.languageConfig.language });
  const { data: eddData, isLoading: isLoadingEdd } = apiCaller.useGetEDDSettingsQuery(undefined, { skip: isLockedFeature });
  const { data: blackListingData, isLoading: isLoadingBlackListing } = useGetBlacklistingQuery(undefined, {
    skip: isLockedFeature,
  });

  const handleResponse = useCallback(
    (data: ITranslation) => {
      const {
        trackingStatus,
        trackingForm,
        trackingHistory,
        progressBar,
        orderDetails,
        review,
        footer,
        googleTranslateExtension,
        googleTranslatePosition,
        language: languageData,
      } = data;
      const value = {
        footer: { privacy: footer.privacyPage, return: footer.returnPage, support: footer.support, term: footer.termPage },
        orderDetails: {
          shipmentItems: orderDetails.shipment_items,
          product: orderDetails.product,
          totalPrice: orderDetails.total_price,
          itemSingular: orderDetails.item_singular,
          itemPlural: orderDetails.item_plural,
        },
        progressBar,
        review: { buttonText: review.button_text, reviewTitle: review.review_title },
        trackingForm: {
          trackYourOrder: trackingForm.title,
          orderId: trackingForm.order_id,
          email: trackingForm.email,
          phoneNumber: trackingForm.phone,
          or: trackingForm.or,
          trackingNumber: trackingForm.tracking_number,
          track: trackingForm.button,
          placeholderTrackingNumber: trackingForm.plh_tracking_number,
          placeholderOrderId: trackingForm.plh_order_id,
          placeholderEmail: trackingForm.plh_email,
          placeholderPhoneNumber: trackingForm.plh_phone,
          orderNotFound: trackingForm.order_not_found,
          emptyEmail: trackingForm.empty_email,
          invalidEmail: trackingForm.invalid_email,
          emptyTrackingNumber: trackingForm.empty_tracking_number,
          emptyOrderName: trackingForm.empty_order_name,
          emptyPhoneNumber: trackingForm.empty_phone_number,
        },
        trackingHistory: {
          shipmentInformation: trackingHistory.shipment_information,
          shippingAddress: trackingHistory.shipping_address,
          trackingNumber: trackingHistory.tracking_number,
          shipmentHistory: trackingHistory.shipment_history,
          carrier: trackingHistory.carrier,
        },
        trackingStatus: {
          ordered: trackingStatus.ordered,
          informationReceived: trackingStatus.information_received,
          inTransit: trackingStatus.in_transit,
          outForDelivery: trackingStatus.out_for_delivery,
          delivered: trackingStatus.delivered,
          courierNotSupported: trackingStatus.courier_not_supported,
          expired: trackingStatus.expired,
          failedAttempt: trackingStatus.failed_attempt,
          exception: trackingStatus.exception,
          pending: trackingStatus.pending,
        },
      };
      dispatch(
        trackingPageSlice.actions.handleLanguageValue({
          ...value,
        }),
      );
      dispatch(
        trackingPageSlice.actions.handleLanguageOldValue({
          ...language.oldValue,
          value,
          languageConfig: {
            language: languageData as Language,
            googleTranslateExtension,
            googleTranslatePosition,
          },
        }),
      );
      dispatch(
        trackingPageSlice.actions.handleLanguageConfig({
          language: languageData as Language,
          googleTranslateExtension,
          googleTranslatePosition,
        }),
      );
    },
    [dispatch, language.oldValue],
  );
  useEffect(() => {
    if (translationData?.data) {
      handleResponse(translationData.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [translationData?.data]);

  useEffect(() => {
    dispatch(trackingPageSlice.actions.handleTrackingPageIsloading(getTrackingPage.isLoading));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getTrackingPage.isLoading]);
  // const { isLock, selectedTab } = useMemo(() => {
  //   const isLock = isLockFeature([AccountPlan.Starter, AccountPlan.Basic]) && !accountState.isOldPaidUser;
  //   const selectedTab = TrackingPageTabs.ProductRecommendation;
  //   return {
  //     isLock,
  //     selectedTab,
  //   };
  // }, [accountState.isOldPaidUser]);

  useEffect(() => {
    const data = getTrackingPage.data?.data;
    if (data) {
      const { lookAndFeel, configuration } = data;
      const trackingPageValue: { lookAndFeel: ILookAndFeel; configuration: IConfiguration } = {
        lookAndFeel: {
          chooseTheme: lookAndFeel.general.theme.toString(),
          lookupOption: {
            method: lookAndFeel.trackingForm.showMethods,
            isEnabledEmail: lookAndFeel.isEnabledEmail,
            isEnabledOrderId: lookAndFeel.isEnabledOrderId,
            isEnabledPhoneNumber: lookAndFeel.isEnabledPhoneNumber,
            isEnabledTrackingNumber: lookAndFeel.isEnabledTrackingNumber,
            requireEmail: lookAndFeel.trackingForm.requireEmail,
            requireOrderId: lookAndFeel.trackingForm.requireOrderId,
            requirePhoneNumber: lookAndFeel.trackingForm.requirePhoneNumber,
            requireTrackingNumber: lookAndFeel.trackingForm.requireTrackingNumber,
          },
          content: {
            shippingInfo: lookAndFeel.general.show.split(',') as Array<ShippingInfo>,
            format: {
              dateFormat: lookAndFeel.general.dateFormat,
              timeFormat: lookAndFeel.general.timeFormat,
            },
            progressBar: lookAndFeel.displayStatusBar.split(',') as Array<ProgressBar>,
          },
          style: {
            btnRadius: lookAndFeel.general.buttonBorder,
            colorPicker: {
              btnColor: hexToHSB(lookAndFeel.general.buttonColor),
              btnNameColor: hexToHSB(lookAndFeel.general.buttonTextColor),
              mainColor: hexToHSB(lookAndFeel.general.mainColor),
              textColor: hexToHSB(lookAndFeel.general.textColor),
            },
          },
        },
        configuration: {
          notInfoOrder: {
            isNoInfo: configuration.customMessage.trackUrl.isActive,
            isNotFound: configuration.customMessage.isReplaceMessage,
            message: configuration.customMessage.replaceMessage,
          },
          customStatus: {
            isCustomStatus: configuration.customStatus.enabled,
            activeAfter: configuration.customStatus.timeActive.toString(),
            icon: configuration.customStatus.icon.toString(),
            statusName: configuration.customStatus.label,
          },
          productRecommendation: {
            enable: configuration.productRecommend.isActive,
            title: isLock && !plan.isOldPaidUser ? '' : configuration.productRecommend.title,
            type: isLock && !plan.isOldPaidUser ? ProductRecommendType.AllProduct : configuration.productRecommend.type,
            value:
              configuration.productRecommend.type === ProductRecommendType.productType
                ? configuration.productRecommend.productType
                : configuration.productRecommend.tags,
          },
          // review: {
          //   settings: {
          //     isEnableReview: configuration.reviewQuestions.isActive,
          //     isDisabledDisplayReview: configuration.reviewQuestions.reviewSettings.isActive,
          //     displayReviewOption: configuration.reviewQuestions.reviewSettings.show,
          //   },
          //   question_1: {
          //     display: configuration.reviewQuestions.question_1.isDisplay,
          //     value: configuration.reviewQuestions.question_1.title,
          //     oneStarTitle: configuration.reviewQuestions.question_1.iconStatusBad?.toString(),
          //     fiveStarTitle: configuration.reviewQuestions.question_1.iconStatusGood.toString(),
          //     displayReasons: configuration.reviewQuestions.question_1.isDisplayReason,
          //     minReason: configuration.reviewQuestions.question_1.limitLabelRequired?.toString(),
          //     reasons: configuration.reviewQuestions.question_1.labelQuestion,
          //   },
          //   question_2: {
          //     display: configuration.reviewQuestions.question_2.isDisplay,
          //     value: configuration.reviewQuestions.question_2.title,
          //     oneStarTitle: configuration.reviewQuestions.question_2.iconStatusBad.toString(),
          //     fiveStarTitle: configuration.reviewQuestions.question_2.iconStatusGood.toString(),
          //   },
          //   question_3: {
          //     display: configuration.reviewQuestions.question_3.isDisplay,
          //     value: configuration.reviewQuestions.question_3.title,
          //     oneStarTitle: configuration.reviewQuestions.question_3.iconStatusBad.toString(),
          //     fiveStarTitle: configuration.reviewQuestions.question_3.iconStatusGood.toString(),
          //   },
          //   additionalComment: {
          //     display: configuration.reviewQuestions.additionalComment.isDisplay,
          //     value: configuration.reviewQuestions.additionalComment.title,
          //   },
          //   thankMessage: {
          //     display: configuration.reviewQuestions.thankMsg.isDisplay,
          //     value: configuration.reviewQuestions.thankMsg.title,
          //   },
          // },
          footerLink: {
            ...configuration.footerLinks.policy,
            ...configuration.footerLinks.social,
            isEnabledFooterLink: configuration.footerLinks.isEnabledFooterLink,
          },
          additionalContent: {
            ...configuration.additional,
          },
        },
      };

      // configuration
      dispatch(trackingPageSlice.actions.handleTrackingPageValue(trackingPageValue));
      dispatch(trackingPageSlice.actions.handleTrackingPageOldValue(trackingPageValue));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getTrackingPage.data?.data]);

  useEffect(() => {
    if (eddData && eddData.data) {
      const newEddValue = {
        isEnableBtn: eddData.data.enabled,
        orderDescription: {
          cutoffTime: eddData.data.cutoffTime,
          timezone: eddData.data.timezone,
          processingTime: eddData.data.processingTime,
          businessDays: eddData.data.businessDays,
        },
        transitTimes: eddData.data.transitTime,
      };
      dispatch(
        trackingPageSlice.actions.handleChangeEDDValue({
          ...edd.value,
          ...newEddValue,
        }),
      );
      dispatch(
        trackingPageSlice.actions.handleChangeEDDOldValue({
          ...edd.oldValue,
          ...newEddValue,
        }),
      );
      return;
    }
    dispatch(
      trackingPageSlice.actions.handleChangeEDDValue({
        ...edd.value,
        transitTimes: defaultTransitTimes,
      }),
    );
    dispatch(
      trackingPageSlice.actions.handleChangeEDDOldValue({
        ...edd.oldValue,
        transitTimes: defaultTransitTimes,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eddData]);
  useEffect(() => {
    dispatch(trackingPageSlice.actions.handleChangeEDDIsLoading(isLoadingEdd));
  }, [dispatch, isLoadingEdd]);

  useEffect(() => {
    if (
      blackListingData &&
      blackListingData.data &&
      blackListingData.data.areaToReplaceWith &&
      blackListingData.data.areasToBeReplaced
    ) {
      const newBlacklistingValue = {
        ...blacklisting.value,
        replaceWith: blackListingData.data.replaceWith,
        areasToBeReplaced: { ...blacklisting.value.areasToBeReplaced, selected: blackListingData.data.areasToBeReplaced },
        areaToReplaceWith: {
          ...blacklisting.value.areaToReplaceWith,
          country: {
            ...blacklisting.value.areaToReplaceWith.country,
            selected: blackListingData.data.areaToReplaceWith.country ? [blackListingData.data.areaToReplaceWith.country] : [],
          },
          city: {
            ...blacklisting.value.areaToReplaceWith.city,
            selected: blackListingData.data.areaToReplaceWith.city ? [blackListingData.data.areaToReplaceWith.city] : [],
          },
        },
      };
      dispatch(trackingPageSlice.actions.handleChangeBlacklistingValue(newBlacklistingValue));
      dispatch(trackingPageSlice.actions.handleChangeBlacklistingOldValue(newBlacklistingValue));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blackListingData]);
  useEffect(() => {
    dispatch(trackingPageSlice.actions.handleChangeBlacklistingIsLoading(isLoadingBlackListing));
  }, [dispatch, isLoadingBlackListing]);

  useEffect(() => {
    if (Object.entries(trackingPage.fieldsChange).length > 0) {
      dispatch(trackingPageSlice.actions.handleTrackingPageFieldsChange({}));
    }
    if (Object.entries(language.fieldsChange).length > 0) {
      dispatch(trackingPageSlice.actions.handleLanguageFieldsChange({}));
    }
    if (Object.entries(blacklisting.fieldsChange).length > 0) {
      dispatch(trackingPageSlice.actions.handleChangeBlacklistingFieldsChange({}));
    }
    if (Object.entries(edd.fieldsChange).length > 0) {
      dispatch(trackingPageSlice.actions.handleChangeEDDFieldsChange({}));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { isHiddenControlBar, isChangeTrackingPage, isChangeLanguage, isChangeBlacklisting, isChangeEDD } = useMemo(() => {
    const isChangeTrackingPage =
      !isUndefinedArray(Object.values(trackingPage.fieldsChange)) || Object.entries(trackingPage.fieldsChange).length > 0;
    const isChangeLanguage =
      !isUndefinedArray(Object.values(language.fieldsChange)) || Object.entries(language.fieldsChange).length > 0;
    const isChangeBlacklisting =
      !isUndefinedArray(Object.values(blacklisting.fieldsChange)) || Object.entries(blacklisting.fieldsChange).length > 0;
    const isChangeEDD = !isUndefinedArray(Object.values(edd.fieldsChange)) || Object.entries(edd.fieldsChange).length > 0;

    return {
      isHiddenControlBar:
        (!isChangeTrackingPage && !isChangeLanguage && !isChangeBlacklisting && !isChangeEDD) || !!isOpenModalTrackingPage,
      isChangeTrackingPage,
      isChangeLanguage,
      isChangeBlacklisting,
      isChangeEDD,
    };
  }, [blacklisting.fieldsChange, edd.fieldsChange, isOpenModalTrackingPage, language.fieldsChange, trackingPage.fieldsChange]);

  const actionGroup = useMemo(() => {
    return [
      {
        content: 'Discard',
        onAction: () => {
          dispatch(
            trackingPageSlice.actions.handleTrackingPageValue({
              ...trackingPage.oldValue,
            }),
          );
          dispatch(trackingPageSlice.actions.handleTrackingPageFieldsChange({}));
          dispatch(trackingPageSlice.actions.handleChangeValidation([]));

          dispatch(
            trackingPageSlice.actions.handleLanguageValue({
              ...language.oldValue.value,
            }),
          );
          dispatch(trackingPageSlice.actions.handleLanguageFieldsChange({}));
          dispatch(trackingPageSlice.actions.handleLanguageConfig({ ...language.oldValue.languageConfig }));
          dispatch(trackingPageSlice.actions.handleChangeValidationLanguage([]));

          dispatch(
            trackingPageSlice.actions.handleChangeBlacklistingValue({
              ...blacklisting.oldValue,
            }),
          );
          dispatch(trackingPageSlice.actions.handleChangeBlacklistingFieldsChange({}));

          dispatch(trackingPageSlice.actions.handleChangeEDDValue(edd.oldValue));
          dispatch(trackingPageSlice.actions.handleChangeEDDFieldsChange({}));
        },
        primary: false,
      },
      {
        content: 'Save',
        disabled:
          !!trackingPage.validation.length ||
          !shippingInfo.length ||
          !progressBar.length ||
          (blacklisting.value.replaceWith === BlacklistReplaceWith.AnotherCity &&
            !blacklisting.value.areasToBeReplaced.selected.length) ||
          isLockedFeature,
        onAction: () => {
          if (isChangeTrackingPage) {
            updateTrackingPage(trackingPage.fieldsChange)
              .then((res) => {
                if ('data' in res && res.data && res.data?.state === 1) {
                  dispatch(trackingPageSlice.actions.handleTrackingPageFieldsChange({}));
                  dispatch(commonSlice.actions.handleToast(handleToastMutation(res)));
                  dispatch(trackingPageSlice.actions.handleTrackingPageOldValue(trackingPage.value));

                  // check show review table tab in sidebar
                  if (trackingPage.fieldsChange?.reviewQuestions?.isActive !== accountState.isEnabledReview) {
                    dispatch(
                      planSlice.actions.handleChangePlan({
                        ...accountState,
                        isEnabledReview: trackingPage.fieldsChange?.reviewQuestions?.isActive,
                      }),
                    );
                  }
                  if (apiError) {
                    setApiError('');
                  }
                  return;
                }
                setApiError(getErrorFromAPI(res));
              })
              .catch((err) => {});
          }
          if (isChangeLanguage) {
            updateTranslation(language.fieldsChange)
              .then((res) => {
                if ('data' in res && res.data && res.data?.state === 1) {
                  dispatch(commonSlice.actions.handleToast(handleToastMutation(res)));
                  dispatch(trackingPageSlice.actions.handleLanguageFieldsChange({}));
                  dispatch(
                    trackingPageSlice.actions.handleLanguageOldValue({
                      value: language.value,
                      languageConfig: language.languageConfig,
                    }),
                  );
                  if (apiError) {
                    setApiError('');
                  }
                  return;
                }
                setApiError(getErrorFromAPI(res));
              })
              .catch((err) => {});
          }
          if (isChangeBlacklisting) {
            updateBlacklisting(blacklisting.fieldsChange).then((res) => {
              if ('data' in res && res.data && res.data?.state === 1) {
                dispatch(commonSlice.actions.handleToast(handleToastMutation(res)));
                dispatch(trackingPageSlice.actions.handleChangeBlacklistingFieldsChange({}));
                dispatch(trackingPageSlice.actions.handleChangeBlacklistingOldValue(blacklisting.value));
                if (apiError) {
                  setApiError('');
                }
                return;
              }
              setApiError(getErrorFromAPI(res));
            });
          }
          if (isChangeEDD) {
            updateEdd(edd.fieldsChange).then((res) => {
              if ('data' in res && res.data && res.data?.state === 1) {
                dispatch(commonSlice.actions.handleToast(handleToastMutation(res)));
                dispatch(trackingPageSlice.actions.handleChangeEDDFieldsChange({}));
                dispatch(trackingPageSlice.actions.handleChangeEDDOldValue(edd.value));
                if (apiError) {
                  setApiError('');
                }
                return;
              }
              setApiError(getErrorFromAPI(res));
            });
          }
        },
        primary: true,
        loading: isLoading || isLoadingUpdateTranslation || isLoadingUpdateBlacklisting || isLoadingUpdateEdd,
      },
    ];
  }, [
    trackingPage.validation.length,
    trackingPage.oldValue,
    trackingPage.fieldsChange,
    trackingPage.value,
    shippingInfo.length,
    progressBar.length,
    blacklisting.value,
    blacklisting.oldValue,
    blacklisting.fieldsChange,
    isLockedFeature,
    isLoading,
    isLoadingUpdateTranslation,
    isLoadingUpdateBlacklisting,
    isLoadingUpdateEdd,
    dispatch,
    language.oldValue.value,
    language.oldValue.languageConfig,
    language.fieldsChange,
    language.value,
    language.languageConfig,
    edd.oldValue,
    edd.fieldsChange,
    edd.value,
    isChangeTrackingPage,
    isChangeLanguage,
    isChangeBlacklisting,
    isChangeEDD,
    updateTrackingPage,
    accountState,
    apiError,
    updateTranslation,
    updateBlacklisting,
    updateEdd,
  ]);

  return (
    <Layout {...props}>
      <ControlBar
        isFixed={isFixed}
        actionGroup={actionGroup}
        hidden={isHiddenControlBar}
        // changeFields={
        //   !isUndefinedArray(Object.values(trackingPage.fieldsChange)) || Object.entries(language.fieldsChange).length > 0
        // }
      />
      {apiError ? (
        <div className="mb-16">
          <ErrorBanner isVisible>{apiError}</ErrorBanner>
        </div>
      ) : null}
      {children}
    </Layout>
  );
};

export default TrackingPageLayout;
