import { getLastSomeMonths, getSomeDaysAgo, getThisMonth } from '@/helpers';
import { Box, Button, ButtonProps, DatePicker, Icon, OptionList, Popover, TextField, useBreakpoints } from '@shopify/polaris';
import { ArrowRightIcon, CalendarIcon } from '@shopify/polaris-icons';
import { memo, useCallback, useState } from 'react';
import dayjs from 'dayjs';
import { StyledDatePicker } from './styled';

interface Props {
  onSave?: Function;
  start: Date;
  end: Date;
  titleBtn: string;
  setTitleBtn: Function;
  disabled?: boolean;
  variant?: ButtonProps['variant'];
}

const ranges = [
  {
    title: 'Custom',
    alias: 'Custom',
    period: {
      end: new Date(),
      start: new Date(),
    },
  },
  {
    title: 'Today',
    alias: 'today',
    period: {
      end: new Date(),
      start: new Date(),
    },
  },
  {
    title: 'Yesterday',
    alias: 'yesterday',
    period: {
      end: getSomeDaysAgo(2),
      start: getSomeDaysAgo(2),
    },
  },
  {
    title: 'Last 7 days',
    alias: 'last7days',
    period: {
      end: new Date(),
      start: getSomeDaysAgo(7),
    },
  },
  {
    title: 'Last 30 days',
    alias: 'last30days',
    period: {
      end: new Date(),
      start: getSomeDaysAgo(30),
    },
  },
  {
    title: 'This month',
    alias: 'thismonth',
    period: {
      end: getThisMonth().end,
      start: getThisMonth().start,
    },
  },
  {
    title: 'Last month',
    alias: 'lastmonth',
    period: {
      end: getLastSomeMonths(1).end,
      start: getLastSomeMonths(1).start,
    },
  },
  {
    title: 'Last 2 months',
    alias: 'last2months',
    period: {
      end: getLastSomeMonths(2).end,
      start: getLastSomeMonths(2).start,
    },
  },
];
// This example is for guidance purposes. Copying it will come with caveats.
function CustomDatePicker({ onSave, start, end, titleBtn, setTitleBtn, disabled = false, variant }: Props) {
  const now = new Date();
  const { smDown } = useBreakpoints();
  const [selected, setSelected] = useState(ranges[4]);
  const [popoverActive, setPopoverActive] = useState(false);
  const [selectedDates, setSelectedDates] = useState({ start, end });
  const [{ month, year }, setDate] = useState({ month: now.getMonth(), year: now.getFullYear() });
  const handleMonthChange = useCallback((month: number, year: number) => setDate({ month, year }), []);
  const handlePopoverActive = () => {
    setPopoverActive(!popoverActive);
  };
  const handleCancel = () => {
    setPopoverActive(false);
    setSelectedDates({ start, end });
  };
  const handleSave = () => {
    setPopoverActive(false);
    onSave && onSave(selectedDates.start, selectedDates.end);
    setTitleBtn(
      selected.title === 'Custom'
        ? `${dayjs(selectedDates.start).format('DD/MM/YYYY')} - ${dayjs(selectedDates.end).format('DD/MM/YYYY')}`
        : selected.title,
    );
  };
  return (
    <Popover
      active={popoverActive}
      autofocusTarget="none"
      preferredAlignment="left"
      preferredPosition="below"
      fluidContent
      sectioned={false}
      fullHeight
      activator={
        <Button icon={CalendarIcon} onClick={handlePopoverActive} disabled={disabled} variant={variant}>
          {titleBtn}
        </Button>
      }
      onClose={() => {
        setSelectedDates({ start, end });
        setPopoverActive(false);
      }}
    >
      <StyledDatePicker>
        <OptionList
          options={ranges.map((range) => ({
            value: range.alias,
            label: range.title,
          }))}
          selected={[selected.alias]}
          onChange={(value) => {
            const range = ranges.find((range) => range.alias === value[0]) || ranges[0];
            setSelected(range);
            setSelectedDates({ ...range.period });
            handleMonthChange(range.period.start.getMonth(), range.period.start.getFullYear());
          }}
        />
        <Box padding={{ xs: '400' }} maxWidth={smDown ? '100%' : '32.5rem'}>
          <div className="from-to-date">
            <div>
              <TextField
                role="combobox"
                label={'Since'}
                labelHidden
                prefix={<Icon source={CalendarIcon} />}
                value={dayjs(selectedDates.start).format('DD/MM/YYYY')}
                readOnly
                autoComplete="off"
              />
            </div>
            <div className="arrow-right-icon">
              <Icon source={ArrowRightIcon} />
            </div>
            <div>
              <TextField
                role="combobox"
                label={'Until'}
                labelHidden
                prefix={<Icon source={CalendarIcon} />}
                value={dayjs(selectedDates.end).format('DD/MM/YYYY')}
                autoComplete="off"
                readOnly
              />
            </div>
          </div>
          <DatePicker
            month={month}
            year={year}
            selected={{
              start: selectedDates.start,
              end: selectedDates.end,
            }}
            onMonthChange={handleMonthChange}
            onChange={(value) => {
              const start = new Date(value.start.getTime() + Math.abs(value.start.getTimezoneOffset() * 60000));
              const end = new Date(value.end.getTime() + Math.abs(value.end.getTimezoneOffset() * 60000));
              setSelectedDates({ start, end });
              setSelected(ranges[0]);
            }}
            multiMonth
            allowRange
            disableDatesAfter={now}
            disableDatesBefore={getLastSomeMonths(2).start}
          />
          <div className="date-picker-btn-group">
            <Button onClick={handleCancel}>Cancel</Button>
            <div className="ml-8">
              <Button variant="primary" onClick={handleSave}>
                Apply
              </Button>
            </div>
          </div>
        </Box>
      </StyledDatePicker>
    </Popover>
  );
}
export default memo(CustomDatePicker);
