import { Card, Button, SkeletonBodyText, TextField, Badge } from '@shopify/polaris';
import { StyledTemplateHeading } from './styled';
import Switch from '@/components/Switch';
import { useDispatch, useSelector } from 'react-redux';
import emailSlice, { emailTemplatesSelector, emailTemplatesTabSelector, orderStatusSelector } from '@/redux/features/email.slice';
import { useEffect, useState } from 'react';
import { useGetTemplateDetailQuery, useUpdateTemplateMutation } from '@/redux/api/api.caller';
import commonSlice from '@/redux/features/common.slice';
import { getErrorFromAPI, handleToastMutation } from '@/helpers/toast';
import { Receiver } from '@/constants/enum';

const TemplateHeading = () => {
  const dispatch = useDispatch();
  const [apiError, setApiError] = useState<string>('');
  const templateState = useSelector(emailTemplatesSelector);
  const orderStatusState = useSelector(orderStatusSelector);
  const templatesTab = useSelector(emailTemplatesTabSelector);

  const isCheckedSwitch = templateState[templatesTab].status.some((status) => status === orderStatusState);
  const { data, isLoading } = useGetTemplateDetailQuery({ trackingStatus: orderStatusState, receiver: templatesTab });
  const [updateTemplate, updatedTemplateData] = useUpdateTemplateMutation();

  const handleChange = (value: string | boolean, key: string) => {
    setApiError('');
    const updatedValue = value
      ? [...templateState[templatesTab].status, orderStatusState]
      : templateState[templatesTab].status.filter((status) => status !== orderStatusState);
    dispatch(
      emailSlice.actions.handleChangeEmailTemplates({
        ...templateState,
        [templatesTab]: { ...templateState[templatesTab], [key]: typeof value === 'boolean' ? updatedValue : value },
      }),
    );
  };

  const handleSave = () => {
    const isStatusActive = templateState[templatesTab].status.some((status) => status === orderStatusState);
    updateTemplate({
      status: orderStatusState,
      isActive: isStatusActive,
      subject: templateState[templatesTab].subject,
      receiver: templatesTab,
    }).then((res) => {
      if ('data' in res && res.data && res.data?.state === 1) {
        dispatch(emailSlice.actions.handleChangeEmailTemplates({ ...templateState }));
        dispatch(commonSlice.actions.handleToast(handleToastMutation(res)));
        if (apiError) {
          setApiError('');
        }
        return;
      }
      setApiError(getErrorFromAPI(res));
    });
  };

  useEffect(() => {
    if (data) {
      const updatedStatus = data.data.isActive
        ? [...templateState[templatesTab].status, data.data.trackingStatus]
        : templateState[templatesTab].status.filter((status) => status !== data.data.trackingStatus);
      dispatch(
        emailSlice.actions.handleChangeEmailTemplates({
          ...templateState,
          [templatesTab]: { ...templateState[templatesTab], status: updatedStatus, subject: data.data.subject },
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <StyledTemplateHeading>
      <Card>
        {isLoading ? (
          <div style={{ height: 152 }}>
            <SkeletonBodyText lines={8} />
          </div>
        ) : (
          <>
            <div className="status">
              <div className="title">To</div>
              <div className="content">
                <Badge tone="info">{templatesTab === Receiver.Customer ? 'Customer' : 'Merchant'}</Badge>
              </div>
            </div>
            <div className="status">
              <div className="title">Status</div>
              <div className="content">
                <Switch isActive={isCheckedSwitch} onClick={() => handleChange(!isCheckedSwitch, 'status')} />
              </div>
            </div>
            <div className="subject">
              <div className="title">Subject</div>
              <div className="content">
                <TextField
                  id="subject"
                  label=""
                  value={templateState[templatesTab].subject}
                  onChange={handleChange}
                  autoComplete="off"
                  maxLength={255}
                  showCharacterCount
                  error={apiError}
                />
              </div>
            </div>
            <div className="save-btn">
              <Button variant="primary" onClick={handleSave} loading={updatedTemplateData.isLoading}>
                Save
              </Button>
            </div>
          </>
        )}
      </Card>
    </StyledTemplateHeading>
  );
};

export default TemplateHeading;
