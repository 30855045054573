import useHandleContextureBar from '@/hooks/useHandleContextureBar';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, To, NavigateOptions } from 'react-router-dom';
import { config } from '@/config';
import contextualBarSlice, { isShowContextualBarSelector } from '@/redux/features/contextualBar.slice';

interface IProps {
  forceNavigate?: boolean;
}

export default function useConfirmationNavigate({ forceNavigate }: IProps | undefined = {}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isShowContextualBar = useSelector(isShowContextualBarSelector);
  const contextualBar = useHandleContextureBar();
  return (to: To, options?: NavigateOptions) => {
    if (isShowContextualBar && !forceNavigate) {
      if (config.embedded === '1') contextualBar.leaveConfirmation();
      dispatch(
        contextualBarSlice.actions.handleConfirmNavigateState({
          isShowConfirmNavigate: true,
          to,
          options,
        }),
      );
    } else {
      navigate(to, options);
      contextualBar.hide();
    }
  };
}
