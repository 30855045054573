import { HTMLInputTypeAttribute, useRef } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const Label = styled.label`
  font-size: 0.8125rem;
`;

const StyledInput = styled.input`
  width: 100%;
  padding: 12px;
  border: 1px solid #e2e8f0;
  border-radius: 12px;

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.5);
  }
`;

interface IProps {
  errorMessage?: string;
  label?: string;
  placeholder?: string;
  name?: string;
  id?: string;
  type?: HTMLInputTypeAttribute;
}

const Input = ({ errorMessage, id, type, label, name, placeholder }: IProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  return (
    <Container>
      <Label htmlFor={id || name}>{label}</Label>
      <StyledInput ref={inputRef} id={id || name} name={name} type={type || 'text'} placeholder={placeholder} />
    </Container>
  );
};

export default Input;
