import styled from 'styled-components';

export const TrackingFormStyled = styled.div`
  padding: 1rem;
  border: 1px solid #ccc;
  border-radius: 12px;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
  font-size: 0.8125rem;
  .tab-container {
    display: flex;
    .tab-item {
      width: 50%;
      text-align: center;
      padding: 0 1rem 1rem;
      font-size: 0.8125rem;
      cursor: pointer;
      &.active {
        border-bottom: 2px solid #ffadad;
      }
    }
  }
  .submit-btn {
    border: none;
    outline: none;
    background-color: #000;
    color: #fff;
    font-size: 0.9rem;
    font-weight: 500;
    padding: 0.75rem 0;
    border-radius: 12px;
    cursor: pointer;
  }
`;
