export enum AccountPlan {
  Starter = 'starter',
  Basic = 'basic',
  Professional = 'professional',
  Enterprise = 'enterprise',
}

export enum ShippingInfo {
  ProgressBar = 'progress_bar',
  OrderDetail = 'order_detail',
  TrackingLogs = 'tracking_logs',
  Map = 'map',
  TrackingCompany = 'tracking_company',
}

export enum ProgressBar {
  Ordered = 'Ordered',
  OrderReady = 'Order Ready',
  InTransit = 'In transit',
  OutForDelivery = 'Out for delivery',
  Delivered = 'Delivered',
}

export enum SortDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum DisplayComment {
  Checked = '1',
  UnChecked = '0',
}

export enum Subscription {
  Monthly = 'monthly',
  Annually = 'annually',
}

export enum CRUD {
  Create = 'create',
  Read = 'read',
  Update = 'update',
  Delete = 'delete',
}

export enum Language {
  Default = '',
  English = 'english',
  Chinese = 'chinese',
  French = 'french',
  Dutch = 'dutch',
  Germany = 'germany',
  Japanese = 'japanese',
  Hebrew = 'hebrew',
  Portuguese = 'portuguese',
  Spanish = 'spanish',
  Arabic = 'arabic',
}

export enum PageSize {
  Five = 5,
  TwentyFive = 25,
  Fifty = 50,
  SeventyFive = 75,
  OneHundred = 100,
}

export enum TrackingPageTheme {
  Basic = 0,
  Advanced = 1,
}

export enum TrackingMethods {
  Both = 'both',
  OrderId = 'order_name',
  Email = 'email',
  PhoneNumber = 'phone_number',
  TrackingNumber = 'tracking_number',
}

export enum GoogleTranslatePosition {
  LeftTop = 'leftTop',
  LeftBottom = 'leftBottom',
  RightTop = 'rightTop',
  RightBottom = 'rightBottom ',
}

export enum ProductRecommendType {
  AllProduct = 'all',
  productType = 'type',
  Tags = 'tags',
}

export enum DisplayReview {
  StarRating = 'star_rating',
  Comment = 'comment',
  CustomerName = 'customer_name',
  ReviewTime = 'review_time',
  Courier = 'courier',
}

export enum WidgetTrackingForm {
  TrackingNumber = 'trackingNumber',
  OrderId = 'orderId',
  Both = 'both',
}
export enum WidgetSize {
  Medium = '16px',
  Large = '18px',
  Small = '12px',
}

export enum Alignment {
  Left = 'flex-start',
  Right = 'flex-end',
  Center = 'center',
}

export enum Receiver {
  Customer = 'customer',
  Merchant = 'merchant',
}

export enum PolarisBreakPoint {
  XS = '0rem',
  SM = '30.625rem', // 490px+
  MD = '48rem', // 768px+
  LG = '65rem', // 1040px+
  XL = '90rem', // 1440px+
}

export enum Device {
  Desktop = 'desktop',
  Tablet = 'tablet',
  Mobile = 'mobile',
}
