import { Button, Modal } from '@shopify/polaris';
import { ControlBarStyled } from './styled';
import { memo, useEffect, useMemo, useState } from 'react';
import useEventListener from '@/hooks/useEventListener';
import { config } from '@/config';
import { SaveBar } from '@shopify/app-bridge-react';
import { OTElementId } from '@/constants';
import useHandleContextureBar from '@/hooks/useHandleContextureBar';
import { useDispatch, useSelector } from 'react-redux';
import contextualBarSlice, { confirmNavigateSelector } from '@/redux/features/contextualBar.slice';
import { useNavigate } from 'react-router-dom';

interface Props {
  hidden: boolean;
  actionGroup: Array<{
    content: string;
    onAction: () => void;
    primary?: boolean;
    disabled?: boolean;
    loading?: boolean;
  }>;
  changeFields?: any;
  isFixed?: boolean;
}

const ControlBar = ({ actionGroup, hidden, changeFields, isFixed }: Props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const contextualBar = useHandleContextureBar();
  const confirmNavigateState = useSelector(confirmNavigateSelector);
  let ctrlOrCommandPressed = false;
  const [isShowModalDiscard, setIsShowModalDiscard] = useState(false);

  const alertUser = (e: any) => {
    e.preventDefault();
    e.returnValue = 'Changes you made may not be saved.';
  };

  const handKeyDownSearchOnPage = (e: KeyboardEvent) => {
    if (e.ctrlKey || e.key === 'Meta') {
      ctrlOrCommandPressed = true;
    }
    if (ctrlOrCommandPressed && e.key === 's') {
      e.preventDefault();
      ctrlOrCommandPressed = false;
      const saveAction: any = actionGroup.find((item) => item.content === 'Save' || item.primary);
      return saveAction.onAction();
    }
  };

  useEventListener('keydown', handKeyDownSearchOnPage);

  useEffect(() => {
    if (changeFields) {
      window.addEventListener('beforeunload', alertUser);
    } else {
      window.removeEventListener('beforeunload', alertUser);
    }

    return () => {
      window.removeEventListener('beforeunload', alertUser);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changeFields]);

  useEffect(() => {
    setTimeout(() => {
      if (!hidden) {
        dispatch(contextualBarSlice.actions.handleUpdateContextualBarState({ isShowContextualBar: true }));
        contextualBar.show();
      } else {
        dispatch(contextualBarSlice.actions.handleUpdateContextualBarState({ isShowContextualBar: false }));
        contextualBar.hide();
      }
    }, 200);
  }, [contextualBar, dispatch, hidden]);

  const contextualBarElement = useMemo(() => {
    return config.embedded !== '1' ? (
      !hidden ? (
        <ControlBarStyled isFixed={isFixed}>
          {actionGroup.map((item, index) => {
            return (
              <Button onClick={item.primary ? item.onAction : () => setIsShowModalDiscard(true)} {...item} key={index}>
                {item.content}
              </Button>
            );
          })}
        </ControlBarStyled>
      ) : null
    ) : (
      <SaveBar id={OTElementId.SaveBar}>
        {actionGroup.map((item, index) => (
          <button
            key={index}
            onClick={item.primary ? item.onAction : () => setIsShowModalDiscard(true)}
            variant={item.primary ? 'primary' : undefined}
            disabled={item.disabled}
            loading={item.loading}
          >
            {item.content}
          </button>
        ))}
      </SaveBar>
    );
  }, [actionGroup, hidden, isFixed]);

  return (
    <>
      {contextualBarElement}
      <Modal
        title="Discard all unsaved changes"
        open={isShowModalDiscard}
        onClose={() => setIsShowModalDiscard(false)}
        primaryAction={{
          content: 'Discard changes',
          onAction: () => {
            actionGroup.find((item) => !item.primary)?.onAction();
            setIsShowModalDiscard(false);
          },
          // @ts-ignore
          tone: 'critical',
        }}
        secondaryActions={[{ content: 'Continue editing', onAction: () => setIsShowModalDiscard(false) }]}
      >
        <Modal.Section>If you discard changes, you’ll delete any edits you made since you last saved.</Modal.Section>
      </Modal>
      <Modal
        title="You have unsaved changes"
        open={confirmNavigateState.isShowConfirmNavigate}
        onClose={() => {
          dispatch(contextualBarSlice.actions.handleConfirmNavigateState({ isShowConfirmNavigate: false }));
        }}
        primaryAction={{
          content: 'Leave page',
          onAction: () => {
            if (!confirmNavigateState.to) return;
            navigate(confirmNavigateState.to, confirmNavigateState.options);
            actionGroup.find((item) => !item.primary)?.onAction();
            dispatch(contextualBarSlice.actions.handleUpdateContextualBarState({ isShowContextualBar: false }));
            dispatch(contextualBarSlice.actions.handleConfirmNavigateState({ isShowConfirmNavigate: false }));
          },
          // @ts-ignore
          tone: 'critical',
        }}
        secondaryActions={[
          {
            content: 'Cancel',
            onAction: () => {
              dispatch(contextualBarSlice.actions.handleConfirmNavigateState({ isShowConfirmNavigate: false }));
            },
          },
        ]}
      >
        <Modal.Section>If you leave this page, all unsaved changes will be lost.</Modal.Section>
      </Modal>
    </>
  );
};

export default memo(ControlBar);
