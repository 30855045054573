import { CustomCard, ErrorBanner, UpgradeBanner } from '@/components';
import CustomLayoutOneThird from '@/components/CustomLayoutOneThird';
import TrackingPageLayout from '@/components/Layout/TrackingPageLayout';
import { AreaToReplaceWithType, BlacklistReplaceWith, PATH } from '@/constants';
import { AccountPlan } from '@/constants/enum';
import { checkDirtyField } from '@/helpers';
import { isLockFeature } from '@/helpers/feature';
import useConfirmationNavigate from '@/hooks/useConfirmationNavigate';
import { accountSelector, christmasBannerSelector } from '@/redux/features/plan.slice';
import trackingPageSlice, { blacklistingSelector } from '@/redux/features/trackingPage.slice';
import { Button, InlineGrid, RadioButton, SkeletonBodyText, Text } from '@shopify/polaris';
import { ArrowLeftIcon } from '@shopify/polaris-icons';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AreasToBeReplacedTextField from './components/AreasToBeReplacedTextField';
import AreaToReplaceWithTextField from './components/AreaToReplaceWithTextField';
import SearchAndReplaceWords from './components/SearchAndReplaceWords';
import { StyledBlacklisting } from './styled';

const Blacklisting = () => {
  const navigate = useConfirmationNavigate();
  const dispatch = useDispatch();
  const [apiError, setApiError] = useState<string>('');
  const { value: blacklisting, oldValue, fieldsChange, isLoading } = useSelector(blacklistingSelector);
  const plan = useSelector(accountSelector);
  const { formattedEndDate } = useSelector(christmasBannerSelector);
  const isActiveChristmasFeature = new Date() < new Date(formattedEndDate);
  const isLockedFeature =
    isLockFeature([AccountPlan.Starter, AccountPlan.Basic]) && !plan.isOldPaidUser && !isActiveChristmasFeature;

  const handleChangeReplaceWith = (type: string) => {
    setApiError('');
    dispatch(
      trackingPageSlice.actions.handleChangeBlacklistingValue({ ...blacklisting, replaceWith: type as BlacklistReplaceWith }),
    );
    const { dirtyField } = checkDirtyField({ replaceWith: oldValue.replaceWith }, { replaceWith: type as BlacklistReplaceWith });
    dispatch(
      trackingPageSlice.actions.handleChangeBlacklistingFieldsChange({
        ...fieldsChange,
        replaceWith: dirtyField?.replaceWith ? dirtyField?.replaceWith : undefined,
      }),
    );
  };

  return (
    <TrackingPageLayout
      title={
        (
          <div className="d-flex align-center justify-between">
            <div className="d-flex align-center">
              <Button variant="plain" onClick={() => navigate(PATH.TRACKING_PAGE)} icon={ArrowLeftIcon} />
              <span className="ml-4 d-inline-block">Blacklisting</span>
            </div>
          </div>
        ) as any
      }
    >
      <StyledBlacklisting>
        <UpgradeBanner isOpen={!!isLockedFeature} usedFeature={AccountPlan.Professional} />
        <CustomLayoutOneThird notUseCard title="Hide or replace location">
          <CustomCard title="" noHeaderPadding noHeaderBorder>
            {isLoading ? (
              <SkeletonBodyText lines={10} />
            ) : (
              <>
                <div className="top-section">
                  {apiError && (
                    <div className="mb-8">
                      <ErrorBanner isVisible>{apiError}</ErrorBanner>
                    </div>
                  )}
                  <AreasToBeReplacedTextField />

                  <div className="mb-4 mt-8">
                    <Text as="h6" variant="headingSm">
                      Action
                    </Text>
                  </div>
                  <InlineGrid
                    columns={{ xs: 1, sm: 2, md: 2, lg: 2, xl: 2 }}
                    gap={{ xs: '100', sm: '400', md: '800', lg: '1200', xl: '1600' }}
                  >
                    <RadioButton
                      id={BlacklistReplaceWith.EmptyLocation}
                      label={
                        <Text as="p" variant="bodyMd">
                          Hide selected countries
                        </Text>
                      }
                      name="replacementOption"
                      checked={blacklisting.replaceWith === BlacklistReplaceWith.EmptyLocation}
                      onChange={(_, id) => handleChangeReplaceWith(id)}
                    />
                    <RadioButton
                      id={BlacklistReplaceWith.AnotherCity}
                      label={
                        <Text as="p" variant="bodyMd">
                          Replace with
                        </Text>
                      }
                      name="replacementOption"
                      checked={blacklisting.replaceWith === BlacklistReplaceWith.AnotherCity}
                      onChange={(_, id) => handleChangeReplaceWith(id)}
                    />
                  </InlineGrid>

                  {blacklisting.replaceWith === BlacklistReplaceWith.AnotherCity && (
                    <div className="another-city">
                      <div className="group-textfield">
                        <div className="textfield-item">
                          <AreaToReplaceWithTextField type={AreaToReplaceWithType.Country} />
                        </div>
                        <div className="textfield-item">
                          <AreaToReplaceWithTextField type={AreaToReplaceWithType.City} />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </>
            )}
          </CustomCard>
        </CustomLayoutOneThird>

        <SearchAndReplaceWords />
      </StyledBlacklisting>
    </TrackingPageLayout>
  );
};

export default Blacklisting;
