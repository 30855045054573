import OneThirdCardLayout from '@/components/CustomCard/OneThirdCardLayout';
import { Environment, images, LINK } from '@/constants';
import { CopyIcon } from '@/icons';
import ModalTrackingPage from '@/pages/TrackingPage/components/EDD/components/ModalTrackingPage';
import { tokenSelector } from '@/redux/features/auth.slice';
import trackingPageSlice from '@/redux/features/trackingPage.slice';
import { Button, ButtonGroup, Link, Text, TextField } from '@shopify/polaris';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const TrackingPageEditor = () => {
  const dispatch = useDispatch();
  const { shop } = useSelector(tokenSelector);

  const storefrontLink = useMemo(() => {
    return `https://${shop}/apps/${
      process.env.REACT_APP_ENV === Environment.Production ? 'order-tracking' : 'tracking-order-now-dev'
    }`;
  }, [shop]);

  return (
    <>
      <OneThirdCardLayout
        padding="0"
        title="Tracking page editor"
        subTitle="Customize tracking information, style, and more."
        contentRight={
          <div className="w-full text-right">
            <img src={images.trackingPageEditor} alt="tracking page editor" className="image-content-right" />
          </div>
        }
      >
        <div className="d-flex gap-16 flex-column">
          <ButtonGroup>
            <Button
              variant="primary"
              onClick={() => {
                dispatch(trackingPageSlice.actions.handleIsOpenModalTrackingPage(true));
              }}
            >
              Customize
            </Button>
            <Button url={storefrontLink}>View</Button>
          </ButtonGroup>
          <div>
            <div className="mb-4">
              <Text variant="bodyMd" as="h6" tone="subdued">
                URL
              </Text>
            </div>
            <TextField
              autoComplete="off"
              label="URL"
              labelHidden
              value={storefrontLink}
              readOnly
              suffix={<CopyIcon copiedText={storefrontLink} />}
            />
          </div>
          <Text variant="bodyMd" as="p">
            Go to{' '}
            <Link monochrome url={LINK.SHOPIFY_MENU(shop)}>
              Store Navigation
            </Link>
            , choose a menu and add this link as a menu item.
          </Text>
        </div>
      </OneThirdCardLayout>
      <ModalTrackingPage />
    </>
  );
};

export default TrackingPageEditor;
