import { BgColorPalette } from '@/constants';
import styled from 'styled-components';

export const StyledShippingMethod = styled.div`
  .info-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    margin-bottom: 0.5rem;

    .Polaris-InlineGrid {
      width: 100%;
    }
  }

  .info-detail {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    border-top: 1px solid ${BgColorPalette.SECONDARY_ACTIVE};

    &:not(:first-child) {
      padding-top: 0.25rem;
    }
    &:not(:last-child) {
      padding-bottom: 0.25rem;
    }

    .group-btn {
      display: flex;
      gap: 0.5rem;
    }
  }

  .no-data-text {
    margin-top: 0.5rem;
    color: #6d7175;
  }
`;

export const StyledShippingMethodModal = styled.div`
  .Polaris-Tag {
    margin-block: 0.25rem;
    margin-right: 0.25rem;
  }

  .text-description {
    color: #6d7175;
    margin-block: 0.25rem;
  }

  .transit-time {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  .collapsible-content {
    display: flex;
    flex-direction: column;
    padding-inline: 2rem;
    height: 12.5rem;
    overflow-y: auto;
    border: 1px solid ${BgColorPalette.SECONDARY_ACTIVE};
    border-radius: 12px;

    &::-webkit-scrollbar {
      width: 8px;
    }

    /* Track (the area behind the thumb) */
    &::-webkit-scrollbar-track {
      border-radius: 16px !important;
    }

    /* Handle (the draggable scrolling element) */
    &::-webkit-scrollbar-thumb {
      border-radius: 16px !important;
      background-color: ${BgColorPalette.SECONDARY_HOVER};
    }

    &:hover::-webkit-scrollbar-thumb {
      background: ${BgColorPalette.PRIMARY};
    }

    .Polaris-Choice {
      padding-block: 0.5rem;
    }

    .no-data {
      margin: auto;
    }
  }

  .transit-time-err {
    margin-top: 0.5rem;
    color: rgba(215, 44, 13, 1);
  }
`;
