import { Button, ButtonGroup, Divider, Icon, Modal, Text, useBreakpoints } from '@shopify/polaris';
import { StyledSwitchToNewVersion } from './styled';
import { XIcon } from '@shopify/polaris-icons';
import { newVersionDetail } from './config';
import { memo } from 'react';
import { ErrorBanner, ImagePreview } from '@/components';
import { useDispatch, useSelector } from 'react-redux';
import emailSlice, { openNewVersionModalSelector } from '@/redux/features/email.slice';

interface SwitchToNewVersionProps {
  isOpen: boolean;
  onKeepCurrentVersion: () => void;
  onSwitchNewVersion: () => void;
  isLoading?: boolean;
  error?: string;
}

const SwitchToNewVersion = ({ isOpen, onSwitchNewVersion, onKeepCurrentVersion, isLoading, error }: SwitchToNewVersionProps) => {
  const { smDown } = useBreakpoints();
  const dispatch = useDispatch();
  const isOpenModal = useSelector(openNewVersionModalSelector);

  const handleOpenModal = () => dispatch(emailSlice.actions.handleOpenNewVersionModal(true));
  const handleCloseModal = () => dispatch(emailSlice.actions.handleOpenNewVersionModal(false));

  return (
    <StyledSwitchToNewVersion isOpen={isOpen}>
      <div className="popup-container">
        <div className="popup-heading">
          <div />
          <Text variant="headingLg" as="h6">
            New Email Version Preview
          </Text>
          <div className="cancel-icon" onClick={onKeepCurrentVersion}>
            <Icon source={XIcon} tone="base" />
          </div>
        </div>

        <Divider />

        <div className="popup-content">
          <p className="content-title">
            Please review the images of the new version of the email notification feature, which now is released. You can decide
            whether to keep the current version or switch to the new one by using the two buttons below which also close this
            popup.
          </p>
          <div className="content-image">
            <ImagePreview
              imageSrc={`${process.env.REACT_APP_S3_ASSETS_BASE_URL}/email/email_notification.png`}
              imageWidth={smDown ? '100%' : '49%'}
            />
            <ImagePreview
              imageSrc={`${process.env.REACT_APP_S3_ASSETS_BASE_URL}/email/email_template.png`}
              imageWidth={smDown ? '100%' : '49%'}
            />
          </div>
          <div className="content-description">
            <Text variant="headingMd" as="h6">
              Why switch to new version
            </Text>
            <div className="version-highlight">
              <div className="highlight-list">
                {newVersionDetail.slice(0, newVersionDetail.length / 2).map(({ title, description }) => (
                  <div className="highlight-item" key={title}>
                    <Text variant="headingSm" as="h6">
                      {title}
                    </Text>
                    <div dangerouslySetInnerHTML={{ __html: description }} />
                  </div>
                ))}
              </div>
              <div className="divider" />
              <div className="highlight-list">
                {newVersionDetail.slice(newVersionDetail.length / 2).map(({ title, description }) => (
                  <div className="highlight-item" key={title}>
                    <Text variant="headingSm" as="h6">
                      {title}
                    </Text>
                    <div dangerouslySetInnerHTML={{ __html: description }} />
                  </div>
                ))}
              </div>
            </div>
            <Text variant="headingMd" as="h6">
              Attention
            </Text>
            <p className="attention-description">
              With the transition to the new version, you may need to reconnect your email account to enjoy uninterrupted access
              to our powerful features. Rest assured, this step is designed to ensure a seamless experience.
            </p>
          </div>
          <ButtonGroup>
            <Button onClick={onKeepCurrentVersion}>Keep the current version</Button>
            <Button variant="primary" onClick={handleOpenModal}>
              Switch to the New Version
            </Button>
          </ButtonGroup>
        </div>
      </div>

      <Modal
        open={isOpenModal}
        onClose={handleCloseModal}
        title="Are you sure you want to switch to new version?"
        primaryAction={{
          content: 'Yes',
          onAction: onSwitchNewVersion,
          loading: isLoading,
        }}
        secondaryActions={[
          {
            content: 'No',
            onAction: handleCloseModal,
          },
        ]}
      >
        <Modal.Section>
          <ErrorBanner isVisible={!!error}>{error}</ErrorBanner>
          Please note that once you switch to a new version, you cannot go back to the old version and you need to reconnect your
          email account to enjoy uninterrupted access to our powerful features
        </Modal.Section>
      </Modal>
    </StyledSwitchToNewVersion>
  );
};

export default memo(SwitchToNewVersion);
