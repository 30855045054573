import { CustomTable, ErrorBanner } from '@/components';
import Switch from '@/components/Switch';
import { PATH, TrackingStatus } from '@/constants';
import { Receiver } from '@/constants/enum';
import { getErrorFromAPI, handleToastMutation } from '@/helpers/toast';
import useConfirmationNavigate from '@/hooks/useConfirmationNavigate';
import { useGetNotificationAccountsQuery, useUpdateTemplateMutation } from '@/redux/api/api.caller';
import commonSlice from '@/redux/features/common.slice';
import emailSlice, { emailTemplatesSelector, emailTemplatesTabSelector } from '@/redux/features/email.slice';
import { Button, Card, IndexTable, Tabs, Tooltip } from '@shopify/polaris';
import { EditIcon } from '@shopify/polaris-icons';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { tabsConfig } from './configs';
import { StyledEmailTemplates } from './styled';

const EmailTemplates = () => {
  const navigate = useConfirmationNavigate();
  const dispatch = useDispatch();
  const [apiError, setApiError] = useState<string>('');
  const templatesState = useSelector(emailTemplatesSelector);
  const selectedTab = useSelector(emailTemplatesTabSelector);
  const tabIndex = Object.values(Receiver).findIndex((tab) => tab === selectedTab);
  const [updateTemplate, updatedTemplateData] = useUpdateTemplateMutation();
  const { data, isLoading } = useGetNotificationAccountsQuery();
  const templatesData =
    selectedTab === Receiver.Customer ? data?.data?.email?.customerTemplates || [] : data?.data?.email?.merchantTemplates || [];

  const handleChangeSwitch = (newValue: boolean, key: TrackingStatus) => {
    dispatch(
      emailSlice.actions.handleChangeEmailTemplates({
        ...templatesState,
        [selectedTab]: { ...templatesState[selectedTab], selectedStatus: key },
      }),
    );
    updateTemplate({ status: key, isActive: newValue, receiver: selectedTab }).then((res) => {
      if ('data' in res && res.data && res.data?.state === 1) {
        if (apiError) {
          setApiError('');
        }
        dispatch(commonSlice.actions.handleToast(handleToastMutation(res)));
        return;
      }
      setApiError(getErrorFromAPI(res));
    });
  };

  const navigateToEmailTemplateDetail = (status: TrackingStatus) => {
    dispatch(emailSlice.actions.handleChangeOrderStatus(status));
    navigate(`${PATH.NOTIFICATIONS_EMAIL}/${status}`);
    window.scrollTo(0, 0);
  };

  const emailTemplate = Object.entries(TrackingStatus)
    .filter(([_, value]) => value !== TrackingStatus.All)
    .map(([key, value]) => ({
      id: value,
      orderStatus: key,
      subject: templatesData.find(({ status }) => status === value)?.subject || '',
      status: () => {
        const currentStatus = templatesState[selectedTab].status.some((status) => status === value);
        return (
          <div className="center">
            <Switch
              isActive={currentStatus}
              onClick={() => handleChangeSwitch(!currentStatus, value)}
              loading={templatesState[selectedTab].selectedStatus === value && updatedTemplateData.isLoading}
              disable={updatedTemplateData.isLoading}
            />
          </div>
        );
      },
      action: (
        <div className="center">
          <Tooltip content="Edit email templates">
            <Button onClick={() => navigateToEmailTemplateDetail(value)} icon={EditIcon} />
          </Tooltip>
        </div>
      ),
    }));

  const tableData = emailTemplate.map(({ id, orderStatus, subject, status, action }, index) => (
    <IndexTable.Row id={String(id || 0)} key={id} position={index}>
      <IndexTable.Cell>{orderStatus}</IndexTable.Cell>
      <IndexTable.Cell>{subject}</IndexTable.Cell>
      <IndexTable.Cell>{status()}</IndexTable.Cell>
      <IndexTable.Cell>{action}</IndexTable.Cell>
    </IndexTable.Row>
  ));

  const handleChangeTab = (tab: number) => {
    setApiError('');
    dispatch(emailSlice.actions.handleChangeEmailTemplatesTab(Object.values(Receiver)[tab]));
  };

  useEffect(() => {
    if (data && data.data && data.data.email) {
      const {
        data: { email },
      } = data;
      dispatch(
        emailSlice.actions.handleChangeEmailTemplates({
          ...templatesState,
          customer: {
            ...templatesState.customer,
            status: email.customerTemplates.filter(({ isActive }) => isActive).map(({ status }) => status),
          },
          merchant: {
            ...templatesState.merchant,
            status: email.merchantTemplates.filter(({ isActive }) => isActive).map(({ status }) => status),
          },
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <StyledEmailTemplates>
      <Card>
        <Tabs tabs={tabsConfig} selected={tabIndex} onSelect={handleChangeTab} fitted>
          {apiError && (
            <div className="mb-8">
              <ErrorBanner isVisible>{apiError}</ErrorBanner>
            </div>
          )}
          <CustomTable
            tableProps={{
              itemCount: emailTemplate.length,
              headings: [
                { title: 'Order status' },
                { title: 'Subject' },
                { title: 'Status', alignment: 'center' },
                { title: 'Action', alignment: 'center' },
              ],
              selectable: false,
            }}
            skeletonLines={20}
            isLoading={isLoading}
          >
            {tableData}
          </CustomTable>
        </Tabs>
      </Card>
    </StyledEmailTemplates>
  );
};

export default EmailTemplates;
