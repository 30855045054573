import { Button, ButtonProps, Card, CardProps, Icon, IconSource, InlineGrid, Text, TextProps, Tooltip } from '@shopify/polaris';
import { Fragment, ReactElement, ReactNode, memo } from 'react';
import { CardStyled } from './styled';
import { InfoIcon } from '@shopify/polaris-icons';

interface Props {
  title: ReactNode;
  tooltip?: ReactNode;
  children: any;
  display?: string;
  actionGroup?: Array<{
    content: string | ReactNode;
    onAction?: () => void;
    variant?: ButtonProps['variant'];
    disabled?: boolean;
    loading?: boolean;
    icon?: ReactElement | IconSource;
    url?: string;
  }>;
  className?: string;
  id?: string;
  variantTitle?: TextProps['variant'];
  padding?: CardProps['padding'];
  contentRight: ReactNode;
  subTitle?: string;
}

const OneThirdCardLayout = ({ ...props }: Props) => {
  return (
    <CardStyled style={{ display: props.display || 'block' }} className={props.className} id={props.id}>
      <Card padding={props.padding || { xl: '0' }}>
        <InlineGrid columns={{ md: ['twoThirds', 'oneThird'] }}>
          <div>
            <div className="custom-card-header" style={{ borderBottom: 'none', paddingBottom: 0 }}>
              <Text variant={props.variantTitle || 'headingMd'} as="h6">
                {props.title}
                {props.tooltip && (
                  <div className="information-icon">
                    <Tooltip content={props.tooltip}>
                      <Icon source={InfoIcon} tone="base" />
                    </Tooltip>
                  </div>
                )}
              </Text>
              {props.actionGroup &&
                props.actionGroup.map((item, index) => {
                  return (
                    <Fragment key={index}>
                      {typeof item.content === 'string' ? (
                        <Button key={index} variant={item.variant} url={item.url} onClick={item.onAction} {...item}>
                          {item.content}
                        </Button>
                      ) : (
                        item.content
                      )}
                    </Fragment>
                  );
                })}
            </div>
            {props.subTitle ? (
              <p className="pl-16" style={{ fontSize: '0.8125rem' }}>
                {props.subTitle}
              </p>
            ) : null}
            <div className="custom-card-content">{props.children}</div>
          </div>
          {props.contentRight}
        </InlineGrid>
      </Card>
    </CardStyled>
  );
};

export default memo(OneThirdCardLayout);
