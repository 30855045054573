import BoldText from '@/components/BoldText';
import RegularText from '@/components/RegularText';
import { camelToSnakeCase, checkDirtyField, splitObjects } from '@/helpers';
import MenuItem from '@/pages/TrackingPage/components/EDD/components/ModalTrackingPage/components/MenuItem';
import { convertClientKeyToApiKey } from '@/pages/TrackingPage/components/Language/config';
import UploadAsset from '@/pages/TrackingPage/components/LookAndFeel/components/ChooseTheme/UploadAsset';
import trackingPageSlice, { languageSelector, LanguageValue, trackingPageSelector } from '@/redux/features/trackingPage.slice';
import { Button, Checkbox, TextField } from '@shopify/polaris';
import { ChevronLeftIcon, EditIcon } from '@shopify/polaris-icons';
import { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

enum LookupEditType {
  TrackingNumber = 'trackingNumber',
  OrderId = 'orderId',
  Email = 'email',
  Phone = 'phone',
  NotFoundMessage = 'notFoundMessage',
  TitleAndButton = 'titleAndButton',
  Image = 'image',
}

type LookupOptionKey =
  | 'isEnabledTrackingNumber'
  | 'isEnabledOrderId'
  | 'isEnabledEmail'
  | 'isEnabledPhoneNumber'
  | 'requireEmail'
  | 'requireOrderId'
  | 'requirePhoneNumber'
  | 'requireTrackingNumber';

const Lookup = () => {
  const dispatch = useDispatch();
  const language = useSelector(languageSelector);
  const data = useSelector(trackingPageSelector).value;
  const {
    fieldsChange,
    oldValue: {
      lookAndFeel: { lookupOption: oldLookupOption },
      configuration: { notInfoOrder: oldNotInfoOrder },
    },
  } = useSelector(trackingPageSelector);
  const {
    isEnabledEmail,
    isEnabledOrderId,
    isEnabledPhoneNumber,
    isEnabledTrackingNumber,
    requireEmail,
    requireOrderId,
    requirePhoneNumber,
    requireTrackingNumber,
  } = data.lookAndFeel.lookupOption;
  const { notInfoOrder } = data.configuration;

  const [currentEditItem, setCurrentEditItem] = useState<LookupEditType>();
  const lookupOptions: {
    id: LookupEditType;
    key: LookupOptionKey;
    isChecked: boolean | undefined;
    label: string;
  }[] = useMemo(() => {
    return [
      {
        id: LookupEditType.TrackingNumber,
        key: 'isEnabledTrackingNumber',
        isChecked: isEnabledTrackingNumber,
        label: 'Tracking number',
      },
      { id: LookupEditType.OrderId, key: 'isEnabledOrderId', isChecked: isEnabledOrderId, label: 'Order ID' },
      {
        id: LookupEditType.Email,
        key: 'isEnabledEmail',
        isChecked: isEnabledEmail,
        label: 'Email',
      },
      {
        id: LookupEditType.Phone,
        key: 'isEnabledPhoneNumber',
        isChecked: isEnabledPhoneNumber,
        label: 'Phone number',
      },
    ];
  }, [isEnabledEmail, isEnabledOrderId, isEnabledPhoneNumber, isEnabledTrackingNumber]);

  const appearanceOptions = useMemo(() => {
    return [
      { id: LookupEditType.TitleAndButton, label: 'Title & button' },
      { id: LookupEditType.NotFoundMessage, label: 'Not found message' },
      { id: LookupEditType.Image, label: 'Images' },
    ];
  }, []);

  const handleLanguageField = useCallback(
    (currentValue: {}, parentKey: string) => {
      const { dirtyField: parentKeyField } = checkDirtyField(
        language.oldValue.value[parentKey as keyof typeof language.value],
        currentValue,
      );
      return !parentKeyField ? undefined : changeKeyOfObject(parentKeyField, parentKey);
    },
    [language],
  );

  const handleChangeLanguage = useCallback(
    (parentKey: string, key: string) => (value: string | boolean) => {
      const { fieldsChange } = language;
      dispatch(
        trackingPageSlice.actions.handleLanguageValue({
          ...language.value,
          [parentKey]: {
            ...language.value[parentKey as keyof LanguageValue],
            [key]: value,
          },
        }),
      );
      dispatch(trackingPageSlice.actions.handleChangeValidationLanguage(language.validation.filter((item) => item !== key)));
      dispatch(
        trackingPageSlice.actions.handleLanguageFieldsChange({
          ...fieldsChange,
          [parentKey]: handleLanguageField(
            {
              ...language.value[parentKey as keyof LanguageValue],
              [key]: value,
            },
            parentKey,
          ),
        }),
      );
    },
    [dispatch, handleLanguageField, language],
  );

  const changeKeyOfObject = (currentObject: Partial<Record<string, never>>, parentKey: string) => {
    const replacedObject = Object.keys(currentObject).map((key) => {
      const newKey =
        parentKey === 'trackingForm' || parentKey === 'footer'
          ? convertClientKeyToApiKey[parentKey][key as keyof (typeof convertClientKeyToApiKey)['trackingForm' | 'footer']]
          : camelToSnakeCase(key);
      return { [newKey]: currentObject[key] };
    });
    return replacedObject.reduce((a, b) => Object.assign({}, a, b));
  };

  const handleNoInfoField = useCallback(
    (currentValue: {}) => {
      const { dirtyField: noInfoField } = checkDirtyField(splitObjects(oldNotInfoOrder, 0, 1), {
        ...currentValue,
      });

      const { dirtyField: notFoundField } = checkDirtyField(splitObjects(oldNotInfoOrder, 1, 2), {
        ...currentValue,
      });

      const { dirtyField: messageField } = checkDirtyField(splitObjects(oldNotInfoOrder, 2, 3), {
        ...currentValue,
      });

      if (!noInfoField && !notFoundField && !messageField) {
        return undefined;
      }
      return {
        trackUrl: !noInfoField ? undefined : { isActive: noInfoField?.isNoInfo },
        isReplaceMessage: notFoundField?.isNotFound,
        replaceMessage: messageField?.message,
      };
    },
    [oldNotInfoOrder],
  );

  const handleChangeNoInfo = useCallback(
    (key: string) => (value: string | boolean) => {
      dispatch(
        trackingPageSlice.actions.handleTrackingPageValue({
          ...data,
          configuration: {
            ...data.configuration,
            notInfoOrder: {
              ...notInfoOrder,
              [key]: value,
            },
          },
        }),
      );
      dispatch(
        trackingPageSlice.actions.handleTrackingPageFieldsChange({
          ...fieldsChange,
          customMessage: handleNoInfoField({ ...notInfoOrder, [key]: value }),
        }),
      );
    },
    [data, dispatch, fieldsChange, handleNoInfoField, notInfoOrder],
  );

  const handleChangeLookupOption = useCallback(
    (key: LookupOptionKey) => (value: boolean) => {
      dispatch(
        trackingPageSlice.actions.handleTrackingPageValue({
          ...data,
          lookAndFeel: {
            ...data.lookAndFeel,
            lookupOption: {
              ...data.lookAndFeel.lookupOption,
              [key]: value,
            },
          },
        }),
      );
      const { dirtyField } = checkDirtyField(oldLookupOption, {
        ...data.lookAndFeel.lookupOption,
        [key]: value,
      });
      dispatch(
        trackingPageSlice.actions.handleTrackingPageFieldsChange({
          ...fieldsChange,
          isEnabledTrackingNumber: dirtyField?.isEnabledTrackingNumber,
          isEnabledOrderId: dirtyField?.isEnabledOrderId,
          isEnabledEmail: dirtyField?.isEnabledEmail,
          isEnabledPhoneNumber: dirtyField?.isEnabledPhoneNumber,
          trackingForm:
            dirtyField?.requireEmail !== undefined ||
            dirtyField?.requireOrderId !== undefined ||
            dirtyField?.requirePhoneNumber !== undefined ||
            dirtyField?.requireTrackingNumber !== undefined
              ? {
                  requireEmail: dirtyField?.requireEmail,
                  requireOrderId: dirtyField?.requireOrderId,
                  requirePhoneNumber: dirtyField?.requirePhoneNumber,
                  requireTrackingNumber: dirtyField?.requireTrackingNumber,
                }
              : undefined,
        }),
      );
    },
    [data, dispatch, fieldsChange, oldLookupOption],
  );

  const renderEditContent = useCallback(() => {
    switch (currentEditItem) {
      case LookupEditType.TrackingNumber:
        return (
          <>
            <Checkbox
              label="This field is required"
              checked={requireTrackingNumber}
              onChange={handleChangeLookupOption('requireTrackingNumber')}
            />
            <TextField
              maxLength={100}
              showCharacterCount
              label="Tracking number label"
              placeholder="Tracking number"
              autoComplete="off"
              value={language.value.trackingForm.trackingNumber}
              onChange={handleChangeLanguage('trackingForm', 'trackingNumber')}
            />
            <TextField
              maxLength={100}
              showCharacterCount
              label="Tracking number placeholder"
              placeholder="Enter tracking number"
              autoComplete="off"
              value={language.value.trackingForm.placeholderTrackingNumber}
              onChange={handleChangeLanguage('trackingForm', 'placeholderTrackingNumber')}
            />
            <TextField
              maxLength={100}
              showCharacterCount
              label="Empty tracking number error message"
              placeholder="Please enter tracking"
              autoComplete="off"
              value={language.value.trackingForm.emptyTrackingNumber}
              onChange={handleChangeLanguage('trackingForm', 'emptyTrackingNumber')}
            />
          </>
        );
      case LookupEditType.OrderId:
        return (
          <>
            <Checkbox
              label="This field is required"
              checked={requireOrderId}
              onChange={handleChangeLookupOption('requireOrderId')}
            />
            <TextField
              maxLength={100}
              showCharacterCount
              label="Order ID label"
              placeholder="Order ID"
              autoComplete="off"
              value={language.value.trackingForm.orderId}
              onChange={handleChangeLanguage('trackingForm', 'orderId')}
            />
            <TextField
              maxLength={100}
              showCharacterCount
              label="Order ID placeholder"
              placeholder="Enter your Order ID"
              autoComplete="off"
              value={language.value.trackingForm.placeholderOrderId}
              onChange={handleChangeLanguage('trackingForm', 'placeholderOrderId')}
            />
            <TextField
              maxLength={100}
              showCharacterCount
              label="Empty Order ID error message"
              placeholder="Please enter Order ID"
              autoComplete="off"
              value={language.value.trackingForm.emptyOrderName}
              onChange={handleChangeLanguage('trackingForm', 'emptyOrderName')}
            />
          </>
        );
      case LookupEditType.Email:
        return (
          <>
            <Checkbox label="This field is required" checked={requireEmail} onChange={handleChangeLookupOption('requireEmail')} />
            <TextField
              maxLength={100}
              showCharacterCount
              label="Email label"
              placeholder="Email"
              autoComplete="off"
              value={language.value.trackingForm.email}
              onChange={handleChangeLanguage('trackingForm', 'email')}
            />
            <TextField
              maxLength={100}
              showCharacterCount
              label="Email placeholder"
              placeholder="Enter your email"
              autoComplete="off"
              value={language.value.trackingForm.placeholderEmail}
              onChange={handleChangeLanguage('trackingForm', 'placeholderEmail')}
            />
            <TextField
              maxLength={100}
              showCharacterCount
              label="Empty email error message"
              placeholder="Please enter email"
              autoComplete="off"
              value={language.value.trackingForm.emptyEmail}
              onChange={handleChangeLanguage('trackingForm', 'emptyEmail')}
            />
            <TextField
              maxLength={100}
              showCharacterCount
              label="Invalid email error message"
              placeholder="Invalid email address"
              autoComplete="off"
              value={language.value.trackingForm.invalidEmail}
              onChange={handleChangeLanguage('trackingForm', 'invalidEmail')}
            />
          </>
        );
      case LookupEditType.Phone:
        return (
          <>
            <Checkbox
              label="This field is required"
              checked={requirePhoneNumber}
              onChange={handleChangeLookupOption('requirePhoneNumber')}
            />
            <TextField
              maxLength={100}
              showCharacterCount
              label="Phone number label"
              placeholder="Phone"
              autoComplete="off"
              value={language.value.trackingForm.phoneNumber}
              onChange={handleChangeLanguage('trackingForm', 'phoneNumber')}
            />
            <TextField
              maxLength={100}
              showCharacterCount
              label="Phone number placeholder"
              placeholder="Enter your phone number"
              autoComplete="off"
              value={language.value.trackingForm.placeholderPhoneNumber}
              onChange={handleChangeLanguage('trackingForm', 'placeholderPhoneNumber')}
            />
            <TextField
              maxLength={100}
              showCharacterCount
              label="Empty phone number error message"
              placeholder="Please enter phone"
              autoComplete="off"
              value={language.value.trackingForm.emptyPhoneNumber}
              onChange={handleChangeLanguage('trackingForm', 'emptyPhoneNumber')}
            />
          </>
        );
      case LookupEditType.NotFoundMessage:
        return (
          <>
            <RegularText>When order is not found, provide message to inform or comfort customers.</RegularText>
            <TextField
              maxLength={200}
              showCharacterCount
              label="Order not found title"
              placeholder="Order ID"
              autoComplete="off"
              multiline
              value={language.value.trackingForm.orderNotFound}
              onChange={handleChangeLanguage('trackingForm', 'orderNotFound')}
            />
            <TextField
              maxLength={200}
              showCharacterCount
              label="Order not found message"
              placeholder="The search information may not be correct. Please try again"
              autoComplete="off"
              multiline
              value={notInfoOrder.message}
              onChange={handleChangeNoInfo('message')}
            />
          </>
        );
      case LookupEditType.TitleAndButton:
        return (
          <>
            <TextField
              maxLength={100}
              showCharacterCount
              label="Title"
              placeholder="Track your order"
              autoComplete="off"
              value={language.value.trackingForm.trackYourOrder}
              onChange={handleChangeLanguage('trackingForm', 'trackYourOrder')}
            />
            <TextField
              maxLength={100}
              showCharacterCount
              label="Button"
              placeholder="Check status"
              autoComplete="off"
              value={language.value.trackingForm.track}
              onChange={handleChangeLanguage('trackingForm', 'track')}
            />
          </>
        );
      case LookupEditType.Image:
        return <UploadAsset isShowTrackingFormSetup />;
      default:
        return null;
    }
  }, [
    currentEditItem,
    requireTrackingNumber,
    handleChangeLookupOption,
    language.value.trackingForm.trackingNumber,
    language.value.trackingForm.placeholderTrackingNumber,
    language.value.trackingForm.emptyTrackingNumber,
    language.value.trackingForm.orderId,
    language.value.trackingForm.placeholderOrderId,
    language.value.trackingForm.emptyOrderName,
    language.value.trackingForm.email,
    language.value.trackingForm.placeholderEmail,
    language.value.trackingForm.emptyEmail,
    language.value.trackingForm.invalidEmail,
    language.value.trackingForm.phoneNumber,
    language.value.trackingForm.placeholderPhoneNumber,
    language.value.trackingForm.emptyPhoneNumber,
    language.value.trackingForm.orderNotFound,
    language.value.trackingForm.trackYourOrder,
    language.value.trackingForm.track,
    handleChangeLanguage,
    requireOrderId,
    requireEmail,
    requirePhoneNumber,
    notInfoOrder.message,
    handleChangeNoInfo,
  ]);

  return currentEditItem ? (
    <>
      <MenuItem>
        <div className="d-flex align-center gap-4">
          <Button icon={ChevronLeftIcon} onClick={() => setCurrentEditItem(undefined)} variant="monochromePlain" />
          <BoldText>{[...lookupOptions, ...appearanceOptions].find((item) => item.id === currentEditItem)?.label}</BoldText>
        </div>
      </MenuItem>
      <div className="pd-16 border-bottom d-flex flex-column gap-8 edit-content-left">{renderEditContent()}</div>
    </>
  ) : (
    <>
      <MenuItem>
        <BoldText>Order lookup page</BoldText>
      </MenuItem>

      <MenuItem tone="tertiary">
        <BoldText customFontSize>Lookup options</BoldText>
      </MenuItem>
      {lookupOptions.map((item) => (
        <MenuItem actionIcon={EditIcon} onAction={() => setCurrentEditItem(item.id)} key={item.id}>
          <Checkbox
            checked={item.isChecked}
            onChange={handleChangeLookupOption(item.key)}
            label={<RegularText>{item.label}</RegularText>}
          />
        </MenuItem>
      ))}

      <MenuItem tone="tertiary">
        <BoldText customFontSize>Appearance</BoldText>
      </MenuItem>
      {appearanceOptions.map((item) => (
        <MenuItem actionIcon={EditIcon} onAction={() => setCurrentEditItem(item.id)} key={item.id}>
          <RegularText>{item.label}</RegularText>
        </MenuItem>
      ))}
    </>
  );
};

export default Lookup;
