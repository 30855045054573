import { BgColorPalette } from '@/constants';
import styled from 'styled-components';

export const ContentContainerStyled = styled.div`
  height: 80vh;
  background-color: var(--p-color-bg);
  .Polaris-Layout,
  .Polaris-Layout__Section.Polaris-Layout__Section--oneThird {
    height: calc(100% - var(--p-space-200));
  }
  .Polaris-Layout .Polaris-Layout__Section:nth-child(2) {
    margin-left: 0;
  }
  .actions-bar {
    background-color: #fff;
    padding: 0.75rem;
    height: 100%;
    border-right: 1px solid ${BgColorPalette.SECONDARY_ACTIVE};
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    position: sticky;
    top: 0;
    .nav-icon {
      cursor: pointer;
      padding: 0.25rem;
      border-radius: 0.5rem;
      & svg {
        fill: ${BgColorPalette.OVERLAY};
      }
      &:hover,
      &.nav-icon__active {
        & svg {
          fill: ${BgColorPalette.PRIMARY_HOVER};
        }
        background: rgba(224, 224, 224, 0.8);
      }
    }
    @media (max-width: 768px) {
      border-bottom: 1px solid #e3e3e3;
    }
  }
  .content-left {
    width: 100%;
    max-height: 100%;
    overflow: auto;
    background-color: #fff;
    border-bottom: 1px solid #e3e3e3;

    .status-icon-btn {
      width: 36px;
      height: 36px;
      border-radius: 50%;
      border: 1px solid ${BgColorPalette.OVERLAY_2};
      overflow: hidden;
      cursor: pointer;
      &:hover {
        border-color: ${BgColorPalette.OVERLAY};
      }
      &.selected {
        border: 2px solid #005bd3;
      }
      & > img {
        width: 22px;
        height: 22px;
        object-fit: cover;
      }
    }
    .Polaris-ButtonGroup {
      justify-content: flex-end;
    }

    .edit-content-left {
      height: calc(100% - 54px);
      overflow: auto;
    }
  }
  .content-right {
    padding: 0.5rem;
    .content-right-actions {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;
    }
    .content-right-preview {
      height: 100%;
    }

    @media screen and (max-width: 715px) {
      max-width: 100%;
      margin-left: 20px;
    }
  }
`;
