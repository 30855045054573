import { config } from '@/config';
import { OTElementId } from '@/constants';
import { useAppBridge } from '@shopify/app-bridge-react';

const useHandleContextualNotEmbedded = () => {
  const hide = () => {};
  const show = () => {};
  const leaveConfirmation = () => {};
  return {
    hide,
    show,
    leaveConfirmation,
  };
};

const useHandleContextual = () => {
  const shopify = useAppBridge();
  const hide = () => {
    shopify?.saveBar?.hide(OTElementId.SaveBar);
  };

  const show = () => {
    shopify?.saveBar?.show(OTElementId.SaveBar);
  };

  const leaveConfirmation = () => {
    shopify?.saveBar?.leaveConfirmation();
  };

  return {
    hide,
    show,
    leaveConfirmation,
  };
};

export default config.embedded === '1' ? useHandleContextual : useHandleContextualNotEmbedded;
