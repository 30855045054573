import OneThirdCardLayout from '@/components/CustomCard/OneThirdCardLayout';
import { images, PATH } from '@/constants';
import { AccountPlan } from '@/constants/enum';
import { checkDirtyField, isLockFeature } from '@/helpers';
import useConfirmationNavigate from '@/hooks/useConfirmationNavigate';
import { useGetBlacklistingQuery } from '@/redux/api/api.caller';
import { accountSelector, christmasBannerSelector } from '@/redux/features/plan.slice';
import trackingPageSlice, { blacklistingSelector, IBlacklisting } from '@/redux/features/trackingPage.slice';
import { Button, ButtonGroup, Text } from '@shopify/polaris';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const Blacklisting = () => {
  const navigate = useConfirmationNavigate();
  const dispatch = useDispatch();
  const { value: blacklisting, fieldsChange, oldValue } = useSelector(blacklistingSelector);
  const plan = useSelector(accountSelector);
  const { formattedEndDate } = useSelector(christmasBannerSelector);
  const isActiveChristmasFeature = new Date() < new Date(formattedEndDate);
  const isLockedFeature =
    isLockFeature([AccountPlan.Starter, AccountPlan.Basic]) && !plan.isOldPaidUser && !isActiveChristmasFeature;

  const { data, isLoading } = useGetBlacklistingQuery(undefined, { skip: isLockedFeature });

  const blacklistingBtnText = useMemo(() => {
    if (blacklisting.isEnableBtn) {
      return 'Disable';
    }
    return 'Enable';
  }, [blacklisting.isEnableBtn]);

  const handleChangeBlacklisting = (key: 'isEnableBtn' | 'replaceWith', value: IBlacklisting[keyof IBlacklisting]) => {
    dispatch(trackingPageSlice.actions.handleChangeBlacklistingValue({ ...blacklisting, [key]: value }));
    const { dirtyField } = checkDirtyField(oldValue, { ...blacklisting, [key]: value });

    dispatch(
      trackingPageSlice.actions.handleChangeBlacklistingFieldsChange(
        dirtyField
          ? {
              ...fieldsChange,
              enabled: dirtyField?.isEnableBtn,
              replaceWith: dirtyField?.replaceWith,
            }
          : {},
      ),
    );
  };

  useEffect(() => {
    // if (data && data.data && data.data.areaToReplaceWith && data.data.areasToBeReplaced) {
    if (data && data.data) {
      const blacklistingValue = {
        ...blacklisting,
        isEnableBtn: data.data.enabled,
        replaceWith: data.data.replaceWith,
        areasToBeReplaced: { ...blacklisting.areasToBeReplaced, selected: data.data.areasToBeReplaced || [] },
        areaToReplaceWith: {
          ...blacklisting.areaToReplaceWith,
          country: {
            ...blacklisting.areaToReplaceWith.country,
            selected: data.data.areaToReplaceWith?.country ? [data.data.areaToReplaceWith.country] : [],
          },
          city: {
            ...blacklisting.areaToReplaceWith.city,
            selected: data.data.areaToReplaceWith?.city ? [data.data.areaToReplaceWith.city] : [],
          },
        },
      };
      dispatch(trackingPageSlice.actions.handleChangeBlacklistingValue(blacklistingValue));
      dispatch(trackingPageSlice.actions.handleChangeBlacklistingOldValue(blacklistingValue));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <OneThirdCardLayout
      padding="0"
      title="Blacklisting"
      contentRight={
        <div className="w-full text-right">
          <img src={images.blacklisting} alt="tracking page editor" className="image-content-right" />
        </div>
      }
    >
      <Text variant="bodyMd" as="p">
        Replace or hide specific characters and locations (e.g. Chinese origins) from the tracking history, useful for
        dropshipping.
      </Text>
      <div className="mt-16">
        <ButtonGroup>
          <Button
            onClick={() => {
              handleChangeBlacklisting('isEnableBtn', !blacklisting.isEnableBtn);
            }}
            disabled={isLoading || isLockedFeature}
            variant={!blacklisting.isEnableBtn ? 'primary' : undefined}
          >
            {blacklistingBtnText}
          </Button>
          <Button onClick={() => navigate(PATH.BLACKLIST)}>Settings</Button>
        </ButtonGroup>
      </div>
    </OneThirdCardLayout>
  );
};

export default Blacklisting;
