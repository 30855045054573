import { isUrlValid, renderErrorValue } from '@/helpers';
import { useGetTrackingPageQuery } from '@/redux/api/api.caller';
import trackingPageMediaSlice, { trackingPageMediaSelector } from '@/redux/features/trackingPageMedia.slice';
import { BlockStack, InlineGrid } from '@shopify/polaris';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AssetCard from './AssetCard';
import { uploadFileConfig } from './config';

const versionImage = `?v=${Date.now()}`;

interface IProps {
  isShowTrackingFormSetup?: boolean;
}

const UploadAsset = ({ isShowTrackingFormSetup }: IProps) => {
  const [validation, setValidation] = useState<Array<string>>([]);
  const { data } = useGetTrackingPageQuery();
  const dispatch = useDispatch();
  // const [loadingAsset, setLoadingAsset] = useState('');
  // const [uploadImage, uploadData] = useUploadMediaMutation();
  // const [updateUploadImage, updateUploadData] = useUpdateUploadMediaMutation();

  const trackingPageMedia = useSelector(trackingPageMediaSelector);

  const [initState, setInitState] = useState({
    asset1: {
      index: 1,
      value: '',
      file: null,
    },
    asset2: {
      index: 2,
      value: '',
      file: null,
    },
    asset3: {
      index: 3,
      value: '',
      file: null,
    },
    asset4: {
      index: 4,
      value: '',
      file: null,
    },
    asset5: {
      index: 5,
      value: '',
      file: null,
    },
  });
  const [state, setState] = useState({
    asset1: {
      index: 1,
      value: '',
      url: '',
      file: null,
    },
    asset2: {
      index: 2,
      value: '',
      url: '',
      file: null,
    },
    asset3: {
      index: 3,
      value: '',
      url: '',
      file: null,
    },
    asset4: {
      index: 4,
      value: '',
      url: '',
      file: null,
    },
    asset5: {
      index: 5,
      value: '',
      url: '',
      file: null,
    },
  });

  useEffect(() => {
    if (data) {
      const { media } = data.data.lookAndFeel;
      setInitState({
        asset1: {
          ...initState.asset1,
          value: media.asset1.url || '',
        },
        asset2: {
          ...initState.asset2,
          value: media.asset2.url || '',
        },
        asset3: {
          ...initState.asset3,
          value: media.asset3.url || '',
        },
        asset4: {
          ...initState.asset4,
          value: media.asset4.url || '',
        },
        asset5: {
          ...initState.asset5,
          value: media.asset5.url || '',
        },
      });
      setState({
        asset1: {
          ...state.asset1,
          url:
            media.asset1.path && !media.asset1.path.includes('asset1')
              ? media.asset1.baseUrl + media.asset1.path + versionImage
              : `${process.env.REACT_APP_S3_ASSETS_BASE_URL}/trackingpage/asset1.png`,
          value: media.asset1.url || '',
        },
        asset2: {
          ...state.asset2,
          url:
            media.asset2.path && !media.asset2.path.includes('asset2')
              ? media.asset2.baseUrl + media.asset2.path + versionImage
              : `${process.env.REACT_APP_S3_ASSETS_BASE_URL}/trackingpage/asset2.png`,
          value: media.asset2.url || '',
        },
        asset3: {
          ...state.asset3,
          url:
            media.asset3.path && !media.asset3.path.includes('asset3')
              ? media.asset3.baseUrl + media.asset3.path + versionImage
              : `${process.env.REACT_APP_S3_ASSETS_BASE_URL}/trackingpage/asset3.png`,
          value: media.asset3.url || '',
        },
        asset4: {
          ...state.asset4,
          url:
            media.asset4.path && !media.asset4.path.includes('asset4')
              ? media.asset4.baseUrl + media.asset4.path + versionImage
              : `${process.env.REACT_APP_S3_ASSETS_BASE_URL}/trackingpage/asset4.png`,
          value: media.asset4.url || '',
        },
        asset5: {
          ...state.asset5,
          url:
            media.asset5.path && !media.asset5.path.includes('asset5')
              ? media.asset5.baseUrl + media.asset5.path + versionImage
              : `${process.env.REACT_APP_S3_ASSETS_BASE_URL}/trackingpage/asset5.png`,
          value: media.asset5.url || '',
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleUploadFile = (key: string) => (file: File) => {
    setState({
      ...state,
      [key]: {
        ...state[key as keyof typeof state],
        file,
      },
    });
  };
  const handleChangeValue = (key: string) => (value: string) => {
    setState({
      ...state,
      [key]: {
        ...state[key as keyof typeof state],
        value,
      },
    });
    setValidation(validation.filter((item) => item !== key));
  };

  const handleBlurTextField = (value: string, key: string) => {
    if (value && !isUrlValid(value)) {
      const isNotHaveKey = validation.findIndex((item) => item === key) === -1;
      if (isNotHaveKey) {
        setValidation([...validation, key]);
      }
    } else {
      setValidation(validation.filter((item) => item !== key));
    }
  };

  // const handleUploadSuccess = (key: string, res: any) => {
  //   const updatedState = {
  //     ...state,
  //     [key]: {
  //       ...state[key as keyof typeof state],
  //       file: null,
  //     },
  //   };

  //   setState(updatedState);
  //   setInitState(updatedState);
  //   dispatch(commonSlice.actions.handleToast(handleToastMutation(res)));
  // };

  // const handleUploadImage = (key: string) => () => {
  //   setLoadingAsset(key);
  //   const data = state[key as keyof typeof state];
  //   const initData = initState[key as keyof typeof initState];

  //   const formData = new FormData();
  //   formData.append('name', `asset-${data.index}`);
  //   if (data.file) {
  //     formData.append('file', data.file);
  //   }
  //   if (data.value) {
  //     formData.append('url', data.value);
  //   }
  //   formData.append('shop', shop);
  //   formData.append('urlParams', token?.urlParams || '');

  //   if (!data.file && data.value !== initData.value) {
  //     return updateUploadImage({ name: `asset-${data.index}`, url: data.value }).then((res) => handleUploadSuccess(key, res));
  //   }
  //   uploadImage(formData).then((res) => handleUploadSuccess(key, res));
  // };

  const disableSaveData = useMemo(() => {
    const temp = [...uploadFileConfig.trackingForm, ...uploadFileConfig.trackingResult];
    return (
      temp.every((item) => {
        const asset = state[item.key as keyof typeof state];
        const initAsset = initState[item.key as keyof typeof initState];
        return asset.value === initAsset.value && asset.file === initAsset.file;
      }) ||
      temp?.some((item) => {
        const asset = state[item.key as keyof typeof state];

        return asset.value ? !isUrlValid(asset.value) : false;
      })
    );
  }, [initState, state]);
  // const disableSaveData = useMemo(() => {
  //   const temp = [...uploadFileConfig.trackingForm, ...uploadFileConfig.trackingResult];
  //   return (
  //     temp.every((item) => {
  //       const asset = state[item.key as keyof typeof state];
  //       return !asset.value && !asset.file;
  //     }) ||
  //     temp?.some((item) => {
  //       const asset = state[item.key as keyof typeof state];
  //       const initAsset = initState[item.key as keyof typeof initState];

  //       return (
  //         (asset.value && (asset.value === initAsset.value || !isUrlValid(asset.value))) ||
  //         (asset.file && asset.file === initAsset.file)
  //       );
  //     })
  //   );
  // }, [initState, state]);

  useEffect(() => {
    console.log('log_disableSaveData: ', disableSaveData);
    if (disableSaveData && trackingPageMedia.enabledSave) {
      dispatch(trackingPageMediaSlice.actions.handleUpdateMedia({ enabledSave: false, tempData: state }));
    } else if (!disableSaveData) {
      dispatch(trackingPageMediaSlice.actions.handleUpdateMedia({ enabledSave: true, data: state, tempData: state }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(state), disableSaveData, JSON.stringify(trackingPageMedia)]);

  const renderAssetCards = (
    assets: {
      key: string;
      title: string;
      size: string;
    }[],
  ) => {
    return assets.map((item, index, arr) => {
      const asset = state[item.key as keyof typeof state];
      const initAsset = initState[item.key as keyof typeof initState];

      return (
        <AssetCard
          key={item.key}
          assetNo={item.title}
          fileUrl={state[item.key as keyof typeof state].url}
          fileSize={item.size}
          onUploadFile={handleUploadFile(item.key)}
          destinationValue={state[item.key as keyof typeof state].value}
          onChangeDestination={handleChangeValue(item.key)}
          onBlurDestination={() => handleBlurTextField(state[item.key as keyof typeof state].value, item.key)}
          disabledBtn={
            (asset.value === initAsset.value && asset.file === initAsset.file && !asset.file) || validation.includes(item.key)
          }
          loadingBtn={false}
          // loadingBtn={(uploadData.isLoading || updateUploadData.isLoading) && loadingAsset === item.key}
          // onClickBtn={handleUploadImage(item.key)}
          onClickBtn={() => {}}
          error={renderErrorValue(validation, item.key, 'Destination URL must be an URL address')}
          isHiddenPublishBtn
          isShowDivider={index < arr.length - 1}
        />
      );
    });
  };

  return (
    <>
      {isShowTrackingFormSetup ? (
        <BlockStack gap="400">{renderAssetCards(uploadFileConfig.trackingForm)}</BlockStack>
      ) : (
        <InlineGrid columns={{ xs: 1 }} gap="400">
          {renderAssetCards(uploadFileConfig.trackingResult)}
        </InlineGrid>
      )}
    </>
  );
};

export default UploadAsset;
