import { Icon, Text } from '@shopify/polaris';
import { StyledNotVerifiedPopup } from './styled';
import { XIcon } from '@shopify/polaris-icons';
import { memo, useRef } from 'react';
import { useOnClickOutside } from '@/hooks';

interface VerifiedEmailPopupProps {
  email: string;
  isOpen: boolean;
  onClosePopup: () => void;
}

const VerifiedEmailPopup = ({ email, isOpen, onClosePopup }: VerifiedEmailPopupProps) => {
  const btnRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(btnRef, onClosePopup);

  return isOpen ? (
    <StyledNotVerifiedPopup>
      <div className="popup-container" ref={btnRef}>
        <div className="cancel-icon" onClick={onClosePopup}>
          <Icon source={XIcon} tone="base" />
        </div>
        <img src={`${process.env.REACT_APP_S3_ASSETS_BASE_URL}/email/verified_email.png`} alt="verified email" />

        <Text variant="headingLg" as="h3">
          Please verify your email
        </Text>

        <div className="email-info">
          You’re almost there! We sent an email to
          <br /> <span>{email}</span>
        </div>

        <div className="description">
          Just click on the link that email to complete your connection. If you don’t see it, you may need to{' '}
          <span>check your spam</span> folder.
        </div>
      </div>
    </StyledNotVerifiedPopup>
  ) : (
    <></>
  );
};

export default memo(VerifiedEmailPopup);
