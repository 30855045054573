import { Frame, Page, PageProps } from '@shopify/polaris';
import { memo, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Toast from '../Toast';
import { LayoutStyled } from './styled';

export interface ILayoutProps extends PageProps {
  maxWidth?: string;
}

const Layout = ({ maxWidth = '80rem', ...props }: ILayoutProps): JSX.Element => {
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname) {
      window.scrollTo(0, 0);
    }
  }, [pathname]);

  return (
    <LayoutStyled maxWidth={maxWidth}>
      <Frame>
        <Page {...props}>{props.children}</Page>
        <Toast />
      </Frame>
    </LayoutStyled>
  );
};

export default memo(Layout);
