import { IToast } from '@/types';

export const handleToastMutation = (res: any): IToast => {
  if ('data' in res) {
    if (res?.data?.state === 1) {
      return {
        isOpen: true,
        content: res?.data?.msg || 'Save successfully',
        error: false,
      };
    }
    return {
      isOpen: true,
      content: 'Something happened',
      error: false,
    };
  }
  return {
    isOpen: false,
    content: 'Something happened',
  };
};

export const getErrorFromAPI = (res: any): string => {
  if ('data' in res && res.data?.state !== 1) {
    return res?.data?.msg || 'Save failed';
  }
  if ('error' in res && res?.error?.data?.statusCode === 400) {
    return res?.error?.data?.message?.toString() || 'Save failed';
  }

  return res?.error?.data?.message?.toString() || 'Save failed';
};
