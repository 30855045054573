import React, { useCallback } from 'react';
import CheckboxInput from '../../../CheckboxInput';
import { ProgressBar } from '@/constants/enum';
import { useDispatch, useSelector } from 'react-redux';
import trackingPageSlice, { languageSelector, LanguageValue, trackingPageSelector } from '@/redux/features/trackingPage.slice';
import { isNotChangeData } from '@/pages/TrackingPage/config';
import { Checkbox, Divider, InlineError, TextField } from '@shopify/polaris';
import CustomStatus from './CustomStatus';
import BoldRegularText from '@/components/BoldRegularText';
import { convertClientKeyToApiKey } from '@/pages/TrackingPage/components/Language/config';
import { camelToSnakeCase, checkDirtyField, splitObjects } from '@/helpers';

const ProgressBarContent = () => {
  const dispatch = useDispatch();
  const language = useSelector(languageSelector);
  const { trackingStatus } = language.value;
  const {
    value: data,
    fieldsChange,
    oldValue: {
      lookAndFeel: { content: oldContent },
      configuration: { notInfoOrder: oldNotInfoOrder },
    },
  } = useSelector(trackingPageSelector);
  const { content } = data.lookAndFeel;
  const { progressBar } = content;
  const { notInfoOrder } = data.configuration;

  const handleNoInfoField = (currentValue: {}) => {
    const { dirtyField: noInfoField } = checkDirtyField(splitObjects(oldNotInfoOrder, 0, 1), {
      ...currentValue,
    });

    const { dirtyField: notFoundField } = checkDirtyField(splitObjects(oldNotInfoOrder, 1, 2), {
      ...currentValue,
    });

    const { dirtyField: messageField } = checkDirtyField(splitObjects(oldNotInfoOrder, 2, 3), {
      ...currentValue,
    });

    if (!noInfoField && !notFoundField && !messageField) {
      return undefined;
    }
    return {
      trackUrl: !noInfoField ? undefined : { isActive: noInfoField?.isNoInfo },
      isReplaceMessage: notFoundField?.isNotFound,
      replaceMessage: messageField?.message,
    };
  };

  const handleChangeNoInfo = (key: string) => (value: string | boolean) => {
    dispatch(
      trackingPageSlice.actions.handleTrackingPageValue({
        ...data,
        configuration: {
          ...data.configuration,
          notInfoOrder: {
            ...notInfoOrder,
            [key]: value,
          },
        },
      }),
    );
    dispatch(
      trackingPageSlice.actions.handleTrackingPageFieldsChange({
        ...fieldsChange,
        customMessage: handleNoInfoField({ ...notInfoOrder, [key]: value }),
      }),
    );
  };

  const handleProgressBar = useCallback(
    (value: ProgressBar) => () => {
      const newProgressBar = [...progressBar];
      const index = newProgressBar.findIndex((item) => item === value);
      if (index === -1) {
        newProgressBar.push(value);
      } else {
        newProgressBar.splice(index, 1);
      }
      dispatch(
        trackingPageSlice.actions.handleTrackingPageValue({
          ...data,
          lookAndFeel: {
            ...data.lookAndFeel,
            content: {
              ...content,
              progressBar: newProgressBar,
            },
          },
        }),
      );

      dispatch(
        trackingPageSlice.actions.handleTrackingPageFieldsChange({
          ...fieldsChange,
          displayStatusBar: isNotChangeData(oldContent.progressBar, newProgressBar) ? undefined : newProgressBar.join(','),
        }),
      );
    },
    [content, data, dispatch, fieldsChange, oldContent.progressBar, progressBar],
  );

  const changeKeyOfObject = useCallback((currentObject: Partial<Record<string, never>>, parentKey: string) => {
    const replacedObject = Object.keys(currentObject).map((key) => {
      const newKey =
        parentKey === 'trackingForm' || parentKey === 'footer'
          ? convertClientKeyToApiKey[parentKey][key as keyof (typeof convertClientKeyToApiKey)['trackingForm' | 'footer']]
          : camelToSnakeCase(key);
      return { [newKey]: currentObject[key] };
    });
    return replacedObject.reduce((a, b) => Object.assign({}, a, b));
  }, []);

  const handleLanguageField = useCallback(
    (currentValue: {}, parentKey: string) => {
      const { dirtyField: parentKeyField } = checkDirtyField(
        language.oldValue.value[parentKey as keyof typeof language.value],
        currentValue,
      );
      return !parentKeyField ? undefined : changeKeyOfObject(parentKeyField, parentKey);
    },
    [changeKeyOfObject, language],
  );

  const handleChangeLanguage = useCallback(
    (parentKey: string, key: string) => (value: string | boolean) => {
      const { fieldsChange } = language;
      dispatch(
        trackingPageSlice.actions.handleLanguageValue({
          ...language.value,
          [parentKey]: {
            ...language.value[parentKey as keyof LanguageValue],
            [key]: value,
          },
        }),
      );
      dispatch(trackingPageSlice.actions.handleChangeValidationLanguage(language.validation.filter((item) => item !== key)));
      dispatch(
        trackingPageSlice.actions.handleLanguageFieldsChange({
          ...fieldsChange,
          [parentKey]: handleLanguageField(
            {
              ...language.value[parentKey as keyof LanguageValue],
              [key]: value,
            },
            parentKey,
          ),
        }),
      );
    },
    [dispatch, handleLanguageField, language],
  );

  return (
    <>
      <div className="px-16 pb-8">
        <CheckboxInput
          checkboxLabel="Ordered"
          checked={progressBar.includes(ProgressBar.Ordered)}
          onCheckboxChange={handleProgressBar(ProgressBar.Ordered)}
          inputPlaceholder="Ordered"
          inputValue={trackingStatus.ordered}
          onInputChange={handleChangeLanguage('trackingStatus', 'ordered')}
        />
      </div>
      <Divider />

      <CustomStatus />
      <Divider />

      <div className="px-16 pb-8">
        <CheckboxInput
          checkboxLabel="Order ready"
          checked={progressBar.includes(ProgressBar.OrderReady)}
          onCheckboxChange={handleProgressBar(ProgressBar.OrderReady)}
          inputPlaceholder="Order ready"
          inputValue={trackingStatus.informationReceived}
          onInputChange={handleChangeLanguage('trackingStatus', 'informationReceived')}
        />
      </div>
      <Divider />

      <div className="px-16 pb-8">
        <CheckboxInput
          checkboxLabel="In transit"
          checked={progressBar.includes(ProgressBar.InTransit)}
          onCheckboxChange={handleProgressBar(ProgressBar.InTransit)}
          inputPlaceholder="In transit"
          inputValue={trackingStatus.inTransit}
          onInputChange={handleChangeLanguage('trackingStatus', 'inTransit')}
        />
      </div>
      <Divider />

      <div className="px-16 pb-8">
        <CheckboxInput
          checkboxLabel="Out for delivery"
          checked={progressBar.includes(ProgressBar.OutForDelivery)}
          onCheckboxChange={handleProgressBar(ProgressBar.OutForDelivery)}
          inputPlaceholder="Out for delivery"
          inputValue={trackingStatus.outForDelivery}
          onInputChange={handleChangeLanguage('trackingStatus', 'outForDelivery')}
        />
      </div>
      <Divider />

      <div className="px-16 pb-8">
        <CheckboxInput
          checkboxLabel="Delivered"
          checked={progressBar.includes(ProgressBar.Delivered)}
          onCheckboxChange={handleProgressBar(ProgressBar.Delivered)}
          inputPlaceholder="Delivered"
          inputValue={trackingStatus.delivered}
          onInputChange={handleChangeLanguage('trackingStatus', 'delivered')}
        />
      </div>

      <InlineError fieldID="asd" message={progressBar.length ? '' : 'Please choose at least one option'} />

      <Divider />

      <div className="px-16 d-flex flex-column gap-16 mt-8">
        <BoldRegularText>Other statuses</BoldRegularText>

        <TextField
          maxLength={100}
          showCharacterCount
          label="Courier not supported"
          placeholder="No information"
          autoComplete="off"
          value={trackingStatus.courierNotSupported}
          onChange={handleChangeLanguage('trackingStatus', 'courierNotSupported')}
        />

        <Checkbox
          label="When the status is “No info,” the buyer can check tracking details on the courier’s page via the provided link."
          checked={notInfoOrder.isNoInfo}
          onChange={handleChangeNoInfo('isNoInfo')}
        />

        <TextField
          maxLength={100}
          showCharacterCount
          label="Expired"
          placeholder="Expired"
          autoComplete="off"
          value={trackingStatus.expired}
          onChange={handleChangeLanguage('trackingStatus', 'expired')}
        />

        <TextField
          maxLength={100}
          showCharacterCount
          label="Failed attempt"
          placeholder="Failed attempt"
          autoComplete="off"
          value={trackingStatus.failedAttempt}
          onChange={handleChangeLanguage('trackingStatus', 'failedAttempt')}
        />

        <TextField
          maxLength={100}
          showCharacterCount
          label="Exception"
          placeholder="Exception"
          autoComplete="off"
          value={trackingStatus.exception}
          onChange={handleChangeLanguage('trackingStatus', 'exception')}
        />

        <TextField
          maxLength={100}
          showCharacterCount
          label="Pending"
          placeholder="Pending"
          autoComplete="off"
          value={trackingStatus.pending}
          onChange={handleChangeLanguage('trackingStatus', 'pending')}
        />
      </div>
    </>
  );
};

export default ProgressBarContent;
