export const PATH = {
  DASHBOARD: '/dashboard',
  ANALYTICS: '/analytics',
  SHIPMENT: '/shipments',
  SHIPMENT_DETAIL: '/shipments/:id',
  TRACKING_LOGS: '/shipments/tracking-logs',
  REVIEW_TABLE: '/review-table',
  TRACKING_PAGE: '/tracking-page',
  TRANSIT_TIME: '/tracking-page/transit-time-editor',
  TRANSIT_TIME_DETAIL: '/tracking-page/transit-time-editor/:id',
  NOTIFICATIONS: '/notification-v2',
  NOTIFICATIONS_EMAIL: '/notification-v2/email',
  EMAIL_TEMPLATE: '/notification-v2/email/:status',
  POST_PURCHASE_EDD: '/tracking-page/post-purchase-edd',
  PRE_PURCHASE_EDD: '/tracking-page/pre-purchase-edd',
  BLACKLIST: '/tracking-page/blacklist',
  PLAN: '/plan',
  SETTINGS: '/settings',
};

export const embeddedNavLinksWithoutReview = [
  {
    label: 'Analytics',
    destination: PATH.ANALYTICS,
  },
  {
    label: 'Shipments',
    destination: PATH.SHIPMENT,
  },
  {
    label: 'Tracking Page',
    destination: PATH.TRACKING_PAGE,
  },

  {
    label: 'Notifications',
    destination: PATH.NOTIFICATIONS,
  },
  {
    label: 'Settings',
    destination: PATH.SETTINGS,
  },
  {
    label: 'Subscription Plans',
    destination: PATH.PLAN,
  },
  {
    label: 'Returns',
    destination: '/returns',
  },
];

// export const embeddedNavLinks = [
//   ...embeddedNavLinksWithoutReview,
//   {
//     label: 'Review Table',
//     destination: PATH.REVIEW_TABLE,
//   },
// ];
