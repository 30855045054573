import { MenuItemStyled } from '@/pages/TrackingPage/components/EDD/components/ModalTrackingPage/components/MenuItem/styled';
import { Button, IconProps } from '@shopify/polaris';

interface IProps {
  tone?: 'primary' | 'tertiary';
  onChecked?: () => void;
  checked?: boolean;
  actionIcon?: IconProps['source'];
  children: React.ReactNode;
  isHeader?: boolean;
  onAction?: () => void;
}
const MenuItem = ({ children, tone, actionIcon, isHeader, onAction }: IProps) => {
  return (
    <MenuItemStyled className="content-left-item" tone={tone} isHeader={isHeader}>
      {children}
      {actionIcon ? <Button icon={actionIcon} variant="plain" onClick={onAction} /> : null}
    </MenuItemStyled>
  );
};

export default MenuItem;
