export * from './storage';
export * from './link';
export * from './path';
export * from './api';
export * from './colors';
export * from './options';
export * from './upload';
export * from './file';
export * from './blacklisting';
export * from './location';
export * from './email';
export * from './environment';
export * from './sms';
export * from './tabs';
export * from './images';

export const OTElementId = {
  SaveBar: 'ot-save-bar',
};
