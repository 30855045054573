import BoldText from '@/components/BoldText';
import { BlockStack, Card, Divider, Layout } from '@shopify/polaris';

interface IProps {
  children: React.ReactNode;
  title: string | React.ReactNode;
  description?: string | React.ReactNode;
  isShowDivider?: boolean;
  notUseCard?: boolean;
}

const CustomLayoutOneThird = ({ children, title, description, isShowDivider, notUseCard }: IProps) => {
  return (
    <>
      {isShowDivider && <Divider />}
      <div className={isShowDivider ? 'mt-16 mb-16' : 'mb-16'}>
        <Layout>
          <Layout.Section variant="oneThird">
            <BlockStack gap="200">
              <BoldText>{title}</BoldText>
              {description}
            </BlockStack>
          </Layout.Section>
          {notUseCard ? (
            <Layout.Section>{children}</Layout.Section>
          ) : (
            <Layout.Section>
              <Card>{children}</Card>
            </Layout.Section>
          )}
        </Layout>
      </div>
    </>
  );
};

export default CustomLayoutOneThird;
