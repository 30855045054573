import { LookupPageStyled } from '@/pages/TrackingPage/components/EDD/components/ModalTrackingPage/components/PreviewContent/components/LookupPage/styled';
import TrackingForm from '@/pages/TrackingPage/components/EDD/components/ModalTrackingPage/components/PreviewContent/TrackingForm';
import { trackingPageMediaSelector } from '@/redux/features/trackingPageMedia.slice';
import { useSelector } from 'react-redux';

const LookupPage = () => {
  const trackingPageMediaData = useSelector(trackingPageMediaSelector);
  console.log('log_trackingPageMediaData: ', trackingPageMediaData?.tempData);
  return (
    <LookupPageStyled>
      <h1 className="header">Track your order</h1>
      <div className="form-container">
        <div className="lookup-content-left">
          <TrackingForm />
        </div>
        <div className="lookup-content-right">
          <div
            className="haft-image-container-top"
            style={{
              backgroundImage: `url(${trackingPageMediaData?.tempData?.asset1?.baseUrl}${trackingPageMediaData?.tempData?.asset1?.path})`,
            }}
          ></div>
          <div
            className="haft-image-container-bottom"
            style={{
              backgroundImage: `url(${trackingPageMediaData?.tempData?.asset2?.baseUrl}${trackingPageMediaData?.tempData?.asset2?.path})`,
            }}
          ></div>
          <div
            className="full-image-container"
            style={{
              backgroundImage: `url(${trackingPageMediaData?.tempData?.asset3?.baseUrl}${trackingPageMediaData?.tempData?.asset3?.path})`,
            }}
          ></div>
        </div>
      </div>
    </LookupPageStyled>
  );
};

export default LookupPage;
