import { CustomBanner } from '@/components';
import { PATH } from '@/constants';
import { AccountPlan } from '@/constants/enum';
import { calcPercent, capitalizeFirstLetter, handleSendMoreShipmentMessage } from '@/helpers';
import useConfirmationNavigate from '@/hooks/useConfirmationNavigate';
import { useGetUserPlanQuery } from '@/redux/api/api.caller';
import dashboardPageSlice, { haveClosedOverQuotaBannerSelector } from '@/redux/features/dashboard.slice';
import { accountSelector } from '@/redux/features/plan.slice';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ProgressPercent } from '../../config';

const OverQuotaBanner = () => {
  const navigate = useConfirmationNavigate();
  const dispatch = useDispatch();
  const haveClosedBanner = useSelector(haveClosedOverQuotaBannerSelector);
  const { plan } = useSelector(accountSelector);
  const { data: userPlanData } = useGetUserPlanQuery({});

  const progressInfo = useMemo(() => {
    if (userPlanData && userPlanData.data) {
      const { quota, credit } = userPlanData.data;

      return {
        percent: calcPercent(credit, Number(quota)),
        quota,
        credit,
      };
    }
    return null;
  }, [userPlanData]);

  const handleCloseBanner = () => {
    dispatch(dashboardPageSlice.actions.handleCloseOverQuotaBanner(true));
  };

  const handleUpgradeNow = () => {
    navigate(PATH.PLAN);
  };

  useEffect(() => {
    if (progressInfo && progressInfo.percent < ProgressPercent.Warning && haveClosedBanner) {
      dispatch(dashboardPageSlice.actions.handleCloseOverQuotaBanner(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progressInfo]);

  return progressInfo && progressInfo.percent >= ProgressPercent.Warning ? (
    (() => {
      const { credit, percent, quota } = progressInfo;
      const isNotCritical = percent < ProgressPercent.Critical;

      return (
        <CustomBanner
          maxHeight="15rem"
          isClose={isNotCritical && haveClosedBanner}
          tone={isNotCritical ? 'warning' : 'critical'}
          title={
            isNotCritical
              ? "Attention: You've consumed 80% of your monthly shipment quota"
              : `Exceeded monthly shipment quota of your ${capitalizeFirstLetter(plan)} plan`
          }
          action={{
            content: plan === AccountPlan.Enterprise ? 'Discuss for more shipments volumn' : 'Upgrade Now',
            onAction: plan === AccountPlan.Enterprise ? handleSendMoreShipmentMessage : handleUpgradeNow,
          }}
          onDismiss={isNotCritical ? handleCloseBanner : undefined}
        >
          {isNotCritical
            ? `Your monthly shipment count has reached 80% of your plan's limit of ${quota} shipments, currently at ${credit} shipments this month. To ensure uninterrupted tracking and greater flexibility, please considering an upgrade to your plan.`
            : `Your monthly shipment count has exceeded your plan's limit of ${quota} shipments, hitting ${credit} shipments this month. Upgrade to a higher plan now for uninterrupted tracking and more shipment flexibility.`}
        </CustomBanner>
      );
    })()
  ) : (
    <></>
  );
};

export default OverQuotaBanner;
