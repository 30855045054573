import { createSelector, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import {
  CRUD,
  // DisplayReview,
  GoogleTranslatePosition,
  Language,
  ProgressBar,
  ShippingInfo,
  TrackingMethods,
} from '@/constants/enum';
import { HSBColor } from '@shopify/polaris';
import { BlacklistReplaceWith, TrackingPageTabs, UpdatedBlacklistBtn } from '@/constants';
import { languages } from '@/pages/TrackingPage/components/Language/config';
import { IShippingMethod, ITransitTimes } from '@/types/edd';
import { ICourier } from '@/types/settings';
import { cleanObject } from '@/helpers';

interface LanguageConfig {
  language: Language;
  googleTranslateExtension: boolean;
  googleTranslatePosition: GoogleTranslatePosition;
}

export interface LanguageValue {
  trackingStatus: {
    courierNotSupported: string;
    delivered: string;
    exception: string;
    failedAttempt: string;
    expired: string;
    inTransit: string;
    informationReceived: string;
    ordered: string;
    outForDelivery: string;
    pending: string;
  };
  trackingForm: {
    trackYourOrder: string;
    orderId: string;
    email: string;
    phoneNumber?: string;
    or: string;
    trackingNumber: string;
    track: string;
    placeholderTrackingNumber: string;
    placeholderOrderId: string;
    placeholderEmail: string;
    placeholderPhoneNumber?: string;
    orderNotFound: string;
    orderNotFoundMessage?: string;
    emptyEmail?: string;
    invalidEmail?: string;
    emptyTrackingNumber?: string;
    emptyOrderName?: string;
    emptyPhoneNumber?: string;
  };
  trackingHistory: {
    shipmentInformation: string;
    shippingAddress: string;
    trackingNumber: string;
    shipmentHistory: string;
    carrier: string;
  };
  progressBar: {
    status: string;
  };
  orderDetails: {
    shipmentItems: string;
    product: string;
    totalPrice: string;
    itemSingular: string;
    itemPlural: string;
  };
  review: {
    buttonText: string;
    reviewTitle: string;
  };
  footer: {
    privacy: string;
    return: string;
    support: string;
    term: string;
  };
}

const englishConfig = languages[0];
export interface ILookAndFeel {
  chooseTheme: string;
  lookupOption: {
    method: TrackingMethods;
    isEnabledOrderId?: boolean;
    isEnabledTrackingNumber?: boolean;
    isEnabledEmail?: boolean;
    isEnabledPhoneNumber?: boolean;
    requireEmail: boolean;
    requireOrderId: boolean;
    requirePhoneNumber: boolean;
    requireTrackingNumber: boolean;
  };
  content: {
    shippingInfo: Array<ShippingInfo>;
    format: {
      dateFormat: string;
      timeFormat: string;
    };
    progressBar: Array<ProgressBar>;
  };
  style: {
    colorPicker: {
      mainColor: HSBColor;
      textColor: HSBColor;
      btnColor: HSBColor;
      btnNameColor: HSBColor;
    };
    btnRadius: number;
  };
}

interface ILanguage {
  languageConfig: LanguageConfig;
  value: LanguageValue;
  oldValue: {
    languageConfig: LanguageConfig;
    value: LanguageValue;
  };
  fieldsChange: Object;
  validation: Array<string>;
}

export interface IConfiguration {
  notInfoOrder: {
    isNoInfo: boolean;
    isNotFound: boolean;
    message: string;
  };

  customStatus: {
    isCustomStatus: boolean;
    statusName: string;
    activeAfter: string;
    icon: string;
  };
  productRecommendation: {
    enable: boolean;
    title: string;
    type: string;
    value: Array<string>;
  };
  // review: {
  //   settings: {
  //     isEnableReview: boolean;
  //     isDisabledDisplayReview: boolean;
  //     displayReviewOption: Array<DisplayReview>;
  //   };
  //   question_1: {
  //     value: string;
  //     oneStarTitle: string;
  //     fiveStarTitle: string;
  //     display: boolean;
  //     minReason: string;
  //     displayReasons: boolean;
  //     reasons: Array<string>;
  //   };
  //   question_2: {
  //     value: string;
  //     oneStarTitle: string;
  //     fiveStarTitle: string;
  //     display: boolean;
  //   };
  //   question_3: {
  //     value: string;
  //     oneStarTitle: string;
  //     fiveStarTitle: string;
  //     display: boolean;
  //   };
  //   additionalComment: { value: string; display: boolean };
  //   thankMessage: { value: string; display: boolean };
  // };
  footerLink: {
    facebook: string;
    twitter: string;
    instagram: string;
    tiktok: string;
    pinterest: string;
    support: string;
    termPage: string;
    returnPage: string;
    privacyPage: string;
    isEnabledFooterLink?: boolean;
  };
  additionalContent: {
    textAbove?: string;
    textBelow?: string;
    htmlTop?: string;
    htmlBottom?: string;
    customCss?: string;
  };
}

export interface IBlacklisting {
  updatedBlacklistBtn: UpdatedBlacklistBtn;
  isEnableBtn: boolean;
  replaceWith: BlacklistReplaceWith;
  areasToBeReplaced: {
    selected: Array<string>;
    search: string;
  };
  areaToReplaceWith: {
    country: { selected: Array<string>; search: string };
    city: { selected: Array<string>; search: string };
  };
}

export interface IEstimatedDeliveryDate {
  isEnableBtn: number;
  orderDescription: {
    cutoffTime: string;
    timezone: string;
    processingTime: string;
    businessDays: number[];
  };
  shippingZone: {
    countriesCode: Array<string>;
  };
  shippingMethod: {
    modal: {
      type: CRUD;
      isActive: boolean;
      isCollapse: boolean;
      id?: number;
    };
    methodInfo: IShippingMethod[];
    search: string;
    from: string;
    to: string;
    courier: Array<string>;
    courierList: ICourier[];
  };
  transitTimes: ITransitTimes[];
}

export interface ITrackingPageState {
  tabSelected: TrackingPageTabs;
  tabConfigurationSelected: string;
  trackingPage: {
    isLoading: boolean;
    value: {
      lookAndFeel: ILookAndFeel;
      configuration: IConfiguration;
    };
    oldValue: {
      lookAndFeel: ILookAndFeel;
      configuration: IConfiguration;
    };
    fieldsChange: any;
    validation: Array<string>;
    isOpenModalTrackingPage?: boolean;
  };
  language: ILanguage;
  blacklisting: {
    value: IBlacklisting;
    oldValue: IBlacklisting;
    fieldsChange: any;
    isLoading?: boolean;
  };
  edd: {
    value: IEstimatedDeliveryDate;
    oldValue: IEstimatedDeliveryDate;
    fieldsChange: any;
    isLoading?: boolean;
  };
}
// Define the initial state using that type
const initTrackingPageValue: {
  lookAndFeel: ILookAndFeel;
  configuration: IConfiguration;
} = {
  lookAndFeel: {
    chooseTheme: '1',
    lookupOption: {
      method: TrackingMethods.Both,
      requireEmail: false,
      requireOrderId: false,
      requirePhoneNumber: false,
      requireTrackingNumber: false,
    },
    content: {
      shippingInfo: [
        ShippingInfo.Map,
        ShippingInfo.OrderDetail,
        ShippingInfo.ProgressBar,
        ShippingInfo.TrackingCompany,
        ShippingInfo.TrackingLogs,
      ],
      progressBar: [
        ProgressBar.Delivered,
        ProgressBar.InTransit,
        ProgressBar.OrderReady,
        ProgressBar.Ordered,
        ProgressBar.OutForDelivery,
      ],
      format: {
        dateFormat: 'MMMM Do YYYY',
        timeFormat: 'h:mm:ss a',
      },
    },
    style: {
      colorPicker: {
        mainColor: {
          hue: 1,
          brightness: 1,
          saturation: 1,
        },
        btnColor: {
          hue: 65,
          brightness: 1,
          saturation: 1,
        },
        btnNameColor: {
          hue: 120,
          brightness: 120,
          saturation: 120,
        },
        textColor: {
          hue: 120,
          brightness: 0,
          saturation: 1,
        },
      },
      btnRadius: 16,
    },
  },
  configuration: {
    notInfoOrder: {
      isNoInfo: false,
      isNotFound: false,
      message: '',
    },
    customStatus: {
      isCustomStatus: false,
      statusName: '',
      activeAfter: '1',
      icon: '1',
    },
    productRecommendation: {
      enable: false,
      title: '',
      type: 'all',
      value: [],
    },
    // review: {
    //   settings: {
    //     isEnableReview: false,
    //     isDisabledDisplayReview: true,
    //     displayReviewOption: [
    //       DisplayReview.StarRating,
    //       DisplayReview.Comment,
    //       DisplayReview.CustomerName,
    //       DisplayReview.ReviewTime,
    //       DisplayReview.Courier,
    //     ],
    //   },
    //   question_1: {
    //     value: 'How would you rate us?',
    //     oneStarTitle: '1',
    //     fiveStarTitle: '2',
    //     display: true,
    //     minReason: '2',
    //     reasons: ['Well packed', 'Fast delivers'],
    //     displayReasons: true,
    //   },
    //   question_2: {
    //     value: 'How likely would you recommend us to your friends and family?',
    //     oneStarTitle: '1',
    //     fiveStarTitle: '2',
    //     display: true,
    //   },
    //   question_3: {
    //     value: 'Your optional question!',
    //     oneStarTitle: '1',
    //     fiveStarTitle: '2',
    //     display: true,
    //   },

    //   additionalComment: { value: 'Share with us your experience and feelings', display: true },
    //   thankMessage: { value: 'Thank you for review message!', display: true },
    // },
    footerLink: {
      facebook: '',
      twitter: '',
      instagram: '',
      tiktok: '',
      pinterest: '',
      support: '',
      returnPage: '',
      termPage: '',
      privacyPage: '',
    },
    additionalContent: {
      customCss: '',
      htmlBottom: '',
      htmlTop: '',
      textAbove: '',
      textBelow: '',
    },
  },
};
const initLanguageConfig: LanguageConfig = {
  language: Language.Default,
  googleTranslateExtension: true,
  googleTranslatePosition: GoogleTranslatePosition.LeftTop,
};
const initLanguageValue: LanguageValue = {
  trackingStatus: {
    ...englishConfig.translations.trackingStatus,
  },
  trackingForm: {
    ...englishConfig.translations.trackingForm,
  },
  trackingHistory: {
    ...englishConfig.translations.trackingHistory,
  },
  footer: {
    ...englishConfig.translations.footer,
  },
  orderDetails: {
    ...englishConfig.translations.orderDetails,
  },
  progressBar: {
    ...englishConfig.translations.progressBar,
  },
  review: {
    ...englishConfig.translations.review,
  },
};
const initBlacklistingValue: IBlacklisting = {
  updatedBlacklistBtn: UpdatedBlacklistBtn.Enable,
  isEnableBtn: false,
  replaceWith: BlacklistReplaceWith.EmptyLocation,
  areasToBeReplaced: {
    selected: [],
    search: '',
  },
  areaToReplaceWith: {
    country: { search: '', selected: [] },
    city: { search: '', selected: [] },
  },
};
const initEDDValue: IEstimatedDeliveryDate = {
  isEnableBtn: 0,
  orderDescription: {
    cutoffTime: '',
    timezone: '',
    processingTime: '',
    businessDays: [],
  },
  shippingZone: {
    countriesCode: [],
  },
  shippingMethod: {
    modal: { type: CRUD.Read, isActive: false, isCollapse: false },
    methodInfo: [],
    search: '',
    from: '4',
    to: '7',
    courier: [],
    courierList: [],
  },
  transitTimes: [],
};
const initialState: ITrackingPageState = {
  tabSelected: TrackingPageTabs.LookAndFeel,
  tabConfigurationSelected: 'blacklist',
  trackingPage: {
    isLoading: false,
    value: initTrackingPageValue,
    oldValue: initTrackingPageValue,
    fieldsChange: {},
    validation: [],
  },

  language: {
    fieldsChange: {},
    value: initLanguageValue,
    languageConfig: initLanguageConfig,
    oldValue: {
      value: initLanguageValue,
      languageConfig: initLanguageConfig,
    },
    validation: [],
  },

  blacklisting: {
    value: initBlacklistingValue,
    oldValue: initBlacklistingValue,
    fieldsChange: {},
  },
  edd: {
    value: initEDDValue,
    oldValue: initEDDValue,
    fieldsChange: {},
  },
};

const trackingPageSlice = createSlice({
  name: 'trackingPage',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    handleTabSelected: (state, action: PayloadAction<TrackingPageTabs>) => {
      state.tabSelected = action.payload;
    },
    handleTabConfigurationSelected: (state, action: PayloadAction<string>) => {
      state.tabConfigurationSelected = action.payload;
    },
    handleTrackingPageValue: (state, action: PayloadAction<{ lookAndFeel: ILookAndFeel; configuration: IConfiguration }>) => {
      state.trackingPage.value = action.payload;
    },
    handleTrackingPageOldValue: (state, action: PayloadAction<{ lookAndFeel: ILookAndFeel; configuration: IConfiguration }>) => {
      state.trackingPage.oldValue = action.payload;
    },
    handleTrackingPageFieldsChange: (state, action: PayloadAction<Object>) => {
      state.trackingPage.fieldsChange = cleanObject(action.payload);
    },
    handleTrackingPageIsloading: (state, action: PayloadAction<boolean>) => {
      state.trackingPage.isLoading = action.payload;
    },
    handleIsOpenModalTrackingPage: (state, action: PayloadAction<boolean>) => {
      state.trackingPage.isOpenModalTrackingPage = action.payload;
    },

    handleLanguageConfig: (state, action: PayloadAction<LanguageConfig>) => {
      state.language.languageConfig.language = action.payload.language;
      state.language.languageConfig.googleTranslateExtension = action.payload.googleTranslateExtension;
      state.language.languageConfig.googleTranslatePosition = action.payload.googleTranslatePosition;
    },
    handleLanguageValue: (state, action: PayloadAction<LanguageValue>) => {
      state.language.value = action.payload;
    },
    handleLanguageOldValue: (state, action: PayloadAction<{ value: LanguageValue; languageConfig: LanguageConfig }>) => {
      state.language.oldValue = action.payload;
    },
    handleLanguageFieldsChange: (state, action: PayloadAction<Object>) => {
      state.language.fieldsChange = cleanObject(action.payload);
    },

    handleChangeBlacklistingValue: (state, action: PayloadAction<IBlacklisting>) => {
      state.blacklisting.value = action.payload;
    },
    handleChangeBlacklistingOldValue: (state, action: PayloadAction<IBlacklisting>) => {
      state.blacklisting.oldValue = action.payload;
    },
    handleChangeBlacklistingFieldsChange: (state, action: PayloadAction<Object>) => {
      state.blacklisting.fieldsChange = cleanObject(action.payload);
    },
    handleChangeBlacklistingIsLoading: (state, action: PayloadAction<boolean>) => {
      state.blacklisting.isLoading = action.payload;
    },

    handleChangeEDDValue: (state, action: PayloadAction<IEstimatedDeliveryDate>) => {
      state.edd.value = action.payload;
    },
    handleChangeEDDOldValue: (state, action: PayloadAction<IEstimatedDeliveryDate>) => {
      state.edd.oldValue = action.payload;
    },
    handleChangeEDDFieldsChange: (state, action: PayloadAction<Object>) => {
      state.edd.fieldsChange = cleanObject(action.payload);
    },
    handleChangeEDDIsLoading: (state, action: PayloadAction<boolean>) => {
      state.edd.isLoading = action.payload;
    },
    handleSetEddTransitTimeRule: (state, action: PayloadAction<{ methodInfo: IShippingMethod[]; countriesCode: string[] }>) => {
      state.edd.value.shippingMethod = { ...state.edd.value.shippingMethod, methodInfo: action.payload.methodInfo };
      state.edd.value.shippingZone = { ...state.edd.value.shippingZone, countriesCode: action.payload.countriesCode };
      state.edd.oldValue.shippingMethod = { ...state.edd.oldValue.shippingMethod, methodInfo: action.payload.methodInfo };
      state.edd.oldValue.shippingZone = { ...state.edd.oldValue.shippingZone, countriesCode: action.payload.countriesCode };
    },

    handleChangeValidation: (state, action: PayloadAction<Array<string>>) => {
      state.trackingPage.validation = action.payload;
    },
    handleChangeValidationLanguage: (state, action: PayloadAction<Array<string>>) => {
      state.language.validation = action.payload;
    },
  },
});

// Other code such as selectors can use the imported `RootState` type
export const tabSelectedSelector = createSelector(
  (state: RootState) => state.trackingPage,
  (state) => state.tabSelected,
);
export const tabConfigurationSelectedSelector = createSelector(
  (state: RootState) => state.trackingPage,
  (state) => state.tabConfigurationSelected,
);

export const trackingPageSelector = createSelector(
  (state: RootState) => state.trackingPage,
  (state) => state.trackingPage,
);

export const trackingPageIsLoadingSelector = createSelector(
  (state: RootState) => state.trackingPage,
  (state) => state.trackingPage.isLoading,
);

export const languageSelector = createSelector(
  (state: RootState) => state.trackingPage,
  (state) => state.language,
);

export const blacklistingSelector = createSelector(
  (state: RootState) => state.trackingPage,
  (state) => state.blacklisting,
);

export const eddSelector = createSelector(
  (state: RootState) => state.trackingPage,
  (state) => state.edd,
);

export const isLoadingEddSelector = createSelector(
  (state: RootState) => state.trackingPage,
  (state) => state.edd.isLoading,
);

export default trackingPageSlice;
