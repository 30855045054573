import { Text, TextProps } from '@shopify/polaris';
import { memo } from 'react';

interface IProps extends Omit<TextProps, 'as'> {
  children: React.ReactNode;
  className?: string;
}

const RegularText = ({ children, variant, className, ...textProps }: IProps) => {
  return (
    <div className={`d-inline-block ${className || ''}`}>
      <Text as="span" variant={variant || 'bodyMd'} {...textProps}>
        {children}
      </Text>
    </div>
  );
};
export default memo(RegularText);
