import { CustomCard } from '@/components';
import OneThirdCardLayout from '@/components/CustomCard/OneThirdCardLayout';
import { images, PATH } from '@/constants';
import { checkDirtyField } from '@/helpers';
import useConfirmationNavigate from '@/hooks/useConfirmationNavigate';
import trackingPageSlice, { eddSelector } from '@/redux/features/trackingPage.slice';
import { Button, ButtonGroup, Text } from '@shopify/polaris';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const EstimatedDeliveryDate = () => {
  const navigate = useConfirmationNavigate();
  const dispatch = useDispatch();
  const { value: edd, oldValue, fieldsChange } = useSelector(eddSelector);

  const eddBtnText = useMemo(() => {
    if (edd.isEnableBtn) {
      return 'Disable';
    }
    return 'Enable';
  }, [edd.isEnableBtn]);

  const handleChangeEddValue = (key: 'isEnableBtn', value: number) => {
    dispatch(
      trackingPageSlice.actions.handleChangeEDDValue({
        ...edd,
        [key]: value,
      }),
    );
    const { dirtyField } = checkDirtyField({ isEnableBtn: oldValue.isEnableBtn }, { isEnableBtn: value });
    dispatch(trackingPageSlice.actions.handleChangeEDDFieldsChange({ ...fieldsChange, enabled: dirtyField?.isEnableBtn }));
  };

  return (
    <CustomCard title="Estimated delivery date" noHeaderBorder noHeaderPaddingBottom>
      <div className="d-flex flex-column gap-16">
        <OneThirdCardLayout
          padding="0"
          title="Post-purchase EDD"
          contentRight={
            <div className="w-full text-right">
              <img src={images.postPurchaseEDD} alt="tracking page editor" className="image-content-right" />
            </div>
          }
        >
          <Text variant="bodyMd" as="p">
            Show EDD after purchase on the Tracking page to keep customers informed and reduce inquiries.
          </Text>
          <div className="mt-16">
            <ButtonGroup>
              <Button
                variant={edd.isEnableBtn ? undefined : 'primary'}
                onClick={() => handleChangeEddValue('isEnableBtn', Number(!edd.isEnableBtn))}
              >
                {eddBtnText}
              </Button>
              <Button onClick={() => navigate(PATH.POST_PURCHASE_EDD)}>Settings</Button>
            </ButtonGroup>
          </div>
        </OneThirdCardLayout>
        <OneThirdCardLayout
          padding="0"
          title="Pre-purchase EDD"
          contentRight={
            <div className="w-full text-right">
              <img src={images.prePurchaseEDD} alt="tracking page editor" className="image-content-right" />
            </div>
          }
        >
          <Text variant="bodyMd" as="p">
            Display EDD on Product page, Checkout to set clear expectations and boost purchase confidence.
          </Text>
          <div className="mt-16">
            <Button
              url="https://apps.shopify.com/omega-estimated-shipping-date?utm_campaign=crs&utm_source=order-tracking&utm_medium=inapp"
              external
            >
              Set up now
            </Button>
          </div>
        </OneThirdCardLayout>
      </div>
    </CustomCard>
  );
};

export default EstimatedDeliveryDate;
