import { PolarisBreakPoint } from '@/constants/enum';
import styled from 'styled-components';

export const TrackingPageStyled = styled.div`
  .Polaris-Page {
    @media only screen and (min-width: ${PolarisBreakPoint.LG}) {
      &.Polaris-Page--fullWidth {
        max-width: 90rem;
      }
    }

    .Polaris-Page {
      .Polaris-Page__Content {
        padding: 0;
      }
      &.Polaris-Page--fullWidth {
        padding: 0;

        // css for preview is sticky
        .Polaris-ShadowBevel:first-child {
          overflow: inherit !important;
          .Polaris-Box {
            overflow: inherit !important;
            border-radius: 0.75rem;
          }
        }
      }
    }
    .Polaris-ShadowBevel::before {
      mix-blend-mode: normal;
    }
  }
`;

export const TrackingPageContainerStyled = styled.div`
  display: flex;
  gap: 16px;
  flex-direction: column;
  .image-content-right {
    width: 100%;
    height: 100%;
  }
`;
