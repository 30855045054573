import styled from 'styled-components';

export const LookupPageStyled = styled.div`
  padding: 0 2rem;
  .form-container {
    display: flex;
    gap: 1rem;
  }
  h1.header {
    font-size: 2rem;
    font-weight: bold;
    line-height: 1.5;
    margin-bottom: 1rem;
  }
  .lookup-content-left {
    width: 50%;
  }

  .lookup-content-right {
    width: 50%;

    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 16px;
    grid-row-gap: 12px;

    .haft-image-container-top {
      grid-area: 1 / 1 / 2 / 2;
      border-radius: 12px;
    }
    .haft-image-container-bottom {
      grid-area: 2 / 1 / 3 / 2;
      border-radius: 12px;
    }
    .full-image-container {
      grid-area: 1 / 2 / 3 / 3;
      border-radius: 12px;
      min-height: 50vh;
    }
    .full-image-container,
    .haft-image-container-bottom .haft-image-container-top {
      background-size: cover;
      background-position: 50%;
      background-repeat: no-repeat;
    }
  }

  @media screen and (max-width: 1100px) {
    flex-direction: column;
    .lookup-content-right {
      width: 100%;
    }
  }
`;
