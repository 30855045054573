import { AreaToReplaceWithType } from '@/constants';
import { useGetListCitiesQuery, useGetListCountriesQuery } from '@/redux/api/api.caller';
import trackingPageSlice, { blacklistingSelector } from '@/redux/features/trackingPage.slice';
import { Autocomplete, Icon, Tag } from '@shopify/polaris';
import { SearchIcon } from '@shopify/polaris-icons';
import { useDispatch, useSelector } from 'react-redux';
import { StyledTextField } from '../../styled';
import { memo, useCallback, useState } from 'react';
import { OptionDescriptor } from '@shopify/polaris/build/ts/src/types';
import { checkDirtyField, cleanObject } from '@/helpers';

interface AreaToReplaceWithTextFieldProps {
  type: AreaToReplaceWithType;
}

const AreaToReplaceWithTextField = ({ type }: AreaToReplaceWithTextFieldProps) => {
  const dispatch = useDispatch();
  const { value: blacklisting, oldValue, fieldsChange } = useSelector(blacklistingSelector);
  const [isFocusAutoComplete, setIsFocusAutoComplete] = useState<boolean>(false);
  const countriesData = useGetListCountriesQuery(undefined, {
    skip: type === AreaToReplaceWithType.City || !isFocusAutoComplete,
  });
  const citiesData = useGetListCitiesQuery(
    { country: blacklisting.areaToReplaceWith.country.selected.toString() },
    {
      skip:
        type === AreaToReplaceWithType.Country || !blacklisting.areaToReplaceWith.country.selected.length || !isFocusAutoComplete,
    },
  );
  const isRemovedCountryTag = type === AreaToReplaceWithType.City && !blacklisting.areaToReplaceWith.country.selected.length;

  const transformedOptions = useCallback(
    (list: Array<string>): OptionDescriptor[] => {
      if (list.length) {
        const initOptions = list.map((option) => ({
          value: option,
          label: option,
        }));
        return initOptions.filter((option) =>
          option.value.toLowerCase().includes(blacklisting.areaToReplaceWith[type].search.toLowerCase()),
        );
      }
      return [];
    },
    [blacklisting.areaToReplaceWith, type],
  );

  const handleChangeSearch = (value: string) => {
    dispatch(
      trackingPageSlice.actions.handleChangeBlacklistingValue({
        ...blacklisting,
        areaToReplaceWith: {
          ...blacklisting.areaToReplaceWith,
          [type]: { ...blacklisting.areaToReplaceWith[type], search: value },
        },
      }),
    );
  };

  const handleSelectAutocomplete = (tags: Array<string>) => {
    const updatedAreaToReplaceWith =
      type === AreaToReplaceWithType.Country
        ? { ...blacklisting.areaToReplaceWith, country: { search: '', selected: tags }, city: { search: '', selected: [] } }
        : { ...blacklisting.areaToReplaceWith, city: { search: '', selected: tags } };
    dispatch(
      trackingPageSlice.actions.handleChangeBlacklistingValue({
        ...blacklisting,
        areaToReplaceWith: updatedAreaToReplaceWith,
      }),
    );
    const { dirtyField } = checkDirtyField(
      {
        areaToReplaceWith: {
          country: oldValue.areaToReplaceWith.country.selected.toString(),
          city: oldValue.areaToReplaceWith.city.selected.toString(),
        },
      },
      {
        areaToReplaceWith: cleanObject({
          country: updatedAreaToReplaceWith.country.selected.toString(),
          city: updatedAreaToReplaceWith.city.selected.toString(),
        }),
      },
    );
    dispatch(
      trackingPageSlice.actions.handleChangeBlacklistingFieldsChange({
        ...fieldsChange,
        areaToReplaceWith: dirtyField?.areaToReplaceWith ? dirtyField?.areaToReplaceWith : undefined,
      }),
    );
  };

  const handleRemoveTag = () => {
    handleSelectAutocomplete([]);
  };

  const customTextField = (
    <div onFocus={() => setIsFocusAutoComplete(true)}>
      <Autocomplete.TextField
        label={type === AreaToReplaceWithType.Country ? 'Countries' : 'Cities'}
        placeholder={
          blacklisting.areaToReplaceWith[type].selected.length
            ? blacklisting.areaToReplaceWith[type].selected.toString()
            : 'Search and replace with city, country'
        }
        disabled={isRemovedCountryTag}
        helpText={isRemovedCountryTag && 'You need to pick which locations should be replaced'}
        prefix={<Icon source={SearchIcon} tone="base" />}
        autoComplete="off"
        value={blacklisting.areaToReplaceWith[type].search}
        onChange={handleChangeSearch}
      />
    </div>
  );

  return (
    <StyledTextField>
      <Autocomplete
        listTitle="Suggested Tags"
        allowMultiple={false}
        options={
          type === AreaToReplaceWithType.Country
            ? transformedOptions(countriesData.data?.data || [])
            : transformedOptions(isRemovedCountryTag ? [] : citiesData.data?.data || [])
        }
        selected={blacklisting.areaToReplaceWith[type].selected}
        textField={customTextField}
        onSelect={handleSelectAutocomplete}
        loading={type === AreaToReplaceWithType.Country ? countriesData.isLoading : citiesData.isLoading}
      />

      <div className="tag-list">
        {blacklisting.areaToReplaceWith[type].selected &&
          blacklisting.areaToReplaceWith[type].selected.length > 0 &&
          blacklisting.areaToReplaceWith[type].selected.map((tag: string) => (
            <Tag key={tag} onRemove={handleRemoveTag}>
              {tag}
            </Tag>
          ))}
      </div>
    </StyledTextField>
  );
};

export default memo(AreaToReplaceWithTextField);
