import { CustomCard, CustomCollapsible } from '@/components';
import CustomLayoutOneThird from '@/components/CustomLayoutOneThird';
import { REST_OF_WORLD, continentCode } from '@/constants';
import { ShippingZoneType } from '@/constants/edd';
import { AccountPlan } from '@/constants/enum';
import { countDuplicateValueInArr, getCountriesByContinent, getCountryByCode, isLockFeature } from '@/helpers';
import { useGetEDDSettingsQuery } from '@/redux/api/api.caller';
import { accountSelector, christmasBannerSelector } from '@/redux/features/plan.slice';
import trackingPageSlice, { eddSelector } from '@/redux/features/trackingPage.slice';
import { Checkbox, Divider, SkeletonBodyText, Text } from '@shopify/polaris';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StyledShippingZone } from './styled';

const ShippingZone = ({ id }: { id?: string }) => {
  const dispatch = useDispatch();
  const { value: edd } = useSelector(eddSelector);
  const { data, isLoading } = useGetEDDSettingsQuery();
  const isRestOfWorld = edd.shippingZone.countriesCode.toString() === REST_OF_WORLD;
  const plan = useSelector(accountSelector);
  const { formattedEndDate } = useSelector(christmasBannerSelector);
  const isActiveChristmasFeature = new Date() < new Date(formattedEndDate);
  const isLockedFeature =
    isLockFeature([AccountPlan.Starter, AccountPlan.Basic]) && !plan.isOldPaidUser && !isActiveChristmasFeature;

  const checkedCountryCodeList = useMemo(() => {
    let mergedArr = edd.transitTimes.map(({ shippingDestinationCountry }) => shippingDestinationCountry).flat(1);
    if (id) {
      const transitTimeById = data?.data?.transitTime.find((item) => item.id === Number(id));
      const isNotCheckCountryInDB = (country: string) =>
        !transitTimeById?.shippingDestinationCountry.some((item) => item.toLowerCase() === country.toLowerCase());
      mergedArr = mergedArr.filter((country) => isNotCheckCountryInDB(country));
    }
    return mergedArr;
  }, [data?.data?.transitTime, edd.transitTimes, id]);

  const isCheckedCountry = (country: string) =>
    edd.shippingZone.countriesCode.some((code) => code.toLowerCase() === country.toLowerCase());

  const isDisabledCountry = (country: string) =>
    checkedCountryCodeList.some((code) => code.toLowerCase() === country.toLowerCase());

  const isCheckedContinent = (continent: string) => {
    const continentByCountry = edd.shippingZone.countriesCode.map((country) => getCountryByCode(country)?.continent || '');
    return continentByCountry.some((code) => code.toLowerCase() === continent.toLowerCase());
  };

  const isSelectedAll = (continent: string) => {
    const countryCodeByContinent = getCountriesByContinent(continent).map((country) => country.code);
    const continentList = checkedCountryCodeList.map((code) => getCountryByCode(code)?.continent || REST_OF_WORLD);
    const countryQuantityByContinent = countDuplicateValueInArr(continentList);
    return !isCheckedContinent(continent) && countryQuantityByContinent[continent] === countryCodeByContinent.length;
  };

  const handleChangeCheckbox = (value: boolean, key: string, type: ShippingZoneType) => {
    const codeByType = key.replaceAll(type, '');
    let updatedValue;
    if (type === ShippingZoneType.Country) {
      updatedValue = value
        ? [...edd.shippingZone.countriesCode, codeByType]
        : edd.shippingZone.countriesCode.filter((day) => day !== codeByType);
    } else {
      const countryCodeByContinent = getCountriesByContinent(codeByType).map((country) => country.code);
      const notDisabledCountry = countryCodeByContinent.filter((country) => !isDisabledCountry(country));
      updatedValue = value
        ? [...edd.shippingZone.countriesCode, ...notDisabledCountry]
        : edd.shippingZone.countriesCode.filter(
            (country) => !notDisabledCountry.some((disableCountry) => disableCountry.toLowerCase() === country.toLowerCase()),
          );
    }
    dispatch(
      trackingPageSlice.actions.handleChangeEDDValue({
        ...edd,
        shippingZone: {
          ...edd.shippingZone,
          countriesCode: updatedValue,
        },
      }),
    );
  };

  return (
    <StyledShippingZone>
      <CustomLayoutOneThird
        notUseCard
        title="Shipping zone"
        description={<p>Excluding non-business days from estimated delivery date</p>}
      >
        <CustomCard title="" noHeaderPadding noHeaderBorder>
          {isLoading ? (
            <SkeletonBodyText lines={15} />
          ) : (
            <>
              <div className="rest-of-world">
                <Checkbox
                  label={
                    <Text as="p" variant="bodyMd">
                      {REST_OF_WORLD}
                    </Text>
                  }
                  checked
                  disabled
                />
              </div>
              {!isRestOfWorld && (
                <>
                  <Divider />
                  {Object.entries(continentCode).map(([key, value], index) => {
                    return (
                      <div className="checkbox-item" key={key}>
                        <CustomCollapsible
                          paddingTitle="0.25rem 0.5rem"
                          paddingChildren="0.25rem"
                          open={false}
                          variant="bodyMd"
                          title={
                            <Checkbox
                              id={`${ShippingZoneType.Continent}${key}`}
                              label={value}
                              checked={isSelectedAll(key) || isCheckedContinent(key)}
                              disabled={isSelectedAll(key) || isLockedFeature}
                              onChange={(checked, id) => handleChangeCheckbox(checked, id, ShippingZoneType.Continent)}
                            />
                          }
                          isNotShowDivider={index === Object.entries(continentCode).length - 1}
                        >
                          <div className="collapsible-content">
                            {getCountriesByContinent(key).map((country) => (
                              <Checkbox
                                key={country.code}
                                id={`${ShippingZoneType.Country}${country.code}`}
                                label={country.name}
                                checked={isDisabledCountry(country.code) || isCheckedCountry(country.code)}
                                disabled={isDisabledCountry(country.code) || isLockedFeature}
                                onChange={(checked, id) => handleChangeCheckbox(checked, id, ShippingZoneType.Country)}
                              />
                            ))}
                          </div>
                        </CustomCollapsible>
                      </div>
                    );
                  })}
                </>
              )}
            </>
          )}
        </CustomCard>
      </CustomLayoutOneThird>
    </StyledShippingZone>
  );
};

export default ShippingZone;
