import { Badge, Button, Icon, SkeletonBodyText, Text } from '@shopify/polaris';
import { SubscriptionPlansProps } from '../../config';
import { AccountPlan, Subscription } from '@/constants/enum';
import { CircleRightIcon, SandboxIcon, CheckIcon } from '@shopify/polaris-icons';
import { StyledPlanCard } from './styled';
import { memo } from 'react';
import { handleSendMoreShipmentMessage } from '@/helpers';

interface PlanCardProps extends SubscriptionPlansProps {
  price: { monthly: string; annually: string };
  subscription: Subscription;
  isLoading?: boolean;
  isActivePlan?: boolean;
  handleUpdatePlan?: () => void;
  isBtnLoading?: boolean;
}

const PlanCard = ({
  benefits,
  description,
  displayPlan,
  plan,
  prePlan,
  quota,
  subscription,
  price,
  isLoading = false,
  isActivePlan = false,
  handleUpdatePlan,
  isBtnLoading,
}: PlanCardProps) => {
  return (
    <StyledPlanCard>
      {isLoading ? (
        <SkeletonBodyText lines={26} />
      ) : (
        <>
          <div>
            <div className="plan-title">
              <Text variant="headingSm" as="h6">
                {displayPlan}
              </Text>
              {plan === AccountPlan.Professional && <Badge tone="critical">Best seller</Badge>}
            </div>
            <div className="mt-8">
              <Text variant="bodySm" as="p">
                {description}
              </Text>
            </div>
            <div className="mt-16" style={subscription === Subscription.Annually ? { display: 'flex' } : {}}>
              <Text as="h3" variant="headingLg">
                {Number(price[subscription]) === 0 ? 'Free' : `$${price[subscription]}`}
              </Text>
              {subscription === Subscription.Annually && plan !== AccountPlan.Starter && (
                <div className="ml-8">
                  <Text as="p" textDecorationLine="line-through" variant="bodySm" tone="critical">
                    {`$${(Number(price.monthly) * 12).toFixed(1)}`}
                  </Text>
                </div>
              )}
            </div>
            <div className="mt-8">
              <Text variant="bodySm" as="p">
                {subscription === Subscription.Monthly ? 'monthly' : 'yearly'}
              </Text>
            </div>
            <div className="mt-16 account-benefit">
              <Icon source={CheckIcon} tone="success" />
              <div className="ml-8">
                <Text variant="bodySm" as="p">
                  <b> {quota} </b> shipments/month
                </Text>
              </div>
            </div>
            {prePlan && (
              <div className="account-benefit">
                <Icon source={SandboxIcon} tone="caution" />
                <div className="ml-8">
                  <Text variant="headingSm" as="h6">
                    All in {prePlan}, plus:
                  </Text>
                </div>
              </div>
            )}
            {benefits.map((contentItem, index) => {
              return (
                <div key={index} className="account-benefit">
                  <div>
                    <Icon source={CheckIcon} tone="success" />
                  </div>
                  <div className="ml-8">
                    <Text variant="bodySm" as="p">
                      {contentItem}
                    </Text>
                  </div>
                </div>
              );
            })}
            {plan === AccountPlan.Enterprise && (
              <div className="discuss-to-cs" onClick={handleSendMoreShipmentMessage}>
                <Button variant="monochromePlain" icon={CircleRightIcon}>
                  Discuss for more shipments volumn
                </Button>
              </div>
            )}
          </div>
          <div className="account-plan-details-btn">
            {isActivePlan ? (
              <Badge progress="complete" tone="success">
                Your plan
              </Badge>
            ) : (
              <Button onClick={handleUpdatePlan} variant="primary" loading={isBtnLoading}>
                Choose plan
              </Button>
            )}
          </div>
        </>
      )}
    </StyledPlanCard>
  );
};

export default memo(PlanCard);
