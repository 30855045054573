export const footerLinkConfig = {
  policy: [
    {
      key: 'support',
      title: 'Support contact',
      placeHolder: 'Email or URL',
    },
    {
      key: 'returnPage',
      title: 'Returns',
      placeHolder: 'https://www.store.com/return',
    },
    {
      key: 'termPage',
      title: 'Terms page',
      placeHolder: 'https://www.store.com/terms',
    },
    {
      key: 'privacyPage',
      placeHolder: 'https://www.store.com/privacy',
      title: 'Privacy page',
    },
  ],
  social: [
    {
      key: 'facebook',
      title: 'Facebook',
      placeHolder: 'https://www.facebook.com',
    },
    {
      key: 'twitter',
      title: 'Twitter (X)',
      placeHolder: 'https://www.twitter.com',
    },
    {
      key: 'instagram',
      title: 'Instagram',
      placeHolder: 'https://www.instagram.com',
    },
    {
      key: 'tiktok',
      title: 'Tiktok',
      placeHolder: 'https://www.tiktok.com',
    },
    {
      key: 'pinterest',
      title: 'Pinterest',
      placeHolder: 'https://www.pinterest.com',
    },
  ],
};
