import { CustomCard, CustomCollapsible, UpgradeBanner } from '@/components';
import ControlBar from '@/components/ControlBar';
import { AccountPlan, Language } from '@/constants/enum';
import {
  camelCaseToStartCase,
  camelToSnakeCase,
  checkDirtyField,
  isUndefinedArray,
  renderErrorValue,
  splitObjects,
} from '@/helpers';
import { isLockFeature } from '@/helpers/feature';
import { handleToastMutation } from '@/helpers/toast';
import { useLazyGetTranslationQuery, useUpdateTranslationMutation } from '@/redux/api/api.caller';
import commonSlice from '@/redux/features/common.slice';
import { accountSelector, christmasBannerSelector } from '@/redux/features/plan.slice';
import trackingPageSlice, { LanguageValue, languageSelector } from '@/redux/features/trackingPage.slice';
import { ITranslation } from '@/types/trackingpage';
import { Checkbox, Icon, InlineGrid, Select, SkeletonBodyText, TextField, Tooltip } from '@shopify/polaris';
import { LockIcon } from '@shopify/polaris-icons';
import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { convertClientKeyToApiKey, googleTranslatePositionOption, languageOptions, trackingFormConfig } from './config';
import { StyledAdvancedTranslationTitle } from './styled';

const LanguageTrackingPage = () => {
  const dispatch = useDispatch();
  const language = useSelector(languageSelector);
  const plan = useSelector(accountSelector);
  const { formattedEndDate } = useSelector(christmasBannerSelector);
  const isActiveChristmasFeature = new Date() < new Date(formattedEndDate);
  const isLockedFeature =
    isLockFeature([AccountPlan.Starter, AccountPlan.Basic]) && !plan.isOldPaidUser && !isActiveChristmasFeature;
  const [getTranslation, { isLoading: isFirstLoading }] = useLazyGetTranslationQuery();
  const [updateTranslation, { isLoading }] = useUpdateTranslationMutation();

  const renderAdvancedTranslationTitle = (title: string): any => (
    <StyledAdvancedTranslationTitle>
      {title}
      {isLockedFeature && (
        <Tooltip content="Upgrade to unlock this feature">
          <div className="link_container">
            <Icon source={LockIcon} tone="interactive" />
          </div>
        </Tooltip>
      )}
    </StyledAdvancedTranslationTitle>
  );

  const handleChangeLanguageConfig = (key: string) => (value: string | boolean) => {
    const config = { ...language.languageConfig };
    dispatch(
      trackingPageSlice.actions.handleLanguageConfig({
        ...config,
        [key]: value,
      }),
    );
    const { dirtyField: languageConfigField } = checkDirtyField(splitObjects(language.oldValue.languageConfig, 0, 1), {
      ...splitObjects(config, 0, 1),
      [key]: value,
    });
    const { dirtyField: googleTranslateExtensionField } = checkDirtyField(splitObjects(language.oldValue.languageConfig, 1, 2), {
      ...splitObjects(config, 1, 2),
      [key]: value,
    });
    const { dirtyField: googleTranslatePositionField } = checkDirtyField(splitObjects(language.oldValue.languageConfig, 2, 3), {
      ...splitObjects(config, 2, 3),
      [key]: value,
    });
    dispatch(
      trackingPageSlice.actions.handleLanguageFieldsChange(
        !languageConfigField &&
          !googleTranslateExtensionField &&
          !googleTranslatePositionField &&
          Object.entries(language.fieldsChange).length < 4
          ? {}
          : {
              ...language.fieldsChange,
              language: languageConfigField?.language,
              googleTranslateExtension: googleTranslateExtensionField?.googleTranslateExtension,
              googleTranslatePosition: googleTranslatePositionField?.googleTranslatePosition,
            },
      ),
    );
  };

  const changeKeyOfObject = (currentObject: Partial<Record<string, never>>, parentKey: string) => {
    const replacedObject = Object.keys(currentObject).map((key) => {
      const newKey =
        parentKey === 'trackingForm' || parentKey === 'footer'
          ? convertClientKeyToApiKey[parentKey][key as keyof (typeof convertClientKeyToApiKey)['trackingForm' | 'footer']]
          : camelToSnakeCase(key);
      return { [newKey]: currentObject[key] };
    });
    return replacedObject.reduce((a, b) => Object.assign({}, a, b));
  };
  const handleLanguageField = (currentValue: {}, parentKey: string) => {
    const { dirtyField: parentKeyField } = checkDirtyField(
      language.oldValue.value[parentKey as keyof typeof language.value],
      currentValue,
    );
    return !parentKeyField ? undefined : changeKeyOfObject(parentKeyField, parentKey);
  };

  const handleChange = (parentKey: string, key: string) => (value: string | boolean) => {
    const { fieldsChange } = language;
    dispatch(
      trackingPageSlice.actions.handleLanguageValue({
        ...language.value,
        [parentKey]: {
          ...language.value[parentKey as keyof LanguageValue],
          [key]: value,
        },
      }),
    );
    dispatch(trackingPageSlice.actions.handleChangeValidationLanguage(language.validation.filter((item) => item !== key)));
    dispatch(
      trackingPageSlice.actions.handleLanguageFieldsChange({
        ...fieldsChange,
        [parentKey]: handleLanguageField(
          {
            ...language.value[parentKey as keyof LanguageValue],
            [key]: value,
          },
          parentKey,
        ),
      }),
    );
  };

  const handleBlurTextField = (key: string, value?: string) => {
    if (!value) {
      const isNotHaveKey = language.validation.findIndex((item) => item === key) === -1;
      if (isNotHaveKey) {
        dispatch(trackingPageSlice.actions.handleChangeValidationLanguage([...language.validation, key]));
      }
    } else {
      dispatch(trackingPageSlice.actions.handleChangeValidationLanguage(language.validation.filter((item) => item !== key)));
    }
  };

  const handleResponse = useCallback(
    (data: ITranslation) => {
      const {
        trackingStatus,
        trackingForm,
        trackingHistory,
        progressBar,
        orderDetails,
        review,
        footer,
        googleTranslateExtension,
        googleTranslatePosition,
        language: languageData,
      } = data;
      const value = {
        footer: { privacy: footer.privacyPage, return: footer.returnPage, support: footer.support, term: footer.termPage },
        orderDetails: {
          shipmentItems: orderDetails.shipment_items,
          product: orderDetails.product,
          totalPrice: orderDetails.total_price,
          itemSingular: orderDetails.item_singular,
          itemPlural: orderDetails.item_plural,
        },
        progressBar,
        review: { buttonText: review.button_text, reviewTitle: review.review_title },
        trackingForm: {
          trackYourOrder: trackingForm.title,
          orderId: trackingForm.order_id,
          email: trackingForm.email,
          phoneNumber: trackingForm.phone,
          or: trackingForm.or,
          trackingNumber: trackingForm.tracking_number,
          track: trackingForm.button,
          placeholderTrackingNumber: trackingForm.plh_tracking_number,
          placeholderOrderId: trackingForm.plh_order_id,
          placeholderEmail: trackingForm.plh_email,
          placeholderPhoneNumber: trackingForm.plh_phone,
          orderNotFound: trackingForm.order_not_found,
          emptyEmail: trackingForm.empty_email,
          invalidEmail: trackingForm.invalid_email,
          emptyTrackingNumber: trackingForm.empty_tracking_number,
          emptyOrderName: trackingForm.empty_order_name,
          emptyPhoneNumber: trackingForm.empty_phone_number,
        },
        trackingHistory: {
          shipmentInformation: trackingHistory.shipment_information,
          shippingAddress: trackingHistory.shipping_address,
          trackingNumber: trackingHistory.tracking_number,
          shipmentHistory: trackingHistory.shipment_history,
          carrier: trackingHistory.carrier,
        },
        trackingStatus: {
          ordered: trackingStatus.ordered,
          informationReceived: trackingStatus.information_received,
          inTransit: trackingStatus.in_transit,
          outForDelivery: trackingStatus.out_for_delivery,
          delivered: trackingStatus.delivered,
          courierNotSupported: trackingStatus.courier_not_supported,
          expired: trackingStatus.expired,
          failedAttempt: trackingStatus.failed_attempt,
          exception: trackingStatus.exception,
          pending: trackingStatus.pending,
        },
      };
      dispatch(
        trackingPageSlice.actions.handleLanguageValue({
          ...value,
        }),
      );
      dispatch(
        trackingPageSlice.actions.handleLanguageOldValue({
          ...language.oldValue,
          value,
          languageConfig: {
            language: languageData as Language,
            googleTranslateExtension,
            googleTranslatePosition,
          },
        }),
      );
      dispatch(
        trackingPageSlice.actions.handleLanguageConfig({
          language: languageData as Language,
          googleTranslateExtension,
          googleTranslatePosition,
        }),
      );
    },
    [dispatch, language.oldValue],
  );

  const fetchData = useCallback(async () => {
    const { data } = await getTranslation({ language: language.languageConfig.language });
    if (data) {
      handleResponse(data.data);
    }
  }, [getTranslation, handleResponse, language.languageConfig.language]);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const actionGroup = useMemo(() => {
    const hasChange = Object.entries(language.fieldsChange).length > 0;
    return [
      {
        content: 'Discard',
        onAction: () => {
          dispatch(
            trackingPageSlice.actions.handleLanguageValue({
              ...language.oldValue.value,
            }),
          );
          dispatch(trackingPageSlice.actions.handleLanguageFieldsChange({}));
          dispatch(trackingPageSlice.actions.handleLanguageConfig({ ...language.oldValue.languageConfig }));
          dispatch(trackingPageSlice.actions.handleChangeValidationLanguage([]));
        },
        primary: false,
        disabled: !hasChange,
      },
      {
        content: 'Save',
        onAction: () => {
          updateTranslation(language.fieldsChange)
            .then((res) => {
              fetchData();
              dispatch(commonSlice.actions.handleToast(handleToastMutation(res)));
              dispatch(trackingPageSlice.actions.handleLanguageFieldsChange({}));
            })
            .catch((err) => {});
        },
        primary: true,
        disabled: !!language.validation.length || !hasChange,
        loading: isLoading,
      },
    ];
  }, [
    language.fieldsChange,
    language.validation.length,
    language.oldValue.value,
    language.oldValue.languageConfig,
    isLoading,
    dispatch,
    updateTranslation,
    fetchData,
  ]);

  return (
    <>
      <ControlBar
        actionGroup={actionGroup}
        hidden={isUndefinedArray(Object.values(language.fieldsChange)) || Object.entries(language.fieldsChange).length === 0}
      />

      <CustomCard title="Choose your language">
        {isFirstLoading ? (
          <SkeletonBodyText lines={4} />
        ) : (
          <>
            <InlineGrid columns={{ xl: 2, lg: 2, md: 1, xs: 1, sm: 1 }} gap="400">
              <Select
                label=""
                labelHidden
                options={languageOptions}
                onChange={handleChangeLanguageConfig('language')}
                value={language.languageConfig.language}
              />
              <Select
                label=""
                labelHidden
                options={googleTranslatePositionOption}
                onChange={handleChangeLanguageConfig('googleTranslatePosition')}
                value={language.languageConfig.googleTranslatePosition}
              />
            </InlineGrid>
            <div className="mt-8">
              <Checkbox
                label="Use Google Translate Extension"
                checked={language.languageConfig.googleTranslateExtension}
                onChange={handleChangeLanguageConfig('googleTranslateExtension')}
              />
            </div>
          </>
        )}
      </CustomCard>

      <CustomCard className="mt-16" title="Advanced translation">
        {isFirstLoading ? (
          <SkeletonBodyText lines={14} />
        ) : (
          <>
            {/* tracking status */}
            <UpgradeBanner isOpen={!!isLockedFeature} usedFeature={AccountPlan.Professional} isHiddenTitle />
            <CustomCollapsible open={false} title={renderAdvancedTranslationTitle('Tracking Status')} variant="headingMd">
              <InlineGrid columns={{ xl: 2, lg: 2, md: 1, xs: 1, sm: 1 }} gap="400">
                {Object.entries(language.value.trackingStatus).map(([key, value]) => {
                  return (
                    <TextField
                      maxLength={30}
                      key={key}
                      label={camelCaseToStartCase(key)}
                      value={value}
                      onChange={handleChange('trackingStatus', key)}
                      onBlur={() => handleBlurTextField(key, value)}
                      autoComplete="off"
                      showCharacterCount
                      disabled={isLockedFeature}
                      error={renderErrorValue(language.validation, key, `${camelCaseToStartCase(key)} is required `)}
                    />
                  );
                })}
              </InlineGrid>
            </CustomCollapsible>
            {/* tracking form */}
            <CustomCollapsible open={false} title={renderAdvancedTranslationTitle('Tracking Form')} variant="headingMd">
              <InlineGrid columns={{ xl: 2, lg: 2, md: 1, xs: 1, sm: 1 }} gap="400">
                {Object.entries(trackingFormConfig).map(([key, value]) => {
                  return (
                    <TextField
                      key={key}
                      maxLength={value.maxLength}
                      label={value.label}
                      value={language.value.trackingForm[key as keyof typeof language.value.trackingForm]}
                      onChange={handleChange('trackingForm', key)}
                      onBlur={() =>
                        handleBlurTextField(key, language.value.trackingForm[key as keyof typeof language.value.trackingForm])
                      }
                      autoComplete="off"
                      showCharacterCount
                      disabled={isLockedFeature}
                      error={renderErrorValue(language.validation, key, `${camelCaseToStartCase(key)} is required `)}
                    />
                  );
                })}
              </InlineGrid>
            </CustomCollapsible>
            {/* tracking history */}
            <CustomCollapsible open={false} title={renderAdvancedTranslationTitle('Tracking History')} variant="headingMd">
              <InlineGrid columns={{ xl: 2, lg: 2, md: 1, xs: 1, sm: 1 }} gap="400">
                {Object.entries(language.value.trackingHistory).map(([key, value]) => {
                  return (
                    <TextField
                      key={key}
                      maxLength={40}
                      showCharacterCount
                      label={camelCaseToStartCase(key)}
                      value={value}
                      onChange={handleChange('trackingHistory', key)}
                      onBlur={() => handleBlurTextField(key, value)}
                      autoComplete="off"
                      disabled={isLockedFeature}
                      error={renderErrorValue(language.validation, key, `${camelCaseToStartCase(key)} is required `)}
                    />
                  );
                })}
              </InlineGrid>
            </CustomCollapsible>

            {/* Progress bar */}
            <CustomCollapsible open={false} title={renderAdvancedTranslationTitle('Progress Bar')} variant="headingMd">
              <InlineGrid columns={{ xl: 2, lg: 2, md: 1, xs: 1, sm: 1 }} gap="400">
                {Object.entries(language.value.progressBar).map(([key, value]) => {
                  return (
                    <TextField
                      key={key}
                      maxLength={40}
                      showCharacterCount
                      label={camelCaseToStartCase(key)}
                      value={value}
                      onChange={handleChange('progressBar', key)}
                      onBlur={() => handleBlurTextField(key, value)}
                      autoComplete="off"
                      disabled={isLockedFeature}
                      error={renderErrorValue(language.validation, key, `${camelCaseToStartCase(key)} is required `)}
                    />
                  );
                })}
              </InlineGrid>
            </CustomCollapsible>

            {/* Order detals*/}
            <CustomCollapsible open={false} title={renderAdvancedTranslationTitle('Order details')} variant="headingMd">
              <InlineGrid columns={{ xl: 2, lg: 2, md: 1, xs: 1, sm: 1 }} gap="400">
                {Object.entries(language.value.orderDetails).map(([key, value]) => {
                  return (
                    <TextField
                      key={key}
                      maxLength={40}
                      showCharacterCount
                      label={camelCaseToStartCase(key)}
                      value={value}
                      onChange={handleChange('orderDetails', key)}
                      onBlur={() => handleBlurTextField(key, value)}
                      autoComplete="off"
                      disabled={isLockedFeature}
                      error={renderErrorValue(language.validation, key, `${camelCaseToStartCase(key)} is required `)}
                    />
                  );
                })}
              </InlineGrid>
            </CustomCollapsible>

            {/* Review*/}
            <CustomCollapsible open={false} title={renderAdvancedTranslationTitle('Review')} variant="headingMd">
              <InlineGrid columns={{ xl: 2, lg: 2, md: 1, xs: 1, sm: 1 }} gap="400">
                {Object.entries(language.value.review).map(([key, value]) => {
                  return (
                    <TextField
                      key={key}
                      maxLength={40}
                      showCharacterCount
                      label={camelCaseToStartCase(key)}
                      value={value}
                      onChange={handleChange('review', key)}
                      onBlur={() => handleBlurTextField(key, value)}
                      autoComplete="off"
                      disabled={isLockedFeature}
                      error={renderErrorValue(language.validation, key, `${camelCaseToStartCase(key)} is required `)}
                    />
                  );
                })}
              </InlineGrid>
            </CustomCollapsible>

            {/* Footer*/}
            <CustomCollapsible title={renderAdvancedTranslationTitle('Footer')} variant="headingMd" open={false}>
              <InlineGrid columns={{ xl: 2, lg: 2, md: 1, xs: 1, sm: 1 }} gap="400">
                {Object.entries(language.value.footer).map(([key, value]) => {
                  return (
                    <TextField
                      key={key}
                      maxLength={40}
                      showCharacterCount
                      label={camelCaseToStartCase(key)}
                      value={value}
                      onChange={handleChange('footer', key)}
                      onBlur={() => handleBlurTextField(key, value)}
                      autoComplete="off"
                      disabled={isLockedFeature}
                      error={renderErrorValue(language.validation, key, `${camelCaseToStartCase(key)} is required `)}
                    />
                  );
                })}
              </InlineGrid>
            </CustomCollapsible>
          </>
        )}
      </CustomCard>
    </>
  );
};

export default LanguageTrackingPage;
