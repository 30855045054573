import { TrackingPagePath } from '@/pages/TrackingPage/components/EDD/components/ModalTrackingPage';
import LookupPage from '@/pages/TrackingPage/components/EDD/components/ModalTrackingPage/components/PreviewContent/components/LookupPage';
import StatusPage from '@/pages/TrackingPage/components/EDD/components/ModalTrackingPage/components/PreviewContent/components/StatusPage';
import { Card } from '@shopify/polaris';
import { useState } from 'react';

const PreviewContent = () => {
  const [currentPath, setCurrentPath] = useState<TrackingPagePath>(TrackingPagePath.Lookup);
  return (
    <div className="content-right-preview mt-8">
      <Card>{currentPath === TrackingPagePath.Lookup ? <LookupPage /> : <StatusPage />}</Card>
    </div>
  );
};

export default PreviewContent;
