import { getErrorFromAPI, handleToastMutation } from '@/helpers/toast';
import { useUpdateCourierNameMutation } from '@/redux/api/api.caller';
import commonSlice from '@/redux/features/common.slice';
import shipmentSlice, { courierModalSelector } from '@/redux/features/shipment.slice';
import { Modal, TextField } from '@shopify/polaris';
import { memo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

interface CourierModalProps {
  clearSelection: () => void;
}

const CourierModal = ({ clearSelection }: CourierModalProps) => {
  const [apiError, setApiError] = useState<string>('');
  const dispatch = useDispatch();
  const courierModal = useSelector(courierModalSelector);

  const [updateCourierName, { isLoading: isEditLoading }] = useUpdateCourierNameMutation();

  const handleCloseModal = () => {
    setApiError('');
    dispatch(shipmentSlice.actions.handleChangeCourierModal({ ...courierModal, open: false, value: '' }));
  };

  const handleChangeCourierName = (value: string) => {
    setApiError('');
    dispatch(shipmentSlice.actions.handleChangeCourierModal({ ...courierModal, value }));
  };

  const handleEditCourierName = () => {
    updateCourierName({ ids: courierModal.id, courier: courierModal.value }).then((res) => {
      if ('data' in res && res.data && res.data?.state === 1) {
        handleCloseModal();
        clearSelection();
        dispatch(commonSlice.actions.handleToast(handleToastMutation(res)));
        if (apiError) {
          setApiError('');
        }
        return;
      }
      setApiError(getErrorFromAPI(res));
    });
  };

  return (
    <Modal
      open={courierModal.open}
      onClose={handleCloseModal}
      title="Edit courier name"
      primaryAction={{
        content: 'Save',
        onAction: handleEditCourierName,
        disabled: !courierModal.value,
        loading: isEditLoading,
      }}
    >
      <Modal.Section>
        <TextField
          label="New Courier Name"
          helpText="Example: Canada Post"
          value={courierModal.value}
          onChange={handleChangeCourierName}
          autoComplete="off"
          error={apiError}
        />
      </Modal.Section>
    </Modal>
  );
};

export default memo(CourierModal);
