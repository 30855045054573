import BoldRegularText from '@/components/BoldRegularText';
import BoldText from '@/components/BoldText';
import RegularText from '@/components/RegularText';
import { AccountPlan, ProductRecommendType, ShippingInfo } from '@/constants/enum';
import { camelToSnakeCase, checkDirtyField, isLockFeature, isUrlValid, renderErrorValue, splitObjects } from '@/helpers';
import MenuItem from '@/pages/TrackingPage/components/EDD/components/ModalTrackingPage/components/MenuItem';
import { convertClientKeyToApiKey } from '@/pages/TrackingPage/components/Language/config';
import UploadAsset from '@/pages/TrackingPage/components/LookAndFeel/components/ChooseTheme/UploadAsset';
import { isNotChangeData } from '@/pages/TrackingPage/config';
import trackingPageSlice, {
  IConfiguration,
  languageSelector,
  LanguageValue,
  trackingPageSelector,
} from '@/redux/features/trackingPage.slice';
import { Autocomplete, Button, Checkbox, Divider, Select, Tag, TextField } from '@shopify/polaris';
import { ChevronLeftIcon, EditIcon } from '@shopify/polaris-icons';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ProgressBarContent from './components/ProgressBarContent';
import { accountSelector } from '@/redux/features/plan.slice';
import { useGetProductRecommendQuery } from '@/redux/api/api.caller';
import { footerLinkConfig } from './config';

enum LookupEditType {
  ProgressBar = 'progressBar',
  TrackingHistory = 'orderDetail',
  OrderDetail = 'trackingLogs',
  TrackingCompany = 'trackingCompany',
  ProductRecommendation = 'productRecommendation',
  PolicyAndSocial = 'policyAndSocial',
  Image = 'image',
}

interface IAutoCompleteTextFieldState {
  optionsSelected: Array<{
    value: string;
    label: string;
  }>;
  optionsFilter: Array<{
    value: string;
    label: string;
  }>;
  options: Array<{
    value: string;
    label: string;
  }>;
  baseOptions: {
    type: Array<{
      value: string;
      label: string;
    }>;
    tags: Array<{
      value: string;
      label: string;
    }>;
  };
}

const optionsConfig = [
  { label: 'All product', value: ProductRecommendType.AllProduct, isHasValue: false },
  { label: 'Product Type', value: ProductRecommendType.productType, isHasValue: true },
  { label: 'Product Tags', value: ProductRecommendType.Tags, isHasValue: true },
];

const PageStatus = () => {
  const dispatch = useDispatch();
  const plan = useSelector(accountSelector);
  const isLock = isLockFeature([AccountPlan.Starter]) && !plan.isOldPaidUser;
  const language = useSelector(languageSelector);
  const { trackingHistory, orderDetails } = language.value;
  const {
    value: data,
    fieldsChange,
    oldValue: {
      lookAndFeel: { content: oldContent },
      configuration: { productRecommendation: oldProductRecommendation, footerLink: oldFooterLink },
    },
    validation,
  } = useSelector(trackingPageSelector);
  const { productRecommendation, footerLink } = data.configuration;
  const { content } = data.lookAndFeel;
  const [currentEditItem, setCurrentEditItem] = useState<LookupEditType>();
  const [state, setState] = useState<IAutoCompleteTextFieldState>({
    optionsSelected: [],
    baseOptions: {
      type: [],
      tags: [],
    },
    optionsFilter: [],
    options: [],
  });
  const [isFocusAutoComplete, setIsFocusAutoComplete] = useState<boolean>(false);
  const productionRecommendData = useGetProductRecommendQuery(undefined, {
    skip: !isFocusAutoComplete || isLock,
  });
  const [inputValue, setInputValue] = useState('');
  const updateText = useCallback((inputValue: string) => {
    setInputValue(inputValue);
    const optionsFilter = state.options.filter((option) => option.label.toUpperCase().includes(inputValue.toUpperCase()));
    setState({ ...state, optionsFilter });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (productionRecommendData.data) {
      const { data } = productionRecommendData.data;
      const type = data.types.map((item) => {
        return { value: item, label: item };
      });
      const tags = data.tags.map((item) => {
        return { value: item, label: item };
      });
      setState({
        ...state,
        baseOptions: { type, tags },
        options: productRecommendation.type === ProductRecommendType.Tags ? tags : type,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productionRecommendData.data]);

  useEffect(() => {
    if (productRecommendation.type === ProductRecommendType.Tags) {
      setState({ ...state, options: state.baseOptions.tags });
    }
    if (productRecommendation.type === ProductRecommendType.productType) {
      setState({ ...state, options: state.baseOptions.type });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productRecommendation.type]);

  const trackingInformationOptions = useMemo(() => {
    return [
      { id: LookupEditType.ProgressBar, value: ShippingInfo.ProgressBar, label: 'Progress bar & Status labels' },
      { id: LookupEditType.TrackingHistory, value: ShippingInfo.TrackingLogs, label: 'Tracking history' },
      {
        id: LookupEditType.OrderDetail,
        value: ShippingInfo.OrderDetail,
        label: 'Order detail',
      },
      {
        id: LookupEditType.TrackingCompany,
        value: ShippingInfo.TrackingCompany,
        label: 'Tracking information',
      },
      {
        id: LookupEditType.TrackingCompany,
        value: ShippingInfo.Map,
        label: 'Map',
        isNotEditable: true,
      },
    ];
  }, []);

  const appearanceOptions = useMemo(() => {
    return [{ id: LookupEditType.Image, label: 'Images' }];
  }, []);

  const changeKeyOfObject = useCallback((currentObject: Partial<Record<string, never>>, parentKey: string) => {
    const replacedObject = Object.keys(currentObject).map((key) => {
      const newKey =
        parentKey === 'trackingForm' || parentKey === 'footer'
          ? convertClientKeyToApiKey[parentKey][key as keyof (typeof convertClientKeyToApiKey)['trackingForm' | 'footer']]
          : camelToSnakeCase(key);
      return { [newKey]: currentObject[key] };
    });
    return replacedObject.reduce((a, b) => Object.assign({}, a, b));
  }, []);

  const handleLanguageField = useCallback(
    (currentValue: {}, parentKey: string) => {
      const { dirtyField: parentKeyField } = checkDirtyField(
        language.oldValue.value[parentKey as keyof typeof language.value],
        currentValue,
      );
      return !parentKeyField ? undefined : changeKeyOfObject(parentKeyField, parentKey);
    },
    [changeKeyOfObject, language],
  );

  const handleChangeLanguage = useCallback(
    (parentKey: string, key: string) => (value: string | boolean) => {
      const { fieldsChange } = language;
      dispatch(
        trackingPageSlice.actions.handleLanguageValue({
          ...language.value,
          [parentKey]: {
            ...language.value[parentKey as keyof LanguageValue],
            [key]: value,
          },
        }),
      );
      dispatch(
        trackingPageSlice.actions.handleLanguageFieldsChange({
          ...fieldsChange,
          [parentKey]: handleLanguageField(
            {
              ...language.value[parentKey as keyof LanguageValue],
              [key]: value,
            },
            parentKey,
          ),
        }),
      );
    },
    [dispatch, handleLanguageField, language],
  );

  const handleProductRecommendationField = useCallback(
    (currentValue: {}) => {
      const { dirtyField: enableField } = checkDirtyField(
        { enable: oldProductRecommendation.enable },
        {
          ...currentValue,
        },
      );

      const { dirtyField: titleField } = checkDirtyField(
        { title: oldProductRecommendation.title },
        {
          ...currentValue,
        },
      );

      const { dirtyField: typeField } = checkDirtyField(
        { type: oldProductRecommendation.type },
        {
          ...currentValue,
        },
      );

      if (!enableField && !titleField && !typeField) {
        return undefined;
      }
      return {
        isActive: enableField?.enable,
        title: titleField?.title,
        type: typeField?.type,
      };
    },
    [oldProductRecommendation.enable, oldProductRecommendation.title, oldProductRecommendation.type],
  );

  const handleChangeProductRecommendation = useCallback(
    (key: keyof IConfiguration['productRecommendation']) => (value: string | boolean) => {
      dispatch(
        trackingPageSlice.actions.handleTrackingPageValue({
          ...data,
          configuration: {
            ...data.configuration,
            productRecommendation: {
              ...productRecommendation,
              value: !handleProductRecommendationField({ ...productRecommendation, [key]: value })
                ? oldProductRecommendation.value
                : [],
              [key]: value,
            },
          },
        }),
      );
      dispatch(
        trackingPageSlice.actions.handleTrackingPageFieldsChange({
          ...fieldsChange,
          productRecommend: handleProductRecommendationField({
            ...productRecommendation,
            [key]: value,
          }),
        }),
      );
    },
    [data, dispatch, fieldsChange, handleProductRecommendationField, oldProductRecommendation.value, productRecommendation],
  );

  const handleChangeFooterLink = useCallback(
    (key: keyof typeof footerLink) => (value: (typeof footerLink)[keyof typeof footerLink]) => {
      dispatch(trackingPageSlice.actions.handleChangeValidation(validation.filter((item) => item !== key)));
      dispatch(
        trackingPageSlice.actions.handleTrackingPageValue({
          ...data,
          configuration: {
            ...data.configuration,
            footerLink: {
              ...footerLink,
              [key]: value,
            },
          },
        }),
      );
      const { dirtyField: policyField } = checkDirtyField(splitObjects(oldFooterLink, 0, 4), {
        ...splitObjects(footerLink, 0, 4),
        [key]: value,
      });
      const { dirtyField: socialField } = checkDirtyField(
        splitObjects(oldFooterLink, 4, Object.entries(oldFooterLink).length - 1),
        {
          ...splitObjects(footerLink, 4, Object.entries(footerLink).length - 1),
          [key]: value,
        },
      );
      const { dirtyField: isEnabledField } = checkDirtyField(
        { isEnabledFooterLink: oldFooterLink.isEnabledFooterLink },
        {
          ...footerLink,
          [key]: value,
        },
      );
      dispatch(
        trackingPageSlice.actions.handleTrackingPageFieldsChange({
          ...fieldsChange,
          footerLinks:
            !policyField && !socialField && !isEnabledField
              ? undefined
              : {
                  social: socialField,
                  policy: policyField,
                  ...isEnabledField,
                },
        }),
      );
    },
    [data, dispatch, fieldsChange, footerLink, oldFooterLink, validation],
  );

  const additionalOptions = useMemo(() => {
    return [
      {
        id: LookupEditType.ProductRecommendation,
        label: 'Product recommendation',
        isChecked: productRecommendation.enable,
        onChange: handleChangeProductRecommendation('enable'),
      },
      {
        id: LookupEditType.PolicyAndSocial,
        label: 'Policy & Social links',
        isChecked: footerLink.isEnabledFooterLink,
        onChange: handleChangeFooterLink('isEnabledFooterLink'),
      },
    ];
  }, [footerLink.isEnabledFooterLink, handleChangeFooterLink, handleChangeProductRecommendation, productRecommendation.enable]);

  const handleShippingInfo = (value: ShippingInfo) => () => {
    const shippingInfo = [...data.lookAndFeel.content.shippingInfo];
    const index = shippingInfo.findIndex((item) => item === value);
    if (index === -1) {
      shippingInfo.push(value);
    } else {
      shippingInfo.splice(index, 1);
    }
    dispatch(
      trackingPageSlice.actions.handleTrackingPageValue({
        ...data,
        lookAndFeel: {
          ...data.lookAndFeel,
          content: {
            ...content,
            shippingInfo,
          },
        },
      }),
    );
    dispatch(
      trackingPageSlice.actions.handleTrackingPageFieldsChange({
        ...fieldsChange,
        general:
          isNotChangeData(oldContent.shippingInfo, shippingInfo) && Object.entries(fieldsChange.general).length < 2
            ? undefined
            : {
                ...fieldsChange.general,
                show: shippingInfo.join(','),
              },
      }),
    );
  };

  const handleChangeSelected = useCallback(
    (value: Array<string>) => {
      dispatch(
        trackingPageSlice.actions.handleTrackingPageValue({
          ...data,
          configuration: {
            ...data.configuration,
            productRecommendation: {
              ...productRecommendation,
              value,
            },
          },
        }),
      );
      dispatch(
        trackingPageSlice.actions.handleTrackingPageFieldsChange({
          ...fieldsChange,
          productRecommend:
            isNotChangeData(oldProductRecommendation.value, value) && Object.entries(fieldsChange.productRecommend).length < 3
              ? undefined
              : {
                  ...fieldsChange.productRecommend,
                  tags: productRecommendation.type === ProductRecommendType.Tags ? value : undefined,
                  productType: productRecommendation.type === ProductRecommendType.productType ? value : undefined,
                },
        }),
      );
    },
    [data, dispatch, fieldsChange, oldProductRecommendation.value, productRecommendation],
  );

  const handleRemoveTag = useCallback(
    (value: string) => () => {
      const selected = [...data.configuration.productRecommendation.value];
      const index = selected.findIndex((item) => item === value);
      selected.splice(index, 1);
      dispatch(
        trackingPageSlice.actions.handleTrackingPageValue({
          ...data,
          configuration: {
            ...data.configuration,
            productRecommendation: {
              ...productRecommendation,
              value: selected,
            },
          },
        }),
      );

      dispatch(
        trackingPageSlice.actions.handleTrackingPageFieldsChange({
          ...fieldsChange,
          productRecommend:
            isNotChangeData(oldProductRecommendation.value, selected) &&
            Object.entries(fieldsChange.productRecommend || {}).length < 3
              ? undefined
              : {
                  ...fieldsChange.productRecommend,
                  tags: productRecommendation.type === ProductRecommendType.Tags ? selected : undefined,
                  productType: productRecommendation.type === ProductRecommendType.productType ? selected : undefined,
                },
        }),
      );
    },
    [data, dispatch, fieldsChange, oldProductRecommendation.value, productRecommendation],
  );

  const handleBlurTextField = useCallback(
    (value: string | boolean | undefined, key: string) => {
      if (typeof value === 'boolean') return;
      if (value && !isUrlValid(value)) {
        const isNotHaveKey = validation.findIndex((item) => item === key) === -1;
        if (isNotHaveKey) {
          dispatch(trackingPageSlice.actions.handleChangeValidation([...validation, key]));
        }
      } else {
        dispatch(trackingPageSlice.actions.handleChangeValidation(validation.filter((item) => item !== key)));
      }
    },
    [dispatch, validation],
  );

  const renderEditContent = useCallback(() => {
    switch (currentEditItem) {
      case LookupEditType.ProgressBar:
        return <ProgressBarContent />;
      case LookupEditType.TrackingHistory:
        return (
          <div className="px-16">
            <TextField
              maxLength={100}
              showCharacterCount
              label="Title"
              placeholder="Shipment history"
              autoComplete="off"
              value={trackingHistory.shipmentHistory}
              onChange={handleChangeLanguage('trackingHistory', 'shipmentHistory')}
            />
          </div>
        );
      case LookupEditType.OrderDetail:
        return (
          <div className="px-16 d-flex flex-column gap-8">
            <TextField
              maxLength={100}
              showCharacterCount
              label="Title"
              placeholder="Items of shipment"
              autoComplete="off"
              value={orderDetails.shipmentItems}
              onChange={handleChangeLanguage('orderDetails', 'shipmentItems')}
            />
            <TextField
              maxLength={100}
              showCharacterCount
              label="Product"
              placeholder="Products"
              autoComplete="off"
              value={orderDetails.product}
              onChange={handleChangeLanguage('orderDetails', 'product')}
            />
            <TextField
              maxLength={100}
              showCharacterCount
              label="Total price"
              placeholder="Total"
              autoComplete="off"
              value={orderDetails.totalPrice}
              onChange={handleChangeLanguage('orderDetails', 'totalPrice')}
            />
            <TextField
              maxLength={100}
              showCharacterCount
              label="Item singular"
              placeholder="Item"
              autoComplete="off"
              value={orderDetails.itemSingular}
              onChange={handleChangeLanguage('orderDetails', 'itemSingular')}
            />
            <TextField
              maxLength={100}
              showCharacterCount
              label="Item plural"
              placeholder="Items"
              autoComplete="off"
              value={orderDetails.itemPlural}
              onChange={handleChangeLanguage('orderDetails', 'itemPlural')}
            />
          </div>
        );
      case LookupEditType.TrackingCompany:
        return (
          <div className="px-16 d-flex flex-column gap-8">
            <TextField
              maxLength={100}
              showCharacterCount
              label="Title"
              placeholder="Shipment information"
              autoComplete="off"
              value={trackingHistory.shipmentInformation}
              onChange={handleChangeLanguage('trackingHistory', 'shipmentInformation')}
            />
            <TextField
              maxLength={100}
              showCharacterCount
              label="Shipping address"
              placeholder="Shipping address"
              autoComplete="off"
              value={trackingHistory.shippingAddress}
              onChange={handleChangeLanguage('trackingHistory', 'shippingAddress')}
            />
            <TextField
              maxLength={100}
              showCharacterCount
              label="Tracking number"
              placeholder="Tracking number"
              autoComplete="off"
              value={trackingHistory.trackingNumber}
              onChange={handleChangeLanguage('trackingHistory', 'trackingNumber')}
            />
            <TextField
              maxLength={100}
              showCharacterCount
              label="Carrier"
              placeholder="Carrier"
              autoComplete="off"
              value={trackingHistory.carrier}
              onChange={handleChangeLanguage('trackingHistory', 'carrier')}
            />
          </div>
        );
      case LookupEditType.Image:
        return (
          <div className="px-16">
            <UploadAsset />
          </div>
        );
      case LookupEditType.ProductRecommendation:
        return (
          <div className="px-16 d-flex flex-column gap-8">
            <TextField
              label="Title"
              placeholder="URL"
              autoComplete="off"
              value={productRecommendation.title}
              onChange={handleChangeProductRecommendation('title')}
              maxLength={100}
              showCharacterCount
              disabled={isLock}
            />
            <Select
              label="Type"
              options={optionsConfig.map((item) => ({
                label: item.label,
                value: item.value,
              }))}
              onChange={handleChangeProductRecommendation('type')}
              value={productRecommendation.type}
              disabled={isLock}
            />
            {optionsConfig.find((item) => item.value === productRecommendation.type)?.isHasValue ? (
              <div>
                <Autocomplete
                  options={state.options}
                  selected={productRecommendation.value}
                  onSelect={handleChangeSelected}
                  textField={
                    <div onFocus={() => setIsFocusAutoComplete(true)}>
                      <Autocomplete.TextField
                        onChange={updateText}
                        label="Select your type/tag for recommend products"
                        value={inputValue}
                        autoComplete="off"
                      />
                    </div>
                  }
                  allowMultiple
                  loading={productionRecommendData.isLoading}
                />
                <div className="mt-8 d-flex flex-wrap gap-8">
                  {productRecommendation.value.length > 0 &&
                    productRecommendation.value.map((option) => {
                      return (
                        <div key={option}>
                          <Tag onRemove={handleRemoveTag(option)}>{option}</Tag>
                        </div>
                      );
                    })}
                </div>
              </div>
            ) : null}
          </div>
        );
      case LookupEditType.PolicyAndSocial:
        const renderTextFields = (
          fields: {
            key: string;
            title: string;
            placeHolder: string;
          }[],
        ) => {
          return fields.map((item) => (
            <TextField
              placeholder={item.placeHolder}
              id={item.key}
              key={item.key}
              label={item.title}
              onChange={handleChangeFooterLink(item.key as keyof typeof footerLink)}
              value={footerLink[item.key as keyof typeof footerLink] + ''}
              onBlur={() => handleBlurTextField(footerLink[item.key as keyof typeof footerLink], item.key)}
              autoComplete="off"
              maxLength={100}
              showCharacterCount
              error={renderErrorValue(validation, item.key)}
            />
          ));
        };
        return (
          <>
            <div className="px-16 d-flex flex-column gap-8 pb-8">
              <BoldRegularText>Policy</BoldRegularText>
              {renderTextFields(footerLinkConfig.policy)}
            </div>
            <Divider />
            <div className="px-16 d-flex flex-column gap-8">
              <BoldRegularText>Social media</BoldRegularText>
              {renderTextFields(footerLinkConfig.social)}
            </div>
          </>
        );

      default:
        return null;
    }
  }, [
    currentEditItem,
    footerLink,
    handleBlurTextField,
    handleChangeFooterLink,
    handleChangeLanguage,
    handleChangeProductRecommendation,
    handleChangeSelected,
    handleRemoveTag,
    inputValue,
    isLock,
    orderDetails.itemPlural,
    orderDetails.itemSingular,
    orderDetails.product,
    orderDetails.shipmentItems,
    orderDetails.totalPrice,
    productRecommendation.title,
    productRecommendation.type,
    productRecommendation.value,
    productionRecommendData.isLoading,
    state.options,
    trackingHistory.carrier,
    trackingHistory.shipmentHistory,
    trackingHistory.shipmentInformation,
    trackingHistory.shippingAddress,
    trackingHistory.trackingNumber,
    updateText,
    validation,
  ]);

  return currentEditItem ? (
    <>
      <MenuItem>
        <div className="d-flex align-center gap-4">
          <Button icon={ChevronLeftIcon} onClick={() => setCurrentEditItem(undefined)} variant="monochromePlain" />
          <BoldText>
            {
              [...trackingInformationOptions, ...appearanceOptions, ...additionalOptions].find(
                (item) => item.id === currentEditItem,
              )?.label
            }
          </BoldText>
        </div>
      </MenuItem>
      <div className="py-16 border-bottom d-flex flex-column gap-8 edit-content-left">{renderEditContent()}</div>
    </>
  ) : (
    <>
      <MenuItem>
        <BoldText>Order status page</BoldText>
      </MenuItem>

      <MenuItem tone="tertiary">
        <BoldText customFontSize>Tracking information</BoldText>
      </MenuItem>
      {trackingInformationOptions.map((item) => (
        <MenuItem
          actionIcon={!item.isNotEditable ? EditIcon : undefined}
          onAction={!item.isNotEditable ? () => setCurrentEditItem(item.id) : undefined}
          key={item.id}
        >
          <Checkbox
            label={<RegularText>{item.label}</RegularText>}
            checked={content.shippingInfo.includes(item.value)}
            onChange={handleShippingInfo(item.value)}
          />
        </MenuItem>
      ))}

      <MenuItem tone="tertiary">
        <BoldText customFontSize>Appearance</BoldText>
      </MenuItem>
      {appearanceOptions.map((item) => (
        <MenuItem actionIcon={EditIcon} onAction={() => setCurrentEditItem(item.id)} key={item.id}>
          <RegularText>{item.label}</RegularText>
        </MenuItem>
      ))}

      <MenuItem tone="tertiary">
        <BoldText customFontSize>Additional sections</BoldText>
      </MenuItem>
      {additionalOptions.map((item) => (
        <MenuItem actionIcon={EditIcon} onAction={() => setCurrentEditItem(item.id)} key={item.id}>
          <Checkbox checked={item.isChecked} onChange={item.onChange} label={<RegularText>{item.label}</RegularText>} />
        </MenuItem>
      ))}
    </>
  );
};

export default PageStatus;
