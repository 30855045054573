import { CustomCard } from '@/components';
import AutoCompleteWithTag from '@/components/AutoCompleteWithTag';
import BoldText from '@/components/BoldText';
import { languageOptions } from '@/constants';
import { checkDirtyField, findLanguage, splitObjects } from '@/helpers';
import { handleToastMutation } from '@/helpers/toast';
import { useUpdateTranslationMutation } from '@/redux/api/api.caller';
import commonSlice from '@/redux/features/common.slice';
import trackingPageSlice, { languageSelector } from '@/redux/features/trackingPage.slice';
import { Badge, ChoiceList, Modal, Text } from '@shopify/polaris';
import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const LanguageSection = () => {
  const dispatch = useDispatch();
  const language = useSelector(languageSelector);

  const [isShowSelectLanguage, setIsShowSelectLanguage] = useState(false);
  const [selected, setSelected] = useState<string[]>(
    language.oldValue.languageConfig.googleTranslateExtension ? ['googleTranslateExtension'] : [],
  );

  const [updateTranslation, { isLoading }] = useUpdateTranslationMutation();

  const handleChangeLanguageConfig = useCallback(
    (key: string) => (value: string | boolean) => {
      const config = { ...language.languageConfig };
      dispatch(
        trackingPageSlice.actions.handleLanguageConfig({
          ...config,
          [key]: value,
        }),
      );
      const { dirtyField: languageConfigField } = checkDirtyField(splitObjects(language.oldValue.languageConfig, 0, 1), {
        ...splitObjects(config, 0, 1),
        [key]: value,
      });
      const { dirtyField: googleTranslateExtensionField } = checkDirtyField(
        splitObjects(language.oldValue.languageConfig, 1, 2),
        {
          ...splitObjects(config, 1, 2),
          [key]: value,
        },
      );
      const { dirtyField: googleTranslatePositionField } = checkDirtyField(splitObjects(language.oldValue.languageConfig, 2, 3), {
        ...splitObjects(config, 2, 3),
        [key]: value,
      });
      dispatch(
        trackingPageSlice.actions.handleLanguageFieldsChange(
          !languageConfigField &&
            !googleTranslateExtensionField &&
            !googleTranslatePositionField &&
            Object.entries(language.fieldsChange).length < 4
            ? {}
            : {
                ...language.fieldsChange,
                language: languageConfigField?.language,
                googleTranslateExtension: googleTranslateExtensionField?.googleTranslateExtension,
                googleTranslatePosition: googleTranslatePositionField?.googleTranslatePosition,
              },
        ),
      );
    },
    [dispatch, language.fieldsChange, language.languageConfig, language.oldValue.languageConfig],
  );

  const handleChange = useCallback(
    (value: string[]) => {
      setSelected(value);
      handleChangeLanguageConfig('googleTranslateExtension')(value.includes('googleTranslateExtension'));
    },
    [handleChangeLanguageConfig],
  );

  const handleClickCloseModal = () => {
    setIsShowSelectLanguage(false);
  };

  const handleUpdateTranslation = () => {
    updateTranslation(language.fieldsChange)
      .then((res) => {
        dispatch(commonSlice.actions.handleToast(handleToastMutation(res)));
        dispatch(trackingPageSlice.actions.handleLanguageFieldsChange({}));
        dispatch(
          trackingPageSlice.actions.handleLanguageOldValue({
            ...language.oldValue,
            languageConfig: { ...language.oldValue.languageConfig, language: language.languageConfig.language },
          }),
        );
        setIsShowSelectLanguage(false);
      })
      .catch((err) => {});
  };

  return (
    <CustomCard title="Language" noHeaderBorder noHeaderPaddingBottom>
      <div className="d-flex flex-column gap-16">
        <CustomCard
          title={
            <div className="d-flex flex-column gap-4">
              Default language: {findLanguage(language.oldValue.languageConfig.language)?.label || ''}
              <Text variant="bodyMd" as="p">
                Default language of order tracking page.
              </Text>
            </div>
          }
          noHeaderBorder
          actionGroup={[
            {
              content: 'Change default',
              onAction: () => {
                setIsShowSelectLanguage(true);
              },
            },
          ]}
        />

        <CustomCard title="Translation" noHeaderBorder noHeaderPaddingBottom>
          <div style={{ marginTop: '-0.5rem' }}>
            <ChoiceList
              title="Translation"
              titleHidden
              allowMultiple
              choices={[
                {
                  label: (
                    <Text variant="bodyMd" as="p">
                      Use Google Translate Extension
                    </Text>
                  ),
                  value: 'googleTranslateExtension',
                },
                {
                  label: (
                    <div className="d-flex gap-8" style={{ fontSize: '0.8125rem' }}>
                      Manually customized language version
                      <Badge tone="info">Coming soon</Badge>
                    </div>
                  ),
                  value: '2',
                  disabled: true,
                },
              ]}
              selected={selected}
              onChange={handleChange}
            />
          </div>
        </CustomCard>
      </div>
      <Modal
        open={isShowSelectLanguage}
        title="Select default language"
        onClose={handleClickCloseModal}
        primaryAction={{ content: 'Save', onAction: handleUpdateTranslation, loading: isLoading }}
        secondaryActions={[
          {
            content: 'Cancel',
            onAction: handleClickCloseModal,
            loading: isLoading,
          },
        ]}
      >
        <Modal.Section>
          <AutoCompleteWithTag
            options={languageOptions}
            tag={[
              findLanguage(language.languageConfig.language) || {
                label: '',
                value: '',
              },
            ]}
            label={
              (
                <div className="d-flex gap-8 align-center">
                  <BoldText variant="headingSm">Select a language: </BoldText>
                  <Badge tone="info">{findLanguage(language.languageConfig.language)?.label || ''}</Badge>
                </div>
              ) as any
            }
            selectedOptions={[language.languageConfig.language]}
            setSelectedOptions={(value) => {
              handleChangeLanguageConfig('language')(value[0].value);
            }}
            hasClearButton
          />
          {/* {isVisibleBannerWarningDefaultLanguage && (
          <div className="mt-16">
            <Banner tone="critical">
              You already have <b>{findLanguage(language.languageConfig.language)?.label || ''}</b> version. Saving default
              language as <b>{findLanguage(language.languageConfig.language)?.label || ''}</b> will immediately make current
              version <b>Inactive</b>.
            </Banner>
          </div>
        )} */}
        </Modal.Section>
      </Modal>
    </CustomCard>
  );
};

export default LanguageSection;
