import { BgColorPalette } from '@/constants';
import styled from 'styled-components';

export const ControlBarStyled = styled.div<{ isFixed?: boolean }>`
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background-color: ${BgColorPalette.PRIMARY};
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;
  ${({ isFixed }) =>
    isFixed
      ? `
    position: fixed;
  `
      : ''}
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 0.5rem;

  .Polaris-Button.Polaris-Button--disabled {
    background: rgba(255, 255, 255, 1);
  }
`;
