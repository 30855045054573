import { IConfigTab } from '@/types/tab';
import ShipmentReport from './ShipmentReport';
import { DomainNewIcon, MegaphoneIcon, DeliveryIcon, StarIcon, ForkliftIcon } from '@shopify/polaris-icons';
import CourierReport from './CourierReport';
import TrackingPageReport from './TrackingPageReport';
import ReviewDashboard from './ReviewDashboard';
import NPSReport from './NPSReport';
import { AnalyticsTabs } from '@/constants';

export const tabsConfig: Array<IConfigTab> = [
  {
    value: AnalyticsTabs.ShipmentReport,
    label: 'Shipment Report',
    component: <ShipmentReport />,
    icon: DeliveryIcon,
  },
  {
    value: AnalyticsTabs.CourierReport,
    label: 'Courier Report',
    component: <CourierReport />,
    icon: ForkliftIcon,
  },
  {
    value: AnalyticsTabs.TrackingPageReport,
    label: 'Tracking Page Report',
    component: <TrackingPageReport />,
    icon: DomainNewIcon,
  },
  {
    value: AnalyticsTabs.ReviewDashboard,
    label: 'Review Dashboard',
    component: <ReviewDashboard />,
    icon: StarIcon,
  },
  {
    value: AnalyticsTabs.NPSReport,
    label: 'NPS Report',
    component: <NPSReport />,
    icon: MegaphoneIcon,
  },
];
