import { IOption } from '@/types/option';
import { uniq, uniqByKey } from './array';

export const getLabelFromValue = (optionValue: string, option: { value: string; label: string }[]) =>
  option.find(({ value }) => value === optionValue)?.label;

export const getOptionsSelectedAdd = (
  newValue: Array<string>,
  oldValue: Array<IOption>,
  options: Array<IOption>,
  key?: string,
) => {
  const optionValues = uniq(newValue.map((value) => value.trim())); // Loại bỏ khoảng trắng không cần thiết

  const selectedOptions = options?.filter((option) => optionValues.includes(option.value));

  return uniqByKey([...oldValue, ...selectedOptions], key);
};

export const getOptionsSelectedRemove = (newValue: Array<string>, oldValue: Array<IOption>) => {
  const optionSelected = oldValue?.filter((option) => newValue.includes(option.value));
  return optionSelected;
};
