import { Text, TextProps } from '@shopify/polaris';
import { memo } from 'react';

interface IProps extends Omit<TextProps, 'as'> {
  children: React.ReactNode;
  customFontSize?: boolean;
  className?: string;
}

const BoldText = ({ children, variant, customFontSize, className, ...textProps }: IProps) => {
  return (
    <div className={`d-inline-block ${customFontSize ? 'bold-text-styled' : ''} ${className || ''}`}>
      <Text as="span" variant={variant || 'headingMd'} {...textProps}>
        {children}
      </Text>
    </div>
  );
};
export default memo(BoldText);
