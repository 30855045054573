import { CustomCard } from '@/components';
import { InlineGrid, SkeletonBodyText, TextField } from '@shopify/polaris';
import { footerLinkConfig } from './config';
import { useDispatch, useSelector } from 'react-redux';
import trackingPageSlice, { trackingPageIsLoadingSelector, trackingPageSelector } from '@/redux/features/trackingPage.slice';
import { checkDirtyField, isUrlValid, renderErrorValue, splitObjects } from '@/helpers';

const FooterLink = () => {
  const dispatch = useDispatch();
  const { fieldsChange, validation, value: data, oldValue } = useSelector(trackingPageSelector);
  const isLoading = useSelector(trackingPageIsLoadingSelector);
  const { footerLink } = data.configuration;
  const { footerLink: oldFooterLink } = oldValue.configuration;

  const handleChange = (key: string) => (value: string) => {
    dispatch(trackingPageSlice.actions.handleChangeValidation(validation.filter((item) => item !== key)));
    dispatch(
      trackingPageSlice.actions.handleTrackingPageValue({
        ...data,
        configuration: {
          ...data.configuration,
          footerLink: {
            ...footerLink,
            [key]: value,
          },
        },
      }),
    );
    const { dirtyField: policyField } = checkDirtyField(splitObjects(oldFooterLink, 0, 4), {
      ...splitObjects(footerLink, 0, 4),
      [key]: value,
    });
    const { dirtyField: socialField } = checkDirtyField(splitObjects(oldFooterLink, 4, Object.entries(oldFooterLink).length), {
      ...splitObjects(footerLink, 4, Object.entries(footerLink).length),
      [key]: value,
    });
    dispatch(
      trackingPageSlice.actions.handleTrackingPageFieldsChange({
        ...fieldsChange,
        footerLinks:
          !policyField && !socialField
            ? undefined
            : {
                social: socialField,
                policy: policyField,
              },
      }),
    );
  };

  // const handleBlurTextField = (value: string, key: string) => {
  //   if (value && !isUrlValid(value)) {
  //     const isNotHaveKey = validation.findIndex((item) => item === key) === -1;
  //     if (isNotHaveKey) {
  //       dispatch(trackingPageSlice.actions.handleChangeValidation([...validation, key]));
  //     }
  //   } else {
  //     dispatch(trackingPageSlice.actions.handleChangeValidation(validation.filter((item) => item !== key)));
  //   }
  // };
  return (
    <>
      <CustomCard title="Social media profiles">
        {isLoading ? (
          <SkeletonBodyText lines={10} />
        ) : (
          <InlineGrid columns={{ lg: 2, xl: 2, md: 1, xs: 1, sm: 1 }} gap="400">
            {footerLinkConfig.social.map((item) => {
              return (
                <TextField
                  placeholder="URL profile"
                  id={item.key}
                  key={item.key}
                  label={item.title}
                  onChange={handleChange(item.key)}
                  // value={footerLink[item.key as keyof typeof footerLink]}
                  // onBlur={() => handleBlurTextField(footerLink[item.key as keyof typeof footerLink], item.key)}
                  autoComplete="off"
                  maxLength={500}
                  showCharacterCount
                  error={renderErrorValue(validation, item.key)}
                />
              );
            })}
          </InlineGrid>
        )}
      </CustomCard>

      <CustomCard className="mt-16" title="Policy">
        {isLoading ? (
          <SkeletonBodyText lines={10} />
        ) : (
          <InlineGrid columns={{ lg: 2, xl: 2, md: 1, xs: 1, sm: 1 }} gap="400">
            {footerLinkConfig.policy.map((item) => {
              return (
                <div key={item.key}>
                  <div>{item.title}</div>
                  <div className="mt-8">
                    <TextField
                      key={item.key}
                      label={item.title}
                      labelHidden
                      placeholder={item.placeHolder}
                      onChange={handleChange(item.key)}
                      // value={footerLink[item.key as keyof typeof footerLink]}
                      // onBlur={() => handleBlurTextField(footerLink[item.key as keyof typeof footerLink], item.key)}
                      autoComplete="off"
                      maxLength={500}
                      showCharacterCount
                      error={renderErrorValue(validation, item.key)}
                    />
                  </div>
                </div>
              );
            })}
          </InlineGrid>
        )}
      </CustomCard>
    </>
  );
};
export default FooterLink;
