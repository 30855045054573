import { CustomCard, ErrorBanner } from '@/components';
import CustomLayoutOneThird from '@/components/CustomLayoutOneThird';
import { checkDirtyField } from '@/helpers';
import trackingPageSlice, { eddSelector, isLoadingEddSelector } from '@/redux/features/trackingPage.slice';
import { Checkbox, Select, SkeletonBodyText, Text, TextField } from '@shopify/polaris';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { cutoffTimeOptions, dayInWeek, timezoneOptions } from '../../config';
import { StyleOrderModal, StyledOrderDescription } from './styled';

const OrderDescription = () => {
  const dispatch = useDispatch();
  const [apiError, setApiError] = useState<string>('');
  const { value: edd, oldValue, fieldsChange } = useSelector(eddSelector);
  const isLoading = useSelector(isLoadingEddSelector);

  const handleChangeOptions = (selected: string | boolean, key: string) => {
    setApiError('');
    let newValue: any;
    if (typeof selected === 'string') {
      newValue = key === 'processingTime' ? String(Math.max(Math.min(Number(selected), 999), 0)) : selected;
      dispatch(
        trackingPageSlice.actions.handleChangeEDDValue({
          ...edd,
          orderDescription: {
            ...edd.orderDescription,
            [key]: newValue,
          },
        }),
      );
    }
    if (typeof selected === 'boolean') {
      newValue = selected
        ? [...edd.orderDescription.businessDays, Number(key)].sort()
        : edd.orderDescription.businessDays.filter((day) => day !== Number(key));
      dispatch(
        trackingPageSlice.actions.handleChangeEDDValue({
          ...edd,
          orderDescription: {
            ...edd.orderDescription,
            businessDays: newValue,
          },
        }),
      );
    }
    switch (key) {
      case 'cutoffTime':
      case 'timezone': {
        const { dirtyField } = checkDirtyField(oldValue.orderDescription, { ...edd.orderDescription, [key]: newValue });
        dispatch(
          trackingPageSlice.actions.handleChangeEDDFieldsChange({
            ...fieldsChange,
            [key]: dirtyField?.[key],
          }),
        );
        break;
      }
      case 'processingTime': {
        const { dirtyField } = checkDirtyField(
          { processingTime: oldValue.orderDescription.processingTime },
          { processingTime: newValue },
        );
        dispatch(
          trackingPageSlice.actions.handleChangeEDDFieldsChange({
            ...fieldsChange,
            processingTime: dirtyField?.processingTime,
          }),
        );
        break;
      }
      case '2':
      case '3':
      case '4':
      case '5':
      case '6':
      case '7':
      case '8': {
        const oldBusinessDays = [...oldValue.orderDescription.businessDays];
        oldBusinessDays.sort();
        const newBusinessDays = [...(newValue as number[])];
        newBusinessDays.sort();
        const { dirtyField } = checkDirtyField(
          { businessDays: oldBusinessDays.toString() },
          { businessDays: newBusinessDays.toString() },
        );
        dispatch(
          trackingPageSlice.actions.handleChangeEDDFieldsChange({
            ...fieldsChange,
            businessDays: dirtyField?.businessDays?.split(',')?.map((item) => Number(item)),
          }),
        );
        break;
      }
      default:
        break;
    }
  };

  // const handleEditOrder = () =>
  //   dispatch(
  //     trackingPageSlice.actions.handleChangeEDDValue({
  //       ...edd,
  //       orderDescription: { ...edd.orderDescription, isModalActive: true },
  //     }),
  //   );

  // const handleSubmitForm = () => {
  //   updateEddSetting({
  //     cutoffTime: edd.orderDescription.cutoffTime,
  //     timezone: edd.orderDescription.timezone,
  //     processingTime: edd.orderDescription.processingTime.value,
  //     businessDays: edd.orderDescription.businessDays.value,
  //   }).then((data) => {
  //     if ('data' in data && data.data && data.data?.state === 1) {
  //       if (apiError) {
  //         setApiError('');
  //       }
  //       dispatch(commonSlice.actions.handleToast(handleToastMutation(data)));
  //       dispatch(
  //         trackingPageSlice.actions.handleChangeEDDValue({
  //           ...edd,
  //           orderDescription: { ...edd.orderDescription, isModalActive: false },
  //         }),
  //       );
  //       return;
  //     }
  //     setApiError(getErrorFromAPI(data));
  //   });
  // };

  return (
    <StyledOrderDescription>
      <CustomLayoutOneThird
        notUseCard
        title="Business days & Cut-off time"
        description={<p>Excluding non-business days from estimated delivery date</p>}
      >
        <CustomCard title="" noHeaderBorder noHeaderPadding>
          {isLoading ? (
            <SkeletonBodyText lines={6} />
          ) : (
            <StyleOrderModal>
              <div className="order-section">
                <Text variant="bodyMd" as="h6">
                  Business days
                </Text>
                <div className="business-days mt-4">
                  {dayInWeek.map((item) => {
                    const isChecked = edd.orderDescription.businessDays.includes(item.code);
                    const isHaveOnlyOneDay = edd.orderDescription.businessDays.length === 1;
                    return (
                      <Checkbox
                        id={String(item.code)}
                        key={item.code}
                        label={item.label}
                        checked={isChecked}
                        onChange={handleChangeOptions}
                        disabled={isHaveOnlyOneDay && isChecked}
                      />
                    );
                  })}
                </div>
              </div>
              {apiError && (
                <div className="mb-8">
                  <ErrorBanner isVisible>{apiError}</ErrorBanner>
                </div>
              )}

              <div className="order-section">
                <div className="mb-4">
                  <Text variant="bodyMd" as="h6">
                    Cut-off time
                  </Text>
                </div>

                <Select
                  label="Time"
                  labelHidden
                  id="cutoffTime"
                  options={cutoffTimeOptions}
                  value={edd.orderDescription.cutoffTime}
                  onChange={handleChangeOptions}
                />
              </div>
            </StyleOrderModal>
          )}
        </CustomCard>
      </CustomLayoutOneThird>

      <CustomLayoutOneThird
        isShowDivider
        notUseCard
        title="Timezone"
        description={<p>Choose timezone on which date and time are based for calculation</p>}
      >
        <CustomCard title="" noHeaderBorder noHeaderPadding>
          {isLoading ? (
            <SkeletonBodyText lines={2} />
          ) : (
            <Select
              label="Timezone"
              labelHidden
              id="timezone"
              options={timezoneOptions}
              value={edd.orderDescription.timezone}
              onChange={handleChangeOptions}
            />
          )}
        </CustomCard>
      </CustomLayoutOneThird>

      <CustomLayoutOneThird
        isShowDivider
        notUseCard
        title="Order processing time"
        description={<p>Select how long your warehouse needs to process an order</p>}
      >
        <CustomCard title="" noHeaderBorder noHeaderPadding>
          {isLoading ? (
            <SkeletonBodyText lines={2} />
          ) : (
            <TextField
              label=""
              type="number"
              id="processingTime"
              maxLength={3}
              max={999}
              autoComplete="off"
              value={JSON.stringify(Math.floor(Math.abs(Number(edd.orderDescription.processingTime))))}
              onChange={handleChangeOptions}
              suffix="business days"
            />
          )}
        </CustomCard>
      </CustomLayoutOneThird>

      {/* temp comment */}
      {/* <CustomCard
        className="mt-16"
        title="Order cutoff and processing times"
        actionGroup={[
          {
            content: 'Edit',
            variant: 'plain',
            onAction: handleEditOrder,
            icon: EditIcon,
            disabled: isLockedFeature,
          },
        ]}
      >
        {isLoading ? (
          <SkeletonBodyText lines={7} />
        ) : (
          orderDescriptionArr.length > 0 &&
          orderDescriptionArr.map((desc) => (
            <div key={desc.title} className="order-item">
              <Text variant="headingSm" as="h6">
                {desc.title}
              </Text>
              <p>{desc.value}</p>
            </div>
          ))
        )}
      </CustomCard> */}
    </StyledOrderDescription>
  );
};

export default OrderDescription;
