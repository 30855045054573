import InputColorPicker from '@/components/InputColorPicker';
import { checkDirtyField, isHexColor, splitObjects } from '@/helpers';
import { hexToHSB } from '@/helpers/color';
import trackingPageSlice, { trackingPageSelector } from '@/redux/features/trackingPage.slice';
import { hsbToHex, RangeSlider } from '@shopify/polaris';
import { useDispatch, useSelector } from 'react-redux';
import { LookAndFeelStyleStyled } from './styled';

const colorPickerConfig = [
  {
    label: 'Main color',
    key: 'mainColor',
    apiKey: 'mainColor',
  },
  {
    label: 'Text color',
    key: 'textColor',
    apiKey: 'textColor',
  },
  {
    label: 'Button color',
    key: 'btnColor',
    apiKey: 'buttonColor',
  },
  {
    label: 'Button text color',
    key: 'btnNameColor',
    apiKey: 'buttonTextColor',
  },
];

const Style = () => {
  const dispatch = useDispatch();
  const data = useSelector(trackingPageSelector).value;
  const {
    fieldsChange,
    oldValue: {
      lookAndFeel: { style: oldStyle },
    },
  } = useSelector(trackingPageSelector);
  console.log('log_data: ', data);
  const { style } = data.lookAndFeel;
  const { colorPicker } = data.lookAndFeel.style;
  const { btnRadius } = data.lookAndFeel.style;
  const handleBtnRadius = (btnRadius: number) => {
    dispatch(
      trackingPageSlice.actions.handleTrackingPageValue({
        ...data,
        lookAndFeel: {
          ...data.lookAndFeel,
          style: {
            ...style,
            btnRadius,
          },
        },
      }),
    );
    const { dirtyField: btnRadiusField } = checkDirtyField(splitObjects(oldStyle, 0, 1), {
      ...splitObjects(style, 0, 1),
      btnRadius,
    });
    dispatch(
      trackingPageSlice.actions.handleTrackingPageFieldsChange({
        ...fieldsChange,
        general:
          !btnRadiusField && Object.entries(fieldsChange.general).length < 2
            ? undefined
            : {
                ...fieldsChange.general,
                buttonBorder: btnRadius,
              },
      }),
    );
  };

  const handleChangeColorHex = (key: string, apiKey: string) => (value: string) => {
    if (isHexColor(value)) {
      const hsbColor = hexToHSB(value);
      const newColorPicker = { ...colorPicker };
      newColorPicker[key as keyof typeof colorPicker] = hsbColor;
      dispatch(
        trackingPageSlice.actions.handleTrackingPageValue({
          ...data,
          lookAndFeel: {
            ...data.lookAndFeel,
            style: {
              ...style,
              colorPicker: newColorPicker,
            },
          },
        }),
      );

      dispatch(
        trackingPageSlice.actions.handleTrackingPageFieldsChange({
          ...fieldsChange,
          general: {
            ...fieldsChange.general,
            [apiKey]: value,
          },
        }),
      );
    }
  };
  console.log('log_colorPicker: ', hsbToHex(colorPicker.btnNameColor));

  return (
    <LookAndFeelStyleStyled>
      {colorPickerConfig.map((item) => {
        return (
          <div key={item.key}>
            <InputColorPicker
              label={item.label}
              value={hsbToHex(colorPicker[item.key as keyof typeof colorPicker])}
              onChange={handleChangeColorHex(item.key, item.apiKey)}
            />
          </div>
        );
      })}

      <RangeSlider min={0} max={25} label="Button radius" value={btnRadius} onChange={handleBtnRadius} output />
    </LookAndFeelStyleStyled>
  );
};

export default Style;
