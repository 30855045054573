import Layout from '@/components/Layout';
import { PATH, TrackingStatus } from '@/constants';
import { transformOptions } from '@/helpers';
import useConfirmationNavigate from '@/hooks/useConfirmationNavigate';
import emailSlice, { orderStatusSelector } from '@/redux/features/email.slice';
import { Select, Text } from '@shopify/polaris';
import { useDispatch, useSelector } from 'react-redux';
import TemplateButtons from './components/TemplateButtons';
import TemplateEditor from './components/TemplateEditor';
import TemplateHeading from './components/TemplateHeading';
import { StyledEmailTemplateDetail } from './styled';

const EmailTemplatesDetail = () => {
  const navigate = useConfirmationNavigate();
  const dispatch = useDispatch();
  const orderStatusState = useSelector(orderStatusSelector);

  const orderStatusOptions = transformOptions(TrackingStatus).filter(({ value }) => value !== TrackingStatus.All);

  const handleChange = (value: string) => {
    dispatch(emailSlice.actions.handleChangeOrderStatus(value as TrackingStatus));
    navigate(`${PATH.NOTIFICATIONS_EMAIL}/${value}`);
  };

  return (
    <StyledEmailTemplateDetail>
      <Layout
        fullWidth
        maxWidth="75rem"
        backAction={{ url: PATH.NOTIFICATIONS }}
        title="Email Templates"
        secondaryActions={
          <div className="page-title">
            <Text variant="headingMd" as="h6">
              Order status:
            </Text>
            <Select label="" labelHidden options={orderStatusOptions} value={orderStatusState} onChange={handleChange} />
          </div>
        }
      >
        <TemplateHeading />
        <div className="mt-16" />
        <TemplateButtons />
        <div className="mt-16" />
        <TemplateEditor />
      </Layout>
    </StyledEmailTemplateDetail>
  );
};

export default EmailTemplatesDetail;
