import { CustomBanner } from '@/components';
import TrackingPageLayout from '@/components/Layout/TrackingPageLayout';
import RegularText from '@/components/RegularText';
import { LINK } from '@/constants';
import { handleOpenChat } from '@/helpers';
import Blacklisting from '@/pages/TrackingPage/components/Sections/Blacklisting';
import EstimatedDeliveryDate from '@/pages/TrackingPage/components/Sections/EstimatedDeliveryDate';
import TrackingPageEditor from '@/pages/TrackingPage/components/Sections/TrackingPageEditor';
import { TrackingPageContainerStyled } from '@/pages/TrackingPage/styled';
import { tokenSelector } from '@/redux/features/auth.slice';
import { Button } from '@shopify/polaris';
import { ViewIcon } from '@shopify/polaris-icons';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import LanguageSection from './components/Sections/Language';

const TrackingPage = () => {
  const { shop } = useSelector(tokenSelector);

  const [isShowBannerWatermark, setIsShowBannerWatermark] = useState(false);

  return (
    <TrackingPageLayout
      isFixed
      title="Tracking Page"
      secondaryActions={[
        {
          content: 'Preview',
          icon: ViewIcon,
          url: LINK.CUSTOMER_SHOP(shop),
        },
      ]}
    >
      {/* 
      <CustomPage
        tabsConfig={tabsConfigPage}
        selected={[isTabInTrackingPageTabs ? tabSelected : TrackingPageTabs.LookAndFeel]}
        onChange={handleTabSelected}
        selectedTab={tabSelected}
      /> */}
      <CustomBanner isClose={isShowBannerWatermark} onDismiss={() => setIsShowBannerWatermark(true)}>
        <div className="d-flex justify-between align-center mr-12">
          <RegularText>Remove badge “Powered by Synctrack Order Tracking" for FREE!</RegularText>
          <Button onClick={handleOpenChat}>Contact us</Button>
        </div>
      </CustomBanner>
      <TrackingPageContainerStyled>
        <TrackingPageEditor />
        <EstimatedDeliveryDate />
        <Blacklisting />
        <LanguageSection />
      </TrackingPageContainerStyled>
    </TrackingPageLayout>
  );
};

export default TrackingPage;
