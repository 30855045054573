import { GoogleTranslatePosition, Language } from '@/constants/enum';
import { LanguageValue } from '@/redux/features/trackingPage.slice';

interface ILanguage {
  language: string;
  value: Language;
  translations: LanguageValue;
}

export const googleTranslatePositionOption = [
  {
    value: GoogleTranslatePosition.LeftTop,
    label: 'Left Top',
  },
  {
    value: GoogleTranslatePosition.LeftBottom,
    label: 'Left Bottom',
  },
  {
    value: GoogleTranslatePosition.RightTop,
    label: 'Right Top',
  },
  {
    value: GoogleTranslatePosition.RightBottom,
    label: 'Right Bottom',
  },
];

export const languageOptions = [
  {
    value: Language.English,
    label: 'English',
  },
  {
    value: Language.Chinese,
    label: 'Chinese',
  },
  {
    value: Language.French,
    label: 'French',
  },
  {
    value: Language.Dutch,
    label: 'Dutch',
  },
  {
    value: Language.Germany,
    label: 'Germany',
  },
  {
    value: Language.Japanese,
    label: 'Japanese',
  },
  {
    value: Language.Hebrew,
    label: 'Hebrew',
  },
  {
    value: Language.Portuguese,
    label: 'Portuguese',
  },
  {
    value: Language.Spanish,
    label: 'Spanish',
  },
  {
    value: Language.Arabic,
    label: 'Arabic',
  },
];

export const languages: Array<ILanguage> = [
  {
    language: 'English',
    value: Language.English,
    translations: {
      trackingStatus: {
        ordered: 'Ordered',
        informationReceived: 'Order ready',
        inTransit: 'In Transit',
        outForDelivery: 'Out For Delivery',
        delivered: 'Delivered',
        courierNotSupported: 'NO INFO',
        expired: 'Expired',
        failedAttempt: 'Failed Attempt',
        exception: 'Delivery failed',
        pending: 'Pending',
      },
      trackingForm: {
        trackYourOrder: 'Track your order',
        orderId: 'Order ID',
        email: 'Email',
        or: 'OR',
        trackingNumber: 'Tracking Number',
        track: 'Track',
        placeholderOrderId: 'Enter your order id',
        placeholderEmail: 'Enter your email',
        placeholderTrackingNumber: 'Enter your tracking number',
        orderNotFound: 'Order not found',
      },
      trackingHistory: {
        shipmentInformation: 'Shipment Information',
        shippingAddress: 'Shipping address',
        trackingNumber: 'Tracking number',
        shipmentHistory: 'Shipment history',
        carrier: 'Carrier',
      },
      progressBar: {
        status: 'Status',
      },
      orderDetails: {
        shipmentItems: 'Items of shipment',
        product: 'Products',
        totalPrice: 'Total',
        itemSingular: 'item',
        itemPlural: 'items',
      },
      review: {
        buttonText: 'Review us',
        reviewTitle: 'Your satisfaction is Our Biggest Rewards',
      },
      footer: {
        privacy: 'Privacy',
        return: 'Return',
        support: 'Support',
        term: 'Term',
      },
    },
  },
];

export const trackingFormConfig = {
  trackYourOrder: {
    label: 'Track your order',
    maxLength: 100,
  },
  orderId: {
    label: 'Order ID',
    maxLength: 20,
  },
  email: {
    label: 'Email',
    maxLength: 20,
  },
  phoneNumber: {
    label: 'Phone number',
    maxLength: 20,
  },
  or: {
    label: 'Or',
    maxLength: 20,
  },
  trackingNumber: {
    label: 'Tracking Number',
    maxLength: 30,
  },
  track: {
    label: 'Track',
    maxLength: 20,
  },
  placeholderTrackingNumber: {
    label: 'Placeholder (tracking number)',
    maxLength: 50,
  },
  placeholderOrderId: {
    label: 'Placeholder (order id)',
    maxLength: 50,
  },
  placeholderEmail: {
    label: 'Placeholder (email)',
    maxLength: 50,
  },
  placeholderPhoneNumber: {
    label: 'Placeholder (Phone number)',
    maxLength: 50,
  },
  orderNotFound: {
    label: 'Order not found',
    maxLength: 50,
  },
  emptyEmail: {
    label: 'Empty email',
    maxLength: 50,
  },
  invalidEmail: {
    label: 'Invalid email',
    maxLength: 50,
  },
  emptyTrackingNumber: {
    label: 'Empty tracking number',
    maxLength: 50,
  },
  emptyOrderName: {
    label: 'Empty order name',
    maxLength: 50,
  },
  emptyPhoneNumber: {
    label: 'Empty phone number',
    maxLength: 50,
  },
};

export const convertClientKeyToApiKey = {
  trackingForm: {
    trackYourOrder: 'title',
    orderId: 'order_id',
    email: 'email',
    phoneNumber: 'phone',
    or: 'or',
    trackingNumber: 'tracking_number',
    track: 'button',
    placeholderTrackingNumber: 'plh_tracking_number',
    placeholderOrderId: 'plh_order_id',
    placeholderEmail: 'plh_email',
    placeholderPhoneNumber: 'plh_phone',
    orderNotFound: 'order_not_found',
    orderNotFoundMessage: 'order_not_found_message',
    emptyEmail: 'empty_email',
    invalidEmail: 'invalid_email',
    emptyTrackingNumber: 'empty_tracking_number',
    emptyOrderName: 'empty_order_name',
    emptyPhoneNumber: 'empty_phone_number',
  },
  footer: {
    privacy: 'privacyPage',
    return: 'returnPage',
    support: 'support',
    term: 'termPage',
  },
};
