export const countDuplicateValueInArr = (arr: Array<string>) =>
  // eslint-disable-next-line no-sequences
  arr.reduce((cnt: any, cur) => ((cnt[cur] = cnt[cur] + 1 || 1), cnt), {});

export const isUndefinedArray = (array: Array<any>) => {
  return array.every((element) => element === undefined);
};

export function uniq<T>(a: Array<T>): T[] {
  return Array.from(new Set(a.map((item) => JSON.stringify(item)))).map((item) => JSON.parse(item));
}

export function uniqByKey<T>(a: Array<T>, key?: string): T[] {
  try {
    if (!key) return Array.from(new Set(a.map((item) => JSON.stringify(item)))).map((item) => JSON.parse(item));
    return Array.from(new Set(a.map((item) => JSON.stringify((item as Record<string, any>)[key]))))
      .map((item) => a.find((i) => (i as Record<string, any>)[key] === JSON.parse(item)))
      .filter((item) => !!item) as T[];
  } catch (error) {
    console.log(error);
    return a;
  }
}
